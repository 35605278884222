import request from "../api/agent";
const API_ROUTE = "course/admin";

export class CourseTypeService {
    async addOrUpdateCourseType(course) {
        let action = `${API_ROUTE}/course-type-creation`;
        const response = await request.post(action, course);
        return response;
    }


    // async getAllCourseTypesList() {
    //     const response = await request.get(`${API_ROUTE}/course-type-listing`);
    //     return response.data;
    // }

    async getAllCourseTypes(perpage, currentPage) {
        if(perpage){
        const response = await request.get(`${API_ROUTE}/course-type-listing?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }else{
        const response = await request.get(`${API_ROUTE}/course-type-listing`);
        return response.data;

    }
    }

    async deleteCourseType(id) {
        const response = await request.delete(`${API_ROUTE}/course-type-delete`, {
            data: { course_type_id: [id] }
        });
        return response;
    }
    async getStatus(id) {
        const response = await request.post(`${API_ROUTE}/course-type-status`, { course_type: id })
        return response;
    }
}

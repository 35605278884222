


import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { StateService } from '../../../services/StateServices';


const stateservice = new StateService();
const CreateState = ({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {
    const [types, setTypes] = useState([]);

    useEffect(() => {
      getCountry()
    }, [])

    async function getCountry() {
        const data = await stateservice.getAllCountry();
        setTypes(data?.data?.results)
        console.log(data);
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.name && !formik.errors.name}
                                isInvalid={!!formik.errors.name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        
                    
                 
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Country</Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="country_id"
                                value={formik.values?.country_id}
                                isValid={formik.touched.country_id && !formik.errors.country_id}
                                isInvalid={!!formik.errors?.country_id}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select an Option</option>
                                {types?.map(type =>
                                    <option value={type?.id}>{type?.country_name}</option>
                                )}
                                 
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.country_id}</Form.Control.Feedback>
                    </Form.Group>

                </Row>
                <div className="group">
                    {isloader == true ?
                        <div className='loader'>
                            <img src={loader} alt="" />
                        </div>
                        :
                        <Button type="submit" className='save-btn me-4'>Save</Button>
                    }
                </div>
            </Form>
        </div>
        </React.Fragment >
    )
}

export default  CreateState ;
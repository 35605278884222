// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CreateStream from './createStream';
import StreamDatatable from './streamDatatable';
import { StreamService } from '../../../services/StreamService';
import { checkUserPermission } from '../../checkPermission';

const streamService = new StreamService();

const Stream = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const[typeValue,setTypeValue]=useState();
    const[pending,setPending]=React.useState(true)
    const[currentPage,setCurrentPage]=useState(1)
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [perpage, setPerpage] = useState(10);
    const [totalRows, setTotalRows] = React.useState(0);


    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };

    async function getAllStreams() {
        setPending(true)
        const data = await streamService.getAllStreams(filterText,typeValue,perpage,currentPage);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)

    }
    useEffect(() => {
        getAllStreams()
    }, [refresh,filterText,typeValue,perpage,currentPage])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        course: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            course: "",
        },
        validationSchema,

        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("stream", values.id ?? "");
            }
            formData.append("name", values.name);
            formData.append("course", values.course);

            streamService.addOrUpdateStream(formData).then((response) => {

                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_name: response?.response?.data?.errors?.title,
                        course_type: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        formik.setValues({
            id: item.id,
            name: item.name,
            course: item.course_id,
        })
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Stream</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Stream</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        { checkUserPermission("Create Stream") && <CreateStream image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <StreamDatatable setPerpage={setPerpage} setSelectedRows={setSelectedRows} totalRows={totalRows} handleChange={handleChange} handleRowClicked={handleRowClicked} handlePerRowsChange={handlePerRowsChange} pending={pending} currentPage={currentPage} setCurrentPage={setCurrentPage} setTypeValue={setTypeValue} filterText={filterText} setFilterText={setFilterText} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Stream;

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';

import { ToastContainer, toast } from 'react-toastify';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { StateService } from '../../../services/StateServices';
import CreateState from './CreateState';
import StateDatatable from './StateDatatable';
import { checkUserPermission } from '../../checkPermission';


const stateService = new StateService();

const State = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [tableloader, setTableloader] = useState(true);
    const [scroll, setScroll] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };

    async function getStates() {
        setPending(true)
        const data = await stateService.getAllStates(perpage, currentPage, filterText);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getStates()
    }, [refresh, perpage, currentPage, filterText])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        country_id: Yup.string()
            .required('Required'),

        // image: Yup.string()
        //     .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            state_id: "",
            name: "",
            country_id: "",

        },
        validationSchema,
        /* validate: async (values) => {
             const errors = {}
             // let image = values.image
 
 
             // values.id ? image = await dataURLtoFile(values.image) : image = values.image
             if (values.image == "") {
                 errors.image = "Required"
             }
             else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                 errors.image = "Only jpg/jpeg and png files are allowed!"
             }
             console.log(values.image);
             return errors
         },*/
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.state_id) {
                formData.append("state_id", values.state_id ?? "");
            }

            formData.append("name", values.name);
            formData.append("country_id", values.country_id);
            // formData.append("image", values?.image);

            stateService.addOrUpdateCourse(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        country_id: response?.response?.data?.errors?.country_id,
                        name: response?.response?.data?.errors?.name,
                        //  image: response?.response?.data?.errors?.image,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        // setImage(await dataURLtoFile(item.image))
        formik.setValues({
            state_id: item.id,
            country_id: item.country_id,
            name: item.name,
            // image: await dataURLtoFile(item?.image),
            // image_url: item?.image,
            // course_type: item.course_type_id
        })

        console.log(item);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>State</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">State</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create State") && <CreateState image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <StateDatatable currentPage={currentPage} filterText={filterText} setFilterText={setFilterText} pending={pending} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default State;
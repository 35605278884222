// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import CreateCourse from './createCourse';
import CourseDatatable from './courseDatatable';
import { ToastContainer, toast } from 'react-toastify';
import { CourseService } from "../../../services/CourseService";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { checkUserPermission } from '../../checkPermission';


const courseService = new CourseService();

const Courses = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [typeValue, setTypeValue] = useState();
    const [pending, setPending] = React.useState(true)
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [perpage, setPerpage] = useState(10);
    // const [active, setActive] = useState(false);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };



    async function getAllCourses() {
        setPending(true)
        const data = await courseService.getAllCourses(filterText, typeValue, perpage, currentPage);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)

    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    useEffect(() => {
        getAllCourses()
    }, [refresh, filterText, typeValue, perpage, currentPage])

    const validationSchema = Yup.object().shape({
        course_name: Yup.string()
            .required('Required'),
        course_type: Yup.string()
            .required('Required'),

        // image: Yup.string()
        //     .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            course_name: "",
            course_type: "",
            image: "",
            image_url: ""
        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (values.image == "") {
                errors.image = "Required"
            }
            else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
            console.log(values.image);
            return errors
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("course", values.id ?? "");
            }
            formData.append("name", values.course_name);
            formData.append("course_type", values.course_type);
            formData.append("image", values?.image);

            courseService.addOrUpdateCourse(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_name: response?.response?.data?.errors?.title,
                        course_type: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        // setImage(await dataURLtoFile(item.image))
        setScroll(preVal => !preVal)
        console.log(item);
        formik.setValues({

            id: item.id,
            course_name: item.name,
            image: await dataURLtoFile(item?.image),
            image_url: item?.image,
            course_type: item.course_type_id
        })

    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Create Course</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Course</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create University Course") && <CreateCourse image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <CourseDatatable
                            totalRows={totalRows}
                            handleChange={handleChange}
                            handleRowClicked={handleRowClicked}
                            handlePerRowsChange={handlePerRowsChange}
                            pending={pending}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setPerpage={setPerpage}
                            setTypeValue={setTypeValue}
                            typeValue={typeValue}
                            filterText={filterText}
                            setFilterText={setFilterText}
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            formik={formik}
                        />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Courses;
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import BasicSweetCallback from "../../SweetAlertCallback";
import { AppointmentService } from "../../../services/AppointmentService";
import { AiFillEye } from "react-icons/ai";
import Modal from "react-modal";
import loader from '../../../assets/images/loader.gif';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { checkUserPermission } from "../../checkPermission";
import moment from 'moment';
import { BsFilter } from "react-icons/bs";

const appointmentService = new AppointmentService();

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };


const AppointmmentDatatable = ({ currentPage, setTypeValue, setActive, pending, tableloader, data, setFilterText, filterText, handleEdit, refresh, setRefresh, handlePerRowsChange, setCurrentPage, totalRows, formik }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState({})


  function handleApproveAndReject(id, status) {
    appointmentService.setAppointmentStatus(id, status).then((response) => {
      if (response.status) {
        setActive(false)
        setRefresh(prev => !prev)
      }
    })
  }
  const onChangeStatus = (id) => {
    appointmentService.appoinmentStatus(id).then((response) => {
      setRefresh(!refresh)
    })
  }
  const columns = [
    {
      name: "SI.No",
      key: "index",
      cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
      grow:0.5,
    },
    {
      name: 'Name',
      selector: row => row.fullname,
      sortable: true,
      reorder: true,
      grow:1,
    },
    {
      name: 'Email',
      selector: row => row.email_address,
      sortable: true,
      grow: 2,
      reorder: true,
    },
    {
      name: 'Preferred Date',
      selector: row => moment(row.preferred_date).format("DD-MM-YYYY"),
      sortable: true,
      grow: 2,
      reorder: true,
    },
    {
      name: 'Preferred Time',
      selector: row => row.preferred_time,
      sortable: true,
      grow: 2,
      reorder: true,
    },
    {
      name: 'View',
      selector: row => <div onClick={() => openModal(row)}>
        <span className="svg-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
          </svg>
        </span>
      </div>,
      sortable: true,
      grow: 0.01,
      reorder: true,
    },
    {
      name: 'Status',
      selector: row => row.status.toUpperCase(),
      sortable: true,
      grow: 1,
      reorder: true,
    },
    {
      name: 'Contacted',
      selector: row => <div class="form-check form-switch"  >
        <input onChange={() => {
          appointmentService.contactStatus(row.id).then((response) => {
            setRefresh(!refresh)
            setActive(false)
          })
        }} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_contacted} />
        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
      </div>
      ,
      grow: 1,
      sortable: true,
      reorder: true,
      center: true
    },
    (checkUserPermission("Delete Appointments") || checkUserPermission("Delete Appointments")) && {
      id: 'action',
      name: 'Actions',
      cell: (row) => {
        if (row.id === selected.id) {
          setSelected(row)
        }
        return <div className='d-flex align-items-center'>
          {checkUserPermission("Delete Appointments") && <BasicSweetCallback onDelete={appointmentService.deleteAppointment} deleteId={row.id} title="Appointments" setRefresh={setRefresh} refresh={refresh} />}
        </div>
      },
      sortable: true,
      grow:1,

    },
  ];

  // const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  // const [data, setData] = React.useState([]);
  // const handleChange = () => { setSelectedRows(selectedRows); };


  const handleRowClicked = row => {

    console.log(`${row.name} was clicked!`);
  };


  // const handlePerRowsChange = async (newPerPage) => {
  //   setPerpage(newPerPage)
  // };

  const openModal = (row) => {
    setIsOpen(!modalIsOpen);
    setSelected(row)

  }


  return (
    <React.Fragment>
      <div className='mt-5'>
        <Card style={{ height: '100%' }}>
          <div className="head-filter">
            <div className=" filter">
              <div className="fltr">
               
                <select onChange={(e) => setTypeValue(e.target.value)}>

                  <option value='' >Select status</option>
                  <option value='approved' >APPROVED</option>
                  <option value='rejected' >REJECTED</option>
                  <option value='pending' >PENDING </option>


                </select>
                <BsFilter className='filter_icon' />
              </div>
            </div>
            <div className="">
              <div className="form-outline">
                <input
                  type="search"
                  id="form1"
                  onChange={(e) => setFilterText(e.target.value)}
                  className="form-control"
                  placeholder="search"
                />

              </div>
            </div>

          </div>
          {tableloader === true ?
            <div className='tableloader'>
              <img src={loader} alt="" />
            </div>
            :
            <DataTable
              title="Appointments"
              columns={columns}
              data={data?.results}
              defaultSortFieldId={1}
              // selectableRows={selectableRows}
              highlightOnHover
              defaultSortField="name"
              // actions={actions}
              // contextActions={contextActions(deleteAll)}
              sortIcon={sortIcon}
              // selectableRowsComponent={Checkbox}
              // selectableRowsComponentProps={selectProps}
              // onSelectedRowsChange={handleChange}
              // clearSelectedRows={toggleCleared}
              // onRowClicked={handleRowClicked}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={handlePerRowsChange}
              pagination
              paginationTotalRows={totalRows}
              paginationServer
              progressPending={pending}
            />}
        </Card>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="d-flex flex-row align-items-center justify-content-between document_data">
          <h3>Student Details</h3>
          <div onClick={() => setIsOpen(false)}>
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fffff" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </div>
        <table className='lists'>
          <tbody>
            <tr>
              <td style={{ padding: '12px 12px 12px 12px', fontWeight: "600" }}>Name</td>
              <td style={{ padding: '12px 12px 12px 12px' }}>:</td>
              <td >{selected?.fullname}</td>
            </tr>
            <tr>
              <td style={{ padding: '12px 12px 12px 12px', fontWeight: "600" }}>Email</td>
              <td style={{ padding: '12px 12px 12px 12px' }}>:</td>
              <td>{selected?.email_address}</td>
            </tr>
            <tr>
              <td style={{ padding: '12px 12px 12px 12px', fontWeight: "600" }}>Phone number</td>
              <td style={{ padding: '12px 12px 12px 12px' }}>:</td>
              <td>{selected?.phone_number}</td>
            </tr>
            <tr>
              <td style={{ padding: '12px 12px 12px 12px', fontWeight: "600" }}>Appointment type</td>
              <td style={{ padding: '12px 12px 12px 12px' }}>:</td>
              <td>{selected?.appointment_type}</td>
            </tr>
            <tr>
              <td style={{ padding: '12px 12px 12px 12px', fontWeight: "600" }}> Stage</td>
              <td style={{ padding: '12px 12px 12px 12px' }}>:</td>
              <td>{selected?.select_stage}</td>
            </tr>
            <tr>
              <td style={{ padding: '12px 12px 12px 12px', fontWeight: "600" }}> Preferred Date</td>
              <td style={{ padding: '12px 12px 12px 12px' }}>:</td>
              <td>{moment(selected?.preferred_date).format('DD-MMM-YYYY')}</td>
            </tr>
            <tr>
              <td style={{ padding: '12px 12px 12px 12px', fontWeight: "600" }}> Preferred Time</td>
              <td style={{ padding: '12px 12px 12px 12px' }}>:</td>
              <td> {selected?.preferred_time}</td>
            </tr>
          </tbody>
        </table>
        {/* <div className="main-box " style={{ width: "300px" }}>
          <div className='sub-box' >
            <div className="heading_data">
              Name :
            </div>
            <div >
              {selected?.fullname}
            </div>
          </div>
          <div className='sub-box' >
            <div className="heading_data">
              Email :
            </div>
            <div >
              {selected?.email_address}
            </div>
          </div>
          <div className='sub-box' >
            <div className="heading_data">
              Phone number :
            </div>
            <div >
              {selected?.phone_number}
            </div>
          </div>
          <div className='sub-box' >
            <div className="heading_data">
              Appointment type :
            </div>
            <div >
              {selected?.appointment_type}
            </div>
          </div>
          <div className='sub-box' >
            <div className="heading_data">
              Stage :
            </div>
            <div >
              {selected?.select_stage}
            </div>
          </div>
          <div className='sub-box' >
            <div className="heading_data">
              Preferred Date :
            </div>
            <div >
              {selected?.preferred_date}
            </div>
          </div>
          <div className='sub-box' >
            <div className="heading_data">
              Preferred Time :
            </div>
            <div >
              {selected?.preferred_time}
            </div>
          </div>
        </div> */}
        <div className="btns">
          <div className="veriy-sub-box" style={{ marginRight: "10px" }}>
            {selected?.status?.toUpperCase() === "PENDING" || selected?.status?.toUpperCase() !== "APPROVED" ? <button onClick={() => handleApproveAndReject(selected.id, "approved")} className="button-13 color-blue-btn" role="button" >Approve</button>
              :
              <button disabled={true} className="button-14 color-green-btn" role="button" >Approved</button>}
          </div>
          <div className="veriy-sub-box" >
            {selected?.status?.toUpperCase() === "PENDING" || selected?.status?.toUpperCase() !== "REJECTED" ? <button onClick={() => handleApproveAndReject(selected.id, "rejected")} className="button-13 color-blue-btn" role="button" >Reject</button>
              :
              <button disabled={true} className="button-14 color-red-btn" role="button" >Rejected</button>}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}
const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '40%'
  },

};
export default AppointmmentDatatable;

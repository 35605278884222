import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import { UniversityService } from "../../../services/universityService";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { BsFilter } from 'react-icons/bs';
import { checkUserPermission } from "../../checkPermission";
import { CountryService } from "../../../services/CountryService";
import BasicSweetCallback2 from "../../sweetAlertMultipleDelete";
const universityService = new UniversityService();
const sortIcon = <ArrowDownward />;

const countryService = new CountryService();
const UniversityDatatable = ({
  currentPage,
  setTypeValue,
  setFilterText,
  data,
  handleEdit,
  refresh,
  setRefresh,
  setCurrentPage,
  setPerpage,
  totalRows,
  pending,
}) => {
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [countries, setCountries] = useState([]);
  const [rowsId, setRowsId] = useState([]);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );
  useEffect(() => {
    getAllCountry()

  }, [])

  async function getAllCountry() {
    const data = await countryService.getAllCountries();
    setCountries(data?.data?.results)
  }

  const handleRowSelected = state => {
    setRowsId(state.selectedRows.map(row => row.id))
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  }

  console.log(rowsId)

  const columns = [
    {
      name: "SL.No",
      key: "index",
      width: "190px",
      cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
    },
    {
      name: "Name",
      // selector: (row) => row?.university_name,
      cell: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row?.university_name}>
          {row?.university_name}
        </div>,
      sortable: true,
      width: "190px",
      reorder: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
      width: "190px",
      reorder: true,
    },
    {
      name: "Country",
      selector: (row) => row?.country_name,
      sortable: true,
      width: "190px",
      reorder: true,
    },
    {
      name: 'Trusted',
      width: "190px",
      selector: row => {
        return <div className="form-check form-switch"  >
          <input onChange={() => {
            universityService.setTrustedUniversity(row.id).then((response) => {
              setRefresh(!refresh)
            })
          }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_trusted} />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
        </div>
      }
      ,
      sortable: true,
      reorder: true,
    },
    {
      name: 'Status',
      width: "190px",
      selector: row => {
        return <div className="form-check form-switch"  >
          <input onChange={() => {
            universityService.setStatus(row.id).then((response) => {
              setRefresh(!refresh)
            })
          }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_active} />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
        </div>
      }
      ,
      sortable: true,
      reorder: true,
    },
    (checkUserPermission("Edit Universities") || checkUserPermission("Delete Universities")) && {
      id: "action",
      width: "190px",
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center">
          {checkUserPermission("Edit Universities") && <Link
            onClick={() => handleEdit(row)}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span className="svg-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </OverlayTrigger>
          </Link>}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <React.Fragment>
      <div className='mt-5'>
        <Card style={{ height: '100%' }}>
          <div className="head-filter">
            <div className=" filter">
              <div className="fltr">
                <select onChange={(e) => setTypeValue(e.target.value)}>
                  <option value='' >Select an Option</option>
                  {countries?.map(d =>
                    <option value={d?.id}>{d?.country_name}</option>
                  )}
                </select>
                <BsFilter className='filter_icon' />
              </div>
            </div>
            <div className="">
              <div className="form-outline">
                <input
                  type="search"
                  id="form1"
                  onChange={(e) => setFilterText(e.target.value)}
                  className="form-control"
                  placeholder="search"
                />
              </div>
            </div>
          </div>
          {(rowsId.length !== 0) && checkUserPermission("Delete Universities") && <BasicSweetCallback2
            onDelete={universityService.deleteUniversity}
            deleteId={rowsId}
            title="Selected Data"
            setRefresh={setRefresh}
            refresh={refresh}
            setRowsId={setRowsId}
            handleClearRows={handleClearRows}
          />}
          <DataTable
            title="Universities"
            columns={columns}
            data={data?.results}
            defaultSortFieldId={1}
            highlightOnHover
            selectableRows
            onSelectedRowsChange={handleRowSelected}
            defaultSortField="name"
            sortIcon={sortIcon}
            onChangePage={(page) => setCurrentPage(page)}
            onChangeRowsPerPage={(newPerPage) => setPerpage(newPerPage)}
            clearSelectedRows={toggledClearRows}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            progressPending={pending}
          />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default UniversityDatatable;

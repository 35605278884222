// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../main/header";
import Sidebar from "../../main/sidebar";
import CountryCreate from "./createCountry";
import CountryDatatable from "./countriesDatatable";
import { ToastContainer, toast } from "react-toastify";
import { CountryService } from "../../../services/CountryService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { dataURLtoFile } from "../../functions";
import { checkUserPermission } from "../../checkPermission";

const countryService = new CountryService();

const Countries = () => {
  const formRef = useRef(null);
  const [isloader, setIsloader] = useState(false);
  const [toaster, setToaster] = useState("");
  const [image, setImage] = useState();
  const [data, setData] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [tableloader, setTableloader] = useState(true);
  const [scroll, setScroll] = useState(false);
  const [pending, setPending] = React.useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [scroll])


  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage);
  };

  async function getCountries() {
    setPending(true)
    const data = await countryService.getCountries(perpage, currentPage);
    setPending(false)
    setData(data.data);
    setTotalRows(data.data.count);
  }
  useEffect(() => {
    getCountries();
  }, [refresh, perpage, currentPage]);

  const validationSchema = Yup.object().shape({
    country_name: Yup.string().required("Required"),
    country_code: Yup.string().required("Required"),
    currency_logo: Yup.string().required("Required"),
    currency_code: Yup.string().required("Required"),

    // image: Yup.string()
    //     .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      country_id: "",
      country_name: "",
      country_code: "",
      image: "",
      image_url: "",
      currency_logo: "",
      currency_code: "",

    },
    validationSchema,
    validate: (values) => {
      console.log(values);
      const errors = {};
      if (values.image == "") {
        errors.image = "Required"
      }
      else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
        errors.image = "Only jpg/jpeg and png files are allowed!"
      }
      else {
        let kb = values.image.size / 1024; // convert the file size into byte to kb
        let mb = kb / 1024; // convert kb to mb
        if (mb > 2) {
          errors.image = "images should be less than 2 MB"
        }
      }
      console.log(values.image);
      return errors;
    },
    onSubmit: (values) => {
      console.log(values);
      setIsloader(true);
      let formData = new FormData();
      if (values.country_id) {
        formData.append("country_id", values.country_id ?? "");
      }
      formData.append("country_name", values.country_name);
      formData.append("country_code", values.country_code);
      formData.append("flag", values.image);
      formData.append("currency_logo", values.currency_logo);
      formData.append("currency_code", values.currency_code);

      countryService.addOrUpdateCountry(formData).then((response) => {
        // console.log(response);
        if (response && response.status) {
          setIsloader(false)
          setPending(false);
          console.log(response?.data?.data);
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setRefresh(!refresh);
          formik.resetForm();
          formRef.current.reset();
        } else {
          formik.setErrors({
            country_name: response?.response?.data?.errors?.title,
            country_code: response?.response?.data?.errors?.description,
            image: response?.response?.data?.errors?.image,
          });
          console.log(response?.response?.data?.errors);
          setPending(false);
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    },
  });
  const handleEdit = async (item) => {
    setScroll(preVal => !preVal)
    // setImage(await dataURLtoFile(item.image))
    formik.setValues({
      country_id: item.id,
      country_name: item.country_name,
      image: await dataURLtoFile(item.flag),
      image_url: item?.flag,
      country_code: item.country_code,
      currency_code: item.currency_code,
      currency_logo: item.currency_logo,

    });
    console.log(item);
  };

  console.log(formik);

  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Country</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Country</li>
              </ol>
            </nav>
          </div>
          <React.Fragment>
            {checkUserPermission("Create Country") && <CountryCreate
              image={image}
              formik={formik}
              isloader={isloader}
              toaster={toaster}
              setToaster={setToaster}
              setImage={setImage}
              formRef={formRef}
            />}
          </React.Fragment>
          <React.Fragment>
            <CountryDatatable
              data={data}
              handleEdit={handleEdit}
              refresh={refresh}
              setRefresh={setRefresh}
              formik={formik}
              pending={pending}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handlePerRowsChange={handlePerRowsChange}
              totalRows={totalRows}
            />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  );
};

export default Countries;

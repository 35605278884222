// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import CreateAlumniProfile from './createAlumniProfile';
import AlumniDatatable from './alumniDatatable';
import { AlumniProfileService } from '../../../services/AlumniProfileService';
import { checkUserPermission } from '../../checkPermission';

const alumniProfileService = new AlumniProfileService();

const AlumniProfile = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [totalRows, setTotalRows] = React.useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };

    async function getAllAlumniProfile() {
        setPending(true)
        const data = await alumniProfileService.getAllAlumniProfile(perpage, currentPage,filterText,);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getAllAlumniProfile()
    }, [refresh,perpage, currentPage,filterText,])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        company_name: Yup.string()
            .required('Required'),

        // image: Yup.string()
        //     .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            company_name: "",
            image: "",
            image_url: "",
            company_image: "",
            company_image_url: ""
        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            // let image = values.image


            // values.id ? image = await dataURLtoFile(values.image) : image = values.image
            if (values.image == "") {
                errors.image = "Required"
            }
            else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            if (values.company_image == "") {
                errors.company_image = "Required"
            }
            else if (values?.company_image?.type !== "image/png" && values?.company_image?.type !== "image/jpg" && values?.company_image?.type !== "image/jpeg") {
                errors.company_image = "Only jpg/jpeg and png files are allowed!"
            }
            console.log(values.company_image);
            return errors
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("user", values.id ?? "");
            }
            formData.append("name", values.name);
            formData.append("company_name", values.company_name);
            formData.append("image", values?.image);
            formData.append("company_image", values?.company_image);

            alumniProfileService.addOrUpdateAlumniProfile(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        name: response?.response?.data?.errors?.title,
                        company_name: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                        company_image: response?.response?.data?.errors?.company_image,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        // setImage(await dataURLtoFile(item.image))
        formik.setValues({
            id: item.id,
            name: item.name,
            company_name: item.company_name,
            image: await dataURLtoFile(item?.image),
            image_url: item?.image,
            company_image: await dataURLtoFile(item.company_image),
            company_image_url: item.company_image
        })

        console.log(item);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Alumni Profile</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Alumni Profile</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create Alumni Profile") && < CreateAlumniProfile image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <AlumniDatatable setCurrentPage={setCurrentPage} totalRows={totalRows} handleRowClicked={handleRowClicked} handleChange={handleChange} currentPage={currentPage} pending={pending} filterText={filterText} setFilterText={setFilterText} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default AlumniProfile;
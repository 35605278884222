import request from "../api/agent";
const API_ROUTE = "country/admin";

export class StateService {
    async addOrUpdateCourse(state) {
        let action = `${API_ROUTE}/state-register`;
        const response = await request.post(action, state);
        return response;
    }

    async getAllStates(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/state-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }

    async getStates() {
        const response = await request.get(`${API_ROUTE}/state-listing`);
        return response.data;
    }
    async getAllCountry() {
        const response = await request.get(`/country/admin/country-listing`);
        return response.data;
    }


    async deleteState(id) {
        const response = await request.delete(`${API_ROUTE}/state-delete`, {
            data: { state_id: [id] }
        });
        return response;
      }
      
      async setStateStatus(state) {
        let action = `${API_ROUTE}/state-status`;
        const response = await request.post(action, {state_id:state});
        return response;
        
        

    } 
   
}
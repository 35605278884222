import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import { EnquiryService } from '../../../services/EnquiryService';
import EnquiryDatatable from './EnquiryDatable';

const Enquiry = () => {
    const enquiryService = new EnquiryService();

    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [tableloader, setTableloader] = useState(true);
    const [pending, setPending] = React.useState(true)

    const handlePerRowsChange = async (newPerPage) => {
      setPerpage(newPerPage)
    };
  
    async function getAllEnquires() {
      setPending(true)
      const data = await enquiryService.getEnquiry(perpage, currentPage);
      setPending(false)
      setData(data.data)
    console.log(data?.data);
      setTotalRows(data.data.count)
    }
  
    useEffect(() => {
      getAllEnquires()
    }, [refresh, currentPage, perpage])

  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Enquiry</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">FAQ</li>
              </ol>
            </nav>
          </div>
          <React.Fragment>
            <EnquiryDatatable pending={pending}  data={data}  refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  )
}

export default Enquiry
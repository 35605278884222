import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';

import { ToastContainer, toast } from 'react-toastify';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { AboardService } from '../../../services/AboardService';
import CreateAboard from './CreateAboard';
import AboardDatatable from './AboardDatatable';
import { checkUserPermission } from '../../checkPermission';

const aboardservice = new AboardService();

const Aboard = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [pending, setPending] = React.useState(true)
    const [selectedRows, setSelectedRows] = React.useState([]);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
    const handleChange = () => { setSelectedRows(selectedRows); };


const handleRowClicked = row => {

    console.log(`${row.name} was clicked!`);
};

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [scroll])

    async function getAboard() {
        // setTableloader(true)
        setPending(true);
        const data = await aboardservice.getAllAboard(perpage, currentPage,filterText);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getAboard()
    }, [refresh, perpage, currentPage,filterText])

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),

        // image: Yup.string()
        //     .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            instance_id: "",
            title: "",
            logo: "",
            is_active: true

        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            // let image = values.image


            // values.id ? image = await dataURLtoFile(values.image) : image = values.image
            if (values.logo == "") {
                errors.logo = "Required"
            }
            else if (values?.logo?.type !== "image/png" && values?.logo?.type !== "image/jpg" && values?.logo?.type !== "image/jpeg") {
                errors.logo = "Only jpg/jpeg and png files are allowed!"
            }
            console.log(values.logo);
            return errors
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)

            let formData = new FormData();
            if (values.instance_id) {
                formData.append("instance_id", values.instance_id ?? "");
            }
            formData.append("title", values.title);
            formData.append("logo", values.logo);

            aboardservice.addOrUpdateAboard(formData).then((response) => {
                if (response && response.status) {
                    setRefresh(!refresh)
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        title: response?.response?.data?.errors?.title,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        formik.setValues({
            instance_id: item.id,
            title: item.title,
            logo: await dataURLtoFile(item.logo),
            image_url: item.logo

        })
    }
   
    console.log(formik)
    return (
        <React.Fragment>
            <ToastContainer />
            < >
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Abroad Plan</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Abroad Plan </li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                       { checkUserPermission("Create Abroad Plan") && <CreateAboard
                            image={image}
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            setImage={setImage}
                            formRef={formRef}
                        />}
                    </React.Fragment>
                    <React.Fragment>
                        <AboardDatatable
                            // tableloader={tableloader} 
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                            filterText={filterText}
                            setFilterText={setFilterText}
                            pagination
                            pending={pending}
                            currentPage={currentPage}
                            handleChange={handleChange}
                            handleRowClicked={handleRowClicked}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default Aboard;
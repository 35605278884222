
import React, { useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap';
import forgot_icon from '../../assets/images/forgot.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import loader from '../../assets/images/loader.gif';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthService } from '../../services/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import design from "../../assets/images/design.png";
import logo from "../../assets/images/ALGATE-Logo.png";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import IconButton from '@material-ui/core/IconButton';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@material-ui/core';
const authService = new AuthService();


const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
    },
});


function Logo() {

    const classes = useStyles();

    const [isloader, setIsloader] = useState(false);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    useEffect(() => {
        if (state?.change_password) {
            toast.success("Password Change Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            window.history.replaceState({}, document.title)

        }
        console.log(state);
    }, [state])

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            username: Yup.string()
                .required('Required'),
            password: Yup.string()
                .required('Required'),


        }),
        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            formData.append("username", values.username);
            formData.append("password", values.password);

            authService.userLogin(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log("RES");
                    console.log(response?.data);
                    localStorage.setItem('is_logged', true)
                    localStorage.setItem('token', response?.data?.data?.token)
                    localStorage.setItem('refresh', response?.data?.data?.refresh)
                    localStorage.setItem('user', JSON.stringify(response?.data?.data?.user))
                    localStorage.setItem('user_permissions', JSON.stringify(response?.data?.data?.user.user_permissions))
                    navigate('/')
                }
                else {
                    setIsloader(false)
                    toast.error(response?.response?.data?.message ? response?.response?.data?.message : "Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    return (
        <div className='container-fluid'>
        <ToastContainer />
            <div className='row'>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 image">
                    <img src={design} alt="Login image" className="img-fluid imgs"

                    />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-12 login-forms'>
                    <div className='login_left_wrapper'>
                        <div className=''>
                            <img src={logo} className='img-fluid logo-login ' />
                        </div>

                        <div className=''>
                            <Form onSubmit={formik.handleSubmit}>
                                <div className='input-login' >
                                    <Form.Group controlId="validationCustom04">
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            name="username"
                                            value={formik.values?.username}
                                            className={`usrname ${classes.root}`}
                                            onChange={formik.handleChange}
                                            // isValid={formik.touched.username && !formik.errors.username}
                                            // isInvalid={!!formik.errors.username}
                                            placeholder="Username"
                                            maxLength={100}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton>
                                                            <PersonOutlineIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {formik.errors.username && <div className='required'>Required</div>}

                                        <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='input-login'>
                                    <Form.Group controlId="validationCustom04">
                                        <TextField
                                            variant="outlined"
                                            type={showPassword ? 'input' : 'password'}
                                            name="password"
                                            value={formik.values?.password}
                                            className={`usrname ${classes.root}`}
                                            onChange={formik.handleChange}
                                            // error={!!formik.errors.password}
                                            // helperText={formik.errors.password}
                                            placeholder="Password"
                                            maxLength={100}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton>
                                                            <KeyIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            {showPassword ? (
                                                                <VisibilityIcon onClick={togglePasswordVisibility} />
                                                            ) : (
                                                                <VisibilityOffIcon onClick={togglePasswordVisibility} />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {formik.errors.password && <div className='required'>Required</div>}



                                        <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='forgot-login'>
                                    <Link to='/forget-password'>
                                        <div className='forgot-text' role='button'>Forgot password ?</div>
                                    </Link>
                                </div>
                                <div className="login_btn_wrapper group">
                                    {isloader == true ?
                                        <div className='loader'>
                                            <img src={loader} alt="" />
                                        </div>
                                        :
                                        <Button type="submit" className='login_btn'>LOGIN</Button>
                                    }
                                </div>

                            </Form>
                        </div>
                    </div>
                </div>



            </div>

        </div>
    );
}

export default Logo;
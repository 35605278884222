import request from "../api/agent";
const API_ROUTE = "/placement/placement";

export class PlacementService {
    async addOrUpdatePlacement(placement) {
        let action = `${API_ROUTE}/placement-register`;
        const response = await request.post(action, placement);
        return response;
    }

    async getAllPlacement(perpage,currentPage,search) {
        const serchValue = search??""
        if(perpage){
        const response = await request.get(`${API_ROUTE}/placement-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;}
        else{
            {
                const response = await request.get(`${API_ROUTE}/placement-listing`);
                return response.data;}
        }
    }


  async deletePlacement(id) {
        const response = await request.delete(`${API_ROUTE}/placement-delete`, {
            data: { placement: [id] }
        } );
        
        return response;
      }
      async setPlacementStatus(id){
        const response = await request.post(`${API_ROUTE}/placement-status`,{placement_id:id})
        return response;
    }
}
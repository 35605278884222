import { Formik, useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import loader from "../../assets/images/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import { BsFillCCircleFill } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { AuthService } from "../../services/AuthService";
import { Button, Col, Form, Row } from "react-bootstrap";
import design from "../../assets/images/design.png";
import logo from "../../assets/images/ALGATE-Logo.png";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@material-ui/core';

const authService = new AuthService();
const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
  },
});

const Reset = () => {

  const classes = useStyles();
  const [isloader, setIsloader] = useState(false);

  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswor, setShowPasswor] = useState(false);
  const [showPasswo, setShowPasswo] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const togglePasswordVisibilit = () => {
    setShowPasswor(!showPasswor)
  }

  const togglePasswordVisibili = () => {
    setShowPasswo(!showPasswo)
  }
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Required"),
    new_password: Yup.string().required("Required"),
    confirm_password: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      //   user_instance:"",
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema,
    validate: async (values) => {
      const errors = {}
      if (values?.new_password != values?.confirm_password) {
        errors.confirm_password = "Password mismatch Please try again"
      }
      return errors
    },
    onSubmit: (values) => {
      console.log(values);
      setIsloader(true)
      let formData = new FormData();
      let user = JSON.parse(localStorage.getItem('user'))
      if (user.id) {
        formData.append("user_instance", user.id ?? "");
      }
      // formData.append("user",values.user);
      formData.append("old_password", values.old_password);
      formData.append("new_password", values.new_password);
      formData.append("confirm_password", values?.confirm_password);
      authService.changePassword(formData).then((response) => {
        if (response && response.status) {
          setIsloader(false);
          toast.success("Password Changed Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate('/login', { state: { change_password: true } })
          // return()

        } else {
          formik.setErrors({
            ...formik.errors,
            old_password: response?.data?.data?.errors?.old_password && response?.data?.data?.errors?.old_password[0] ? response?.data?.data?.errors?.old_password[0] : '',
            new_password: response?.data?.data?.errors?.new_password && response?.data?.data?.errors?.new_password[0] ? response?.data?.data?.errors?.new_password[0] : '',
            confirm_password: response?.data?.data?.errors?.confirm_password && response?.data?.data?.errors?.confirm_password[0] ? response?.data?.data?.errors?.confirm_password[0] : '',
          })

          setIsloader(false);
          toast.error(
            response?.response?.data?.message
              ? response?.response?.data?.message
              : "Something went wrong",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      });
    },
  });
  console.log(formik);
  return (
    <div className='container-fluid'>
      <ToastContainer />
      <div className='row'>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 image">
          <img src={design} alt="Login image" className="img-fluid imgs" />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-6 col-12 login-forms'>
          <div className='login_left_wrapper'>
            <div className=''>
              <img src={logo} className='img-fluid logo-login ' />
            </div>
            <div>

              <div className="reset_text">Reset Password</div>
              <Form onSubmit={formik.handleSubmit}>
                <Row className="mb-2">
                  <div className='input-login'>
                    <Form.Group as={Col} md="8" controlId="validationCustom04">
                      {/* <Form.Label>Old Password</Form.Label> */}
                      <TextField
                        variant="outlined"
                        type={showPasswo ? 'input' : 'password'}
                        className={`usrname ${classes.root}`}
                        name="old_password"
                        value={formik.values?.old_password}
                        onChange={formik.handleChange}
                        isValid={
                          formik.touched.old_password && !formik.errors.old_password
                        }
                        isInvalid={!!formik.errors.old_password}
                        placeholder="Old Password"
                        maxLength={100}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <KeyIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                {showPasswo ? (
                                  <VisibilityIcon onClick={togglePasswordVisibili} />
                                ) : (
                                  <VisibilityOffIcon onClick={togglePasswordVisibili} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formik.errors.old_password && <div className='required'>Required</div>}

                      <Form.Control.Feedback type="invalid">
                        {formik.errors.old_password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Row>
                <Row className="mb-2">
                  <div className='input-login'>
                    <Form.Group
                      as={Col}
                      md="8"
                      controlId="formFile"

                    >
                      {/* <Form.Label>New Password</Form.Label> */}
                      <TextField
                        variant="outlined"
                        type={showPasswor ? 'input' : 'password'}
                        className={`usrname ${classes.root}`}
                        name="new_password"
                        value={formik.values?.new_password}
                        onChange={formik.handleChange}
                        isValid={
                          formik.touched.new_password && !formik.errors.new_password
                        }
                        isInvalid={!!formik.errors.new_password}
                        placeholder="New Password"
                        maxLength={100}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <KeyIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                {showPasswor ? (
                                  <VisibilityIcon onClick={togglePasswordVisibilit} />
                                ) : (
                                  <VisibilityOffIcon onClick={togglePasswordVisibilit} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formik.errors.new_password && <div className="required">Required</div>}

                      <Form.Control.Feedback type="invalid">
                        {formik.errors.new_password}
                      </Form.Control.Feedback>{" "}
                    </Form.Group>
                  </div>
                </Row>
                <Row className="mb-2">
                  <div className='input-login'>
                    <Form.Group as={Col} md="8 " controlId="validationCustom03">
                      {/* <Form.Label>Confirm Password</Form.Label> */}
                      <TextField
                        variant="outlined"
                        type={showPassword ? 'input' : 'password'}
                        className={`usrname ${classes.root}`}
                        name="confirm_password"
                        value={formik.values?.confirm_password}
                        onChange={formik.handleChange}
                        isValid={
                          formik.touched.confirm_password && !formik.errors.confirm_password
                        }
                        isInvalid={!!formik.errors.confirm_password}
                        placeholder="Confirm Password"
                        maxLength={100}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <KeyIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                {showPassword ? (
                                  <VisibilityIcon onClick={togglePasswordVisibility} />
                                ) : (
                                  <VisibilityOffIcon onClick={togglePasswordVisibility} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formik.errors.confirm_password && <div className="required">Required</div>}


                    </Form.Group>
                  </div>
                </Row>

                <div className="login_btn_wrapper group">
                  {isloader == true ?
                    <div className='loader'>
                      <img src={loader} alt="" />
                    </div>
                    :
                    <Button type="submit" className='login_btns'>Reset</Button>
                  }
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;

import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CourseTypeService } from '../../../services/CourseTypeService';


const courseTypeService = new CourseTypeService();
const CreateCourse = ({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {
    const [types, setTypes] = useState([]);
    console.log("types")
console.log(types)
    const onChangeImage = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            formik.setValues({ ...formik.values, image: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) })
        }
    }
    useEffect(() => {
        getAllCourseTypesList()
    }, [])

    async function getAllCourseTypesList() {
        const data = await courseTypeService.getAllCourseTypes();
        setTypes(data?.data?.results)
        console.log(data);
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="course_name"
                                value={formik.values?.course_name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.course_name && !formik.errors.course_name}
                                isInvalid={!!formik.errors.course_name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.course_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Icon</Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={formik.touched.image && formik.errors.image}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }

                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Course Type</Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="course_type"
                                value={formik.values?.course_type}
                                isValid={formik.touched.course_type && !formik.errors.course_type}
                                isInvalid={!!formik.errors?.course_type}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select an Option</option>
                                {types?.map(t=>
                                    <option value={t?.id}> {t?.name} </option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.course_type}</Form.Control.Feedback>
                        </Form.Group>


                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateCourse;
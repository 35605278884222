import request from "../api/agent";

const API_ROUTE ="/faq/Faq";

export class FaqService {

    async getFaq(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-faq?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


    async addOrUpdateFaq(faq) {
        let action = `${API_ROUTE}/create-or-update-faq`;
        const response = await request.post(action, faq);
        return response;
    }

    async deleteFaq(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-faq`, {data: { ids: [id] }});
        return response;
    }

    async setFaqStatus(id){
        const response = await request.put(`${API_ROUTE}/faq-status-change`,{id:id})
        return response;
    }
}
import request from "../api/agent";
const API_ROUTE = "course/stream";

export class StreamService {
    async addOrUpdateStream(data) {
        let action = `${API_ROUTE}/stream-creation`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllStreams(search,typeValue,perpage,currentPage) {
        const serchValue = search??"";
         const course = typeValue?typeValue:"";
        const response = await request.get(`${API_ROUTE}/stream-listing?search=${serchValue}&course=${course}&limit=${perpage}&page=${currentPage}`);
        return response.data;
    }


    async deleteStream(id) {
        const response = await request.delete(`${API_ROUTE}/stream-delete`, {
            data: { stream: [id] }
        });
        return response;
      }
      async getStatus(id){
        const response = await request.post(`${API_ROUTE}/stream-status`,{stream:id})
        return response;
    }

}
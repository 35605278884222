import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './assets/css/styles.scss';
import './assets/css/style.css';
import './assets/css/custom.css';
import 'react-toastify/dist/ReactToastify.css';
import "../src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import Login from './components/login/login';
import AdminPanel from './components/pages/auth/adminPanel';
import Forgot from './components/forgot/forgot';
import Reset_Password from './components/forgot/resetpassword';




function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<AdminPanel />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/forget-password' element={<Forgot />} />
        <Route exact path='/password/reset/:uid/:token' element={<Reset_Password />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

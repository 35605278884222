
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import CreateFeeds from './CreateFeeds';
import FeedsDatatable from './FeedsDatatable';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { FeedService } from '../../../services/FeedServices';
import { dataURLtoFile } from '../../functions';
import { checkUserPermission } from '../../checkPermission';

const feedService = new FeedService()

const Feeds = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
   
    async function  getAllfeeds() {
        setPending(true)
        const data = await feedService.getAllfeeds(perpage,currentPage,filterText);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }

    useEffect(() => {
         getAllfeeds()
    }, [refresh,perpage,currentPage,filterText])
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [scroll])

    const validationSchema = Yup.object().shape({
        description: Yup.string()
            .required('Required')
    });

    const formik = useFormik({
        initialValues: {
            feed_id: "",
            description: "",
            image:"",
            image_url: ""

        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (values.image == "") {
                errors.image = "Required"
            }
            else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                  errors.image ="images should be less than 2 MB"
                }
              }

            console.log(values.image);
            return errors
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.feed_id) {
                formData.append("feed_id", values.feed_id ?? "");
            }
            formData.append("description", values.description);
            formData.append("image", values.image);


            feedService.addOrUpdateFeeds(formData).then((response) => {
                // })
                // CountryCreateOrUpdate({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        feed_id: response?.response?.data?.errors?.feed_id,
                        description: response?.response?.data?.errors?.description,
                        image: response?.response?.data?.errors?.image,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        formik.setValues({
            feed_id: item.id,
            description: item.description,
            image: await dataURLtoFile(item.image),
            image_url: item?.image
        })
    }

    console.log(formik)
    return (
        <div>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Feed</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Feed</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create Feed") && <CreateFeeds  formik={formik} setImage={setImage} isloader={isloader} toaster={toaster} setToaster={setToaster}  formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <FeedsDatatable currentPage={currentPage} pending={pending}   filterText={filterText} setFilterText={setFilterText} totalRows={totalRows}  handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} />
                    </React.Fragment>
                     
            
                </main>
            </>

        </div>


    )
}


export default Feeds

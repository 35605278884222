import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CourseService } from '../../../services/CourseService';


const courseService = new CourseService();
const CreateStream = ({ formik, isloader, formRef }) => {
    const [types, setTypes] = useState([]);

    useEffect(() => {
        getAllCourseTypes()
    }, [])

    async function getAllCourseTypes() {
        const data = await courseService.getAllCourses();
        setTypes(data?.data?.results)
        console.log(data);
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.name && !formik.errors.name}
                                isInvalid={!!formik.errors.name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Course</Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="course"
                                value={formik.values?.course}
                                isInvalid={!!formik.errors?.course}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select an Option</option>
                                {types?.map(type =>
                                    <option value={type?.id}>{type?.name}</option>
                                )}


                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.course}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateStream;
import React from 'react';

const Footer = () => {
    return (
        <React.Fragment>
            <footer id="footer" className="footer">
                <div className="copyright fotalign">
                    Copyright &copy;  2022 Algate. All Rights Reserved
                </div>
                <div className="credits">
                    Designed & Developed by <a href="https://www.aventusinformatics.com/" target="_blank">Aventus Informatics</a>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer;
import request from "../api/agent";
const API_ROUTE = "university/form";

export class ApplicationFormService {
    

    async getAllApplicationForm(page,limit,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/form-listing?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }

    async deleteApplicationForm(id) {
        const response = await request.delete(`${API_ROUTE}/form-delete`, {
            data: { document_id: id }
        });
        return response;
    }

    async formCompletion(id) {
        const response = await request.post(`${API_ROUTE}/process-completion`, {
            application_id: id 
        });
        return response;
    }
    async formVerification(id) {
        const response = await request.post(`${API_ROUTE}/verify-document`, {
             application_id: id 
        });
        return response;
    }
}
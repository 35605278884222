import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { SpecializationService } from '../../../services/SpecializationService';
import Select from 'react-select';
import { CountryService } from '../../../services/CountryService';
import { UniversityTypeService } from '../../../services/UniversityTypeService';
import { PlacementService } from '../../../services/PlacementServices';

const placementservice = new PlacementService();
const countryService = new CountryService();
const universityTypeService = new UniversityTypeService();
const specializationService = new SpecializationService();
const CreateUniversity = ({ formik,markAllFieldsAsTouched, setFormView, formView, isloader, toaster, setToaster, setImage, image, formRef, imageError, setOGImage }) => {
  const [types, setTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [scholarErrors, setScholarErrors] = useState(false);
  const [preErrors, setPreErrors] = useState(false);
  const [scoreErrors, setScoreErrors] = useState(false);
  const [specializations, setSpecializations] = useState([]);
  const [Placement, setPlacement] = useState([]);
  const [fields, setFields] = useState(['', '', '']);
  useEffect(() => {
    getAllCountry()
    getAllSpecialization()
    getAllUniversityListTypes()
    getlPlacement()
  }, [])

  async function getAllCountry() {
    const data = await countryService.getAllCountries();
    setCountries(data?.data?.results)
  }
  async function getAllUniversityListTypes() {
    const data = await universityTypeService.getAllUniversityTypes();
    console.log("data")
    console.log(data)
    setTypes(data?.data?.results)
  }
  async function getAllSpecialization() {
    let list = [];
    const data = await specializationService.getAllSpecialization();
    data?.data?.results?.map(specialization => {
      list.push({ value: specialization?.id, label: specialization?.name })
    })
    setSpecializations(list)
  }

  async function getlPlacement() {
    let list = [];
    const data = await placementservice.getAllPlacement();
    data?.data?.results?.map(Placement => {
      list.push({ value: Placement?.id, label: Placement?.name })
    })
    setPlacement(list)
  }
  const addNewVideo = () => {
    let gallery_videos = formik.values?.gallery_videos;
    gallery_videos?.push({
      gallery_videos: ""
    });
    formik.setValues({ ...formik.values, gallery_videos: gallery_videos })
  }

  const deleteNewVideo = (index) => {
    let gallery_videos = formik.values?.gallery_videos;
    gallery_videos?.splice(index, 1);
    formik.setValues({ ...formik.values, gallery_videos: gallery_videos })
  }

  const addNewRequsite = () => {
    let applications = formik.values?.applications;
    applications?.pre_requsites?.push({
      pre_requsites_details: ''
    });
    formik.setValues({ ...formik.values, applications: applications })
  }
  const deleteNewRequsite = (index) => {
    let applications = formik.values?.applications;
    applications?.pre_requsites?.splice(index, 1);
    formik.setValues({ ...formik.values, applications: applications })
  }

  const addNewScore = () => {
    let applications = formik.values?.applications;
    applications?.score_requirements?.push({
      score_requirement_details: ''
    });
    formik.setValues({ ...formik.values, applications: applications })
  }

  const deleteNewScore = (index) => {
    let applications = formik.values?.applications;
    applications?.score_requirements?.splice(index, 1);
    formik.setValues({ ...formik.values, applications: applications })
  }


  const handleGalleryImage = (e) => {
    let files = [];
    let urls = [];
    Array.from(e.target.files).map(file => {
      files.push(file)
      urls.push(URL.createObjectURL(file))
    })
    formik.setValues({ ...formik.values, gallery_images: files, gallery_image_urls: urls })
  }
  const handleOnPlacementChange = ({ name, value }) => {
    formik.setValues({ ...formik.values, [name]: value.map(val => val) })
  }

  const handleOnSelectChange = ({ name, value }) => {
    formik.setValues({ ...formik.values, [name]: value.map(val => val) })
  }
  const handleChangeFile = (e, name, url) => {
    formik.setValues({ ...formik.values, [name]: e.target.files[0], [url]: URL.createObjectURL(e.target.files[0]) })
  }
  const onchangeRanking = (e) => {
    if (e.target.value !== '') {
      if (e.target.value <= 5 && e.target.value > 0) {
        formik.handleChange(e)
      }
    } else {
      formik.handleChange(e)
    }
  }
  console.log("formik");
  console.log(formik);

  const style = {
    control: (styles) => ({
      ...styles,
      borderColor: formik.errors.specialization ? "#dc3545" : "white",
      "&:focus": {
        boxShadow: "#f5b7bd 0px 0px 0px 3px",
        borderColor: "#dc3545",
      },
    }),
  };

  const handleClick = () => {
    window.open(formik.values.brochure_url, '_blank').focus();
  };

  return (
    <React.Fragment>
      <div className='form_sec'>
        <Form ref={formRef} onSubmit={formik.handleSubmit}>
          {formView == 'details' &&
            <Fragment>
              <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>University Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="university_name"
                    value={formik.values?.university_name}
                    onChange={formik.handleChange}
                    isValid={formik.touched.university_name && !formik.errors.university_name}
                    isInvalid={!!formik.errors.university_name}
                    placeholder="University Name"
                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.university_name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Place</Form.Label>
                  <Form.Control
                    type="text"
                    name="place"
                    value={formik.values?.place}
                    onChange={formik.handleChange}
                    // isValid={formik.touched.place && !formik.errors.place}
                    // isInvalid={!!formik.errors.place}
                    placeholder="Place"
                    maxLength={100}
                  />
                  {/* <Form.Control.Feedback type="invalid">{formik.errors.place}</Form.Control.Feedback> */}
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    placeholder="Country"
                    className="select-dropdown"
                    classNamePrefix="select"
                    name="country"
                    value={formik.values?.country}
                    // isValid={formik.touched.country && !formik.errors.country}
                    // isInvalid={!!formik.errors?.country}
                    onChange={formik.handleChange}
                  >
                    <option value='' >Select an Option</option>
                    {countries?.map(type =>
                      <option value={type?.id}>{type?.country_name}</option>
                    )}
                  </Form.Select>
                  {/* <Form.Control.Feedback type="invalid">{formik.errors.country}</Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>University Type</Form.Label>
                  <Form.Select
                    placeholder="Select an Option"
                    className="select-dropdown"
                    classNamePrefix="select"
                    name="type"
                    value={formik.values?.type}
                    // isInvalid={!!formik.errors?.type}
                    // isValid={formik.touched.type && !formik.errors.type}
                    onChange={formik.handleChange}
                  >
                    <option value='' >Select an Option</option>
                    {types?.map(type =>
                      <option value={type?.id}>{type?.title}</option>
                    )}
                  </Form.Select>
                  {/* <Form.Control.Feedback type="invalid">{formik.errors.type}</Form.Control.Feedback> */}
                </Form.Group>

              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>ESTD</Form.Label>
                  <Form.Control
                    type="text"
                    name="estd"
                    value={formik.values?.estd}
                    onChange={formik.handleChange}
                    // isValid={formik.touched.estd && !formik.errors.estd}
                    // isInvalid={!!formik.errors.estd}
                    placeholder="eg. 1986"
                    maxLength={100}
                  />
                  {/* <Form.Control.Feedback type="invalid">{formik.errors.estd}</Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Rating</Form.Label>
                  <Form.Control
                    type="number "
                    name="ranking"
                    value={formik.values?.ranking}
                    onChange={(e) => {
                      onchangeRanking(e)
                    }}
                    isValid={formik.touched.ranking && !formik.errors.ranking}
                    isInvalid={!!formik.errors.ranking}
                    placeholder="Rating (out of 5)"
                    maxLength={100}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.ranking}</Form.Control.Feedback>
                </Form.Group>

              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    type="text"
                    name="latitude"
                    value={formik.values?.latitude}
                    onChange={formik.handleChange}
                    // isValid={formik.touched.latitude && !formik.errors.latitude}
                    // isInvalid={!!formik.errors.latitude}
                    placeholder="Latitude"
                    maxLength={100}
                  />
                  {/* <Form.Control.Feedback type="invalid">{formik.errors.latitude}</Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    type="text"
                    name="longitude"
                    value={formik.values?.longitude}
                    onChange={formik.handleChange}
                    // isValid={formik.touched.longitude && !formik.errors.longitude}
                    // isInvalid={!!formik.errors.longitude}
                    placeholder="Longitude"
                    maxLength={100}
                  />
                  {/* <Form.Control.Feedback type="invalid">{formik.errors.longitude}</Form.Control.Feedback> */}
                </Form.Group>

              </Row>
              <Row className="mb-2">

                {/* <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Specialization</Form.Label>
                  <Select
                    classNamePrefix="select"
                    isMulti
                    value={formik.values?.specialization}
                    name="specialization"
                    options={specializations}
                    onChange={(e) => { handleOnSelectChange({ name: 'specialization', value: e }) }}
                    placeholder="Select Specialization..."
                  />
                </Form.Group> */}
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Placements</Form.Label>
                  <Select
                    // className={formik.errors?.specialization ? 'error-select basic-single' : "basic-single "}
                    classNamePrefix="select"
                    isMulti
                    // styles={style}
                    value={formik.values?.placements}
                    name="Placements"
                    options={Placement}
                    onChange={(e) => { handleOnPlacementChange({ name: 'placements', value: e }) }}
                    placeholder="Select placements..."
                  />
                  {/* <div className="errorMessage">{formik.errors.placements}</div> */}
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Website</Form.Label>
                  <div className="file_wrapper">
                    <Form.Control
                      type="text"
                      name="website"
                      value={formik.values?.website}
                      onChange={formik.handleChange}
                      isValid={formik.touched.website && !formik.errors.website}

                      isInvalid={!!formik.errors.website}
                      placeholder="eg. https://www.ox.ac.uk/"
                    />
                  </div>
                  <div className="errorMessage">{formik.errors.website}</div>
                  {/* <Form.Control.Feedback type="invalid">{formik.errors.website}</Form.Control.Feedback> */}
                </Form.Group>
              </Row>
              <Row className="mb-2">
                {/* <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Fees</Form.Label>
                  <Form.Control
                    type="text"
                    name="applications.fees"
                    value={formik.values?.applications?.fees}
                    onChange={formik.handleChange}
                    placeholder="Fees"
                    maxLength={100}
                  />
                </Form.Group> */}
                <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                  <Form.Label>Social Media Image</Form.Label>
                  <div className="file_wrapper">
                    <Form.Control
                      type="file"
                      name="og_image"
                      accept="image/*"
                      values={formik.values.og_image}
                      onChange={(e) => {
                        setOGImage(e.target.files[0])
                        formik.setValues({ ...formik.values, og_image: e.target.files[0], og_image_url: URL.createObjectURL(e.target.files[0]) })
                      }
                      }
                      isValid={formik.touched.og_image && !formik.errors.og_image}
                      isInvalid={!!formik.errors.og_image}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.og_image}</Form.Control.Feedback>
                    {formik.values?.og_image_url &&
                      <div className='img-wrapper ms-2'>
                        <img src={formik.values.og_image_url} alt='course' style={{ height: '100px', width: '150px' }} className=' mt-2' />
                      </div>
                     
                    }
                    <div className='img'>Only 300 X 300 px images are accepted</div>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>About</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="about"
                    value={formik.values?.about}
                    onChange={formik.handleChange}
                    // isValid={formik.touched.about && !formik.errors.about}
                    // isInvalid={!!formik.errors.about}
                    placeholder="About"
                  />
                  {/* <Form.Control.Feedback type="invalid">{formik.errors.about}</Form.Control.Feedback> */}
                </Form.Group>

              </Row>
              <div className="group">
                <Button type="button" onClick={() =>
                  formik.values?.university_name ? setFormView('university_image') : markAllFieldsAsTouched()
                } className='save-btn me-4'>Next</Button>
              </div>
            </Fragment>
          }
          {formView == 'university_image' &&
            <Fragment>
              <Row className="mb-2">
                <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                  <Form.Label>Image</Form.Label>
                  <div className="file_wrapper">
                    <Form.Control
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={(e) => { handleChangeFile(e, 'image', 'image_url') }}
                      isValid={formik.touched.image && !formik.errors.image}
                      isInvalid={!!formik.errors.image}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                    {formik.values?.image_url &&
                      <div className='img-wrapper ms-2'>
                        <img src={formik.values.image_url} alt='course' style={{ height: '100px', width: '150px' }} className=' mt-2' />
                      </div>
                    }
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                  <Form.Label>Logo</Form.Label>
                  <div className="file_wrapper">
                    <Form.Control
                      type="file"
                      name="logo"
                      accept="image/*"
                      onChange={(e) => { handleChangeFile(e, 'logo', 'logo_url') }}
                      isValid={formik.touched.logo && !formik.errors.logo}
                      isInvalid={!!formik.errors.logo}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.logo}</Form.Control.Feedback>
                    {formik.values?.logo_url &&
                      <div className='img-wrapper ms-2'>
                        <img src={formik.values.logo_url} alt='course' style={{ height: '100px', width: '150px' }} className=' mt-2' />
                      </div>
                    }
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                  <Form.Label>Brochure</Form.Label>
                  <div className="file_wrapper">
                    <Form.Control
                      type="file"
                      name="brochure"
                      accept="application/pdf"
                      onChange={(e) => { handleChangeFile(e, 'brochure', 'brochure_url') }}
                      isValid={formik.touched.brochure && !formik.errors.brochure}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.brochure && formik.errors.brochure}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.brochure}</Form.Control.Feedback>
                    {
                      formik.values.brochure_url && <div>
                        <Button className='save-btn me-4' onClick={handleClick}>View Pdf</Button>
                      </div>
                    }
                  </div>
                </Form.Group>
              </Row>
              <div className="group">
                <Button type="button" className='save-btn me-4' onClick={() => setFormView('details')}>Back</Button>
                <Button
                  type="button"
                  onClick={() =>
                    formik.values?.image &&
                    formik.values?.logo  ?
                    setFormView("gallery_image") : markAllFieldsAsTouched()
                  }
                  className="save-btn me-4"
                >
                  Next
                </Button>
              </div>
            </Fragment>
          }
          {formView == "gallery_image" && (
            <Fragment>
              <Row className="mb-2">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="formFile"
                  className="mb-3"
                >
                  <Form.Label>Gallery Images</Form.Label>

                  <div className="file_wrapper">

                    <Form.Control
                      type="file"
                      name="gallery_images"
                      accept="image/*"
                      multiple
                      onChange={(e) => { handleGalleryImage(e) }}
                      isValid={formik.touched.gallery_images && !formik.errors.gallery_images}
                      isInvalid={!!formik.errors.gallery_images}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.gallery_images}</Form.Control.Feedback>
                    <div className='d-flex flex-row align-items-center justify-content-start flex-nowrap' style={{ overflowX: 'auto' }}>
                      {formik.values?.gallery_image_urls?.map(url =>
                        <div className='img-wrapper ms-2'>
                          <img src={url} alt='course' style={{ height: '100px', width: '150px' }} className=' mt-2' />
                        </div>
                      )}
                    </div>
                  </div>
                </Form.Group>
              </Row>
              <div className="group">
                <Button type="button" className='save-btn me-4' onClick={() => setFormView('university_image')}>Back</Button>
                <Button type="button" onClick={() => {
                  !formik.errors.gallery_images && setFormView('gallery_videos')
                }} className='save-btn me-4'>Next</Button>
              </div>
            </Fragment>
          )}
          {formView == 'gallery_videos' &&
            <Fragment>
              <div>
                <Row className="mb-2">
                  {formik.values?.gallery_videos?.map((video, index) => {
                    if (video === undefined) {
                      setScholarErrors(true)
                      return;
                    }
                    const touched = formik.touched?.gallery_videos && formik.touched?.gallery_videos[index];
                    return <div className='d-flex flex-row align-items-center'>
                      <Form.Group as={Col} md="5" controlId="formFile" className="mb-3">
                        <Form.Label>{index == 0 && 'Gallery Videos'}</Form.Label>
                        <div className="file_wrapper">
                          <div className='d-flex flex-row align-items-center justify-content-between'>
                            <Form.Control
                              type="text"
                              name={`gallery_videos[${index}].gallery_videos`}
                              value={video.gallery_videos}
                              onChange={formik.handleChange}
                              isValid={touched && scholarErrors}
                              onBlur={formik.handleBlur}
                              isInvalid={formik.touched.gallery_videos && formik.errors.gallery_videos && formik.errors.gallery_videos[index] && formik.errors.gallery_videos[index]?.gallery_videos && !!formik.errors.gallery_videos[index]?.gallery_videos}
                              placeholder="Gallery Videos"
                              maxLength={100}
                            />
                          </div>
                        </div>
                        <div className="text-danger" style={{ marginTop: "10px", marginLeft: "5px" }}>{formik.errors.gallery_videos && formik.errors.gallery_videos[index] && formik.errors.gallery_videos[index]?.gallery_videos}</div>
                        <div className='d-flex'>
                          {index == formik.values?.gallery_videos?.length - 1 &&
                            <div>
                              <Button type="button" onClick={() => addNewVideo()} className='save-btn ms-2  '>Add</Button>
                            </div>
                          }
                          {formik.values?.gallery_videos?.length > 1 && <div>
                            <Button type="button" onClick={() => deleteNewVideo(index)} className='save-btn ms-2  '>Delete</Button>
                          </div>}
                        </div>
                      </Form.Group>
                    </div>
                  })}
                </Row>
              </div>

              <div className="group">
                {isloader === true ? (
                  <div className="loader">
                    <img src={loader} alt="" />
                  </div>
                ) : (
                  <>
                    <Button
                      type="button"
                      className="save-btn me-4"
                      onClick={() => setFormView("gallery_image")}
                    >
                      Back
                    </Button>
                    <Button type="submit" className="save-btn me-4"  >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </Fragment>
          }
          {/* {formView == 'pre_requsites' &&
            <Fragment>
              <div>
                <Row className="mb-2">
                  {formik.values?.applications?.pre_requsites?.map((requsite, index) => {
                    if (requsite === undefined) {
                      setPreErrors(true)
                      return;
                    }
                    const preTouched = formik.touched?.pre_requsites && formik.touched?.pre_requsites[index];
                    return <div className='d-flex flex-row align-items-center'>
                      <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                        <Form.Label>{index == 0 && 'Prerequisite'}</Form.Label>
                        <div className="file_wrapper">
                          <div className='d-flex flex-row align-items-center justify-content-between'>
                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                              <Form.Control
                                type="text"
                                name={`applications.pre_requsites[${index}].pre_requsites_details`}
                                value={requsite?.pre_requsites_details}
                                onChange={formik.handleChange}
                                placeholder="Prerequisite"
                                maxLength={100}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className='d-flex'>
                          {index === formik.values?.applications?.pre_requsites?.length - 1 &&
                            <Button type="button" onClick={() => addNewRequsite()} className='save-btn me-2 '>Add</Button>
                          }
                          {formik.values?.applications?.pre_requsites?.length > 1 && <div>
                            <Button type="button" onClick={() => deleteNewRequsite(index)} className='save-btn me-2  '>Delete</Button>
                          </div>}
                        </div>

                      </Form.Group>
                    </div>
                  })}
                </Row>
              </div>
              <div className="group">
                <Button type="button" className='save-btn me-2' onClick={() => setFormView('gallery_videos')}>Back</Button>
                <Button type="button" onClick={() => setFormView('score_requirements')} className='save-btn me-2'>Next</Button>
              </div>
            </Fragment>
          } */}
          {/* {formView === 'score_requirements' &&
            <Fragment>
              <div>
                <Row className="mb-2">
                  {formik.values?.applications?.score_requirements?.map((requirement, index) => {
                    if (requirement === undefined) {
                      setScoreErrors(true)
                      return;
                    }
                    const scoreTouched = formik.touched?.score_requirements && formik.touched?.score_requirements[index];
                    return <div className='d-flex flex-row align-items-center'>
                      <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                        <Form.Label>{index == 0 && 'Score Requirements'}</Form.Label>
                        <div className="file_wrapper">
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom04"
                            >
                              <Form.Control
                                type="text"
                                name={`applications.score_requirements[${index}].score_requirement_details`}
                                value={requirement?.score_requirement_details}
                                onChange={formik.handleChange}
                                placeholder="Score requirements"
                                maxLength={100}
                              />
                            </Form.Group>
                          </div>
                          <div className='d-flex'>
                            {index === formik.values?.applications?.score_requirements?.length - 1 &&
                              <Button type="button" onClick={() => addNewScore()} className="save-btn me-2 " >
                                Add
                              </Button>
                            }
                            {formik.values?.applications?.score_requirements?.length > 1 && <div>
                              <Button type="button" onClick={() => deleteNewScore(index)} className='save-btn ms-2  '>Delete</Button>
                            </div>}
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  })}
                </Row>
              </div>
              <div className="group">
                {isloader === true ? (
                  <div className="loader">
                    <img src={loader} alt="" />
                  </div>
                ) : (
                  <>
                    <Button
                      type="button"
                      className="save-btn me-4"
                      onClick={() => setFormView("pre_requsites")}
                    >
                      Back
                    </Button>
                    <Button type="submit" className="save-btn me-4"  >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </Fragment>
          } */}
        </Form>
      </div>
    </React.Fragment>
  )






};

export default CreateUniversity;

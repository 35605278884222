import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ScholarshipCategoryService } from '../../../services/ScholarshipCategoryService';
import ScholarshipCategoryCreate from './ScholarshipCategoryCreate';
import ScholarshipCategoryDatatable from './ScholarshipCategoryDatatable';
import { checkUserPermission } from '../../checkPermission';

const ScholarshipCategory = () => {

  const scholarshipCategoryService = new ScholarshipCategoryService();

  const formRef = useRef(null);
  const [isloader, setIsloader] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [toaster, setToaster] = useState("");
  const [data, setData] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [tableloader, setTableloader] = useState(true);
  const [pending, setPending] = React.useState(true)
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [filterText, setFilterText] = React.useState('');


  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage)
  };
  const handleChange = () => { setSelectedRows(selectedRows); };


  const handleRowClicked = row => {

    console.log(`${row.name} was clicked!`);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [scroll])

  async function getAllCategories() {
    setPending(true)
    const data = await scholarshipCategoryService.getCategory(perpage, currentPage, filterText);
    setPending(false)
    setData(data.data)
    setTotalRows(data.data.count)
  }

  useEffect(() => {
    getAllCategories()
  }, [refresh, perpage, currentPage, filterText])

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      title: "",
      is_active: true
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      console.log("SSSSSSSTTTTTT")
      setIsloader(true)
      let formData = new FormData();
      if (values.id) {
        formData.append("instance_id", values.id ?? "");
      }
      formData.append("title", values.title);

      scholarshipCategoryService.addOrUpdateCategory(formData).then((response) => {
        if (response && response.status) {
          setIsloader(false)
          console.log(response?.data?.data)
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT
          });
          setRefresh(!refresh)
          formik.resetForm()
          formRef.current.reset();
        }
        else {
          formik.setErrors({
            question: response?.response?.data?.errors?.title,
            answer: response?.response?.data?.errors?.description,
          });
          console.log(response?.response?.data?.errors);
          setIsloader(false)
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    },
  });
  const handleEdit = async (item) => {

    setScroll(preVal=>!preVal)
    formik.setValues({
      id: item.id,
      title: item.title,
    })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Scholarship Category</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Scholarship Category</li>
              </ol>
            </nav>
          </div>
          <React.Fragment>
            {checkUserPermission("Create Scholarship Category") && <ScholarshipCategoryCreate formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} formRef={formRef} />}
          </React.Fragment>
          <React.Fragment>
            <ScholarshipCategoryDatatable
              data={data}
              handleEdit={handleEdit}
              refresh={refresh}
              setRefresh={setRefresh}
              handlePerRowsChange={handlePerRowsChange}
              setCurrentPage={setCurrentPage}
              totalRows={totalRows}
              formik={formik}
              filterText={filterText}
              setFilterText={setFilterText}
              tableloader={tableloader}
              pending={pending}
              currentPage={currentPage}
              handleChange={handleChange}
              handleRowClicked={handleRowClicked}

            />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  )
}

export default ScholarshipCategory
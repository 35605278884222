import request from "../api/agent";
const API_ROUTE = "university/university";

export class CsvService {
    
    async addCsv(data){
        let action = `${API_ROUTE}/import-csv`;
        const response = await request.post(action, data);
        return response;
    }

}
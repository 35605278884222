import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';


import { dataURLtoFile } from '../../functions';

import { EnrollService } from '../../../services/EnrollServices';
import CreateEnroll from './CreateEnroll';
import EnrollDatatable from './EnrollDatatable';
import { checkUserPermission } from '../../checkPermission';

const enrollservice = new EnrollService();



const Enroll = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)
    const [selectedRows, setSelectedRows] = React.useState([]);

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };



    async function getEnroll() {
        setPending(true)
        const data = await enrollservice.getAllEnroll(perpage, currentPage,filterText);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [scroll])
    useEffect(() => {
        getEnroll()
    }, [refresh, perpage, currentPage,filterText])

    const validationSchema = Yup.object().shape({
        // feed: Yup.string()
        //     .required('Required'),
        title: Yup.string()
            .required('Required'),
        climate: Yup.string()
            .required('Required'),
        // image: Yup.string()
        //     .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            instance_id: "",
            title: "",
            climate: "",
            logo: "",
            is_active: true

        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            // let image = values.image


            // values.id ? image = await dataURLtoFile(values.image) : image = values.image
            if (values.logo == "") {
                errors.logo = "Required"
            }
            else if (values?.logo?.type !== "image/png" && values?.logo?.type !== "image/jpg" && values?.logo?.type !== "image/jpeg") {
                errors.logo = "Only jpg/jpeg and png files are allowed!"
            }
            console.log(values.logo);
            return errors
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.instance_id) {
                formData.append("instance_id", values.instance_id ?? "");
            }
            formData.append("title", values.title);
            formData.append("climate", values.climate);
            formData.append("logo", values.logo);
           


            enrollservice.addOrUpdateEnroll(formData).then((response) => {
                // })
                // CountryCreateOrUpdate({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        instance_id: response?.response?.data?.errors?.instance_id,
                        title: response?.response?.data?.errors?.title,
                        climate: response?.response?.data?.errors?.climate,
                        logo: response?.response?.data?.errors?.logo,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
       
        formik.setValues({
            instance_id: item.id,
            title: item.title,
            climate: item.climate,
            logo: await dataURLtoFile(item.logo),
            image_url: item?.logo
           



        })
        setScroll(preVal=>!preVal)
        console.log(item);
    }

    console.log(formik)
    return (
        <div>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Enroll</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Enroll</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        { checkUserPermission("Create Enroll") && <CreateEnroll formik={formik} setImage={setImage} isloader={isloader} toaster={toaster} setToaster={setToaster} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <EnrollDatatable handleRowClicked={handleRowClicked} handleChange={handleChange} currentPage={currentPage} pending={pending} filterText={filterText} setFilterText={setFilterText}  data={data} setCurrentPage={setCurrentPage} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>

        </div>
    )
}


export default Enroll



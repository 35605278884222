import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CourseService } from '../../../services/CourseService';
import { UniversityService } from '../../../services/universityService';
import Select from 'react-select';
import moment from 'moment';

const courseService = new CourseService();
const universityService = new UniversityService();


const CreateUniversityCourse = ({ intakeValue, setFormView, formView, formik, isloader, formRef,markAllFieldsAsTouched }) => {
    const [unversities, setUniversities] = useState([]);
    const [courses, setCourses] = useState([]);
    const [scholarErrors, setScholarErrors] = useState(false);
    ;


    useEffect(() => {
        getAllCourses()
        getAllUniversities()
    }, [])

    const deleteBtn = {
        padding: "5px 20px"
    }
    const addBtn = {
        padding: "5px 25px"
    }

    const handleSelectChange = ({ name, value }) => {
        formik.setValues({ ...formik.values, [name]: value.map(val => val) })
        // setIntakeData(value.map(val => val.label))
    }

    async function getAllCourses() {
        const data = await courseService.getAllCourses();
        setCourses(data?.data?.results)
        console.log(data);
    }
    async function getAllUniversities() {
        const data = await universityService.getUniversity();
        setUniversities(data?.data?.results)
        console.log(data);
    }


    const addNewFeedDetails = () => {
        let details = formik.values.fee_details;
        details.push({
            term: '',
            fee_head: '',
            amount: ''
        })
        formik.setValues({ ...formik.values, fee_details: details })
    }

    const deleteNewFeedDetails = (index) => {
        let details = formik.values.fee_details;
        details.splice(index, 1)
        formik.setValues({ ...formik.values, fee_details: details })
    }

    const addNewEligibility = () => {
        let eligibility = formik.values.eligibility;
        eligibility.push({
            eligibility_details: ''
        })
        formik.setValues({ ...formik.values, eligibility: eligibility })
    }

    const deleteNewEligibility = (index) => {
        let eligibility = formik.values.eligibility;
        eligibility.splice(index, 1)
        formik.setValues({ ...formik.values, eligibility: eligibility })
    }
    const addNewEntrance = () => {
        let entrance = formik.values.entrance;
        entrance.push({
            entrance_details: ''
        })
        formik.setValues({ ...formik.values, entrance: entrance })
    }
    const deleteNewEntrance = (index) => {
        let entrance = formik.values.entrance;
        entrance.splice(index, 1)
        formik.setValues({ ...formik.values, entrance: entrance })
    }

    const addNewSyllabusDetails = (index) => {
        let syllabus = formik.values.syllabus;
        syllabus[index]?.syllabus_details?.push({
            details_syllabus: ''
        })
        formik.setValues({ ...formik.values, syllabus: syllabus })
    }
    const deleteNewSyllabusDetails = (index, detailIndex) => {
        let syllabus = formik.values.syllabus;
        syllabus[index]?.syllabus_details?.splice(detailIndex, 1)
        formik.setValues({ ...formik.values, syllabus: syllabus })
    }

    const addNewSyllabus = () => {
        let syllabus = formik.values.syllabus;
        syllabus.push({
            course_title: '',
            weeks: '',
            assignments: '',
            syllabus_details: [{
                details_syllabus: ''
            }],
        })
        formik.setValues({ ...formik.values, entrance: syllabus })
    }
    const deleteNewSyllabus = (index) => {
        let syllabus = formik.values.syllabus;
        syllabus.splice(index, 1)
        formik.setValues({ ...formik.values, entrance: syllabus })
    }

    const addNewDocuments = () => {
        let required_documents = formik.values.required_documents;
        required_documents?.push({
            required_details: ''
        })
        formik.setValues({ ...formik.values, required_documents: required_documents })
    }
    const deleteNewDocuments = (index) => {
        let required_documents = formik.values.required_documents;
        required_documents.splice(index, 1)
        formik.setValues({ ...formik.values, required_documents: required_documents })
    }

    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    {formView == 'details' &&
                        <Fragment>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>Course</Form.Label>
                                    <Form.Select
                                        placeholder="Course"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="course"
                                        value={formik.values?.course}
                                        isInvalid={!!formik.errors?.course}
                                        onChange={formik.handleChange}
                                    >
                                        <option value='' >Select an Option</option>
                                        {courses?.map(type =>
                                            <option value={type?.id}>{type?.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{formik.errors.course}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>University</Form.Label>
                                    <Form.Select
                                        placeholder="University"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="university"
                                        value={formik.values?.university}
                                        isInvalid={!!formik.errors?.university}
                                        onChange={formik.handleChange}
                                    >
                                        <option value='' >Select an Option</option>
                                        {unversities?.map(type =>
                                            <option value={type?.id}>{type?.university_name}</option>
                                        )}


                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{formik.errors.university}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Duration</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="duration"
                                        value={formik.values?.duration}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.duration && !formik.errors.duration}
                                        isInvalid={!!formik.errors.duration}
                                        placeholder="Duration"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.duration}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Average Fees</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="fees"
                                        value={formik.values?.fees}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.fees && !formik.errors.fees}
                                        isInvalid={!!formik.errors.fees}
                                        placeholder="Average Fees"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.fees}</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Time</Form.Label>
                                    <Form.Select
                                        placeholder="Time"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="time"
                                        value={formik.values?.time}
                                        isInvalid={!!formik.errors?.errors?.time}
                                        onChange={formik.handleChange}
                                    >
                                        <option value='' >Select an Option</option>
                                        <option value={1}>Full Time</option>
                                        <option value={2}>Part Time</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{formik.errors.time}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Application Deadline</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="application"
                                        value={formik.values?.application}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.application && !formik.errors.application}
                                        isInvalid={!!formik.errors.application}
                                        placeholder="Application"
                                        maxLength={100}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.application}</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row className="mb-2">
                                {/* <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Intake</Form.Label>
                                    <Form.Select
                                        placeholder="Intake"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="in_take"
                                        value={formik.values?.in_take}
                                        isInvalid={!!formik.errors?.errors?.in_take}
                                        onChange={formik.handleChange}
                                        multiple

                                    >
                                        <option value='' >Select an Option</option>
                                        <option value='Janaury'>January</option>
                                        <option value='February'>February</option>
                                        <option value='March'>March</option>
                                        <option value='April'>April</option>
                                        <option value='May'>May</option>
                                        <option value='June'>June</option>
                                        <option value='July'>July</option>
                                        <option value='August'>August</option>
                                        <option value='September'>September</option>
                                        <option value='October'>October</option>
                                        <option value='November'>November</option>
                                        <option value='December'>December</option>

                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{formik.errors.in_take}</Form.Control.Feedback>
                                </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>Intake</Form.Label>
                                    <Select
                                        // className={formik.errors?.specialization ? 'error-select basic-single' : "basic-single "}
                                        classNamePrefix="select"
                                        isMulti
                                        // styles={style}
                                        value={formik.values?.intake}
                                        name="intake"
                                        options={intakeValue}
                                        onChange={(e) => { handleSelectChange({ name: 'intake', value: e }) }}
                                        placeholder="Select intake..."
                                    />
                                    <div className="errorMessage">{formik.errors.intake}</div>
                                </Form.Group>
                            </Row>

                            <div className="group">
                                <Button type="button" onClick={() =>
                                    formik.values?.course  &&   formik.values?.university ? setFormView('entrance') : markAllFieldsAsTouched()}
                                    className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    }
                    {/* {formView === 'fee_details' &&
                        <Fragment>
                            {formik.values?.fee_details?.map((detail, index) =>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                                        <Form.Label>{index == 0 && 'Term'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`fee_details[${index}].term`}
                                            value={detail?.term}
                                            onChange={formik.handleChange}
                                            placeholder="Term"
                                            maxLength={100}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                                        <Form.Label>{index == 0 && 'Fee Head'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`fee_details[${index}].fee_head`}
                                            value={detail?.fee_head}
                                            onChange={formik.handleChange}
                                            placeholder="Fee Head"
                                            maxLength={100}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                                        <Form.Label>{index == 0 && 'Amount'}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={`fee_details[${index}].amount`}
                                            value={detail?.amount}
                                            onChange={formik.handleChange}
                                            placeholder="Amount"
                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.errors?.fee_details && formik.errors?.fee_details[index] && formik.errors?.fee_details[index]?.amount}</Form.Control.Feedback>
                                        <div className='d-flex'>
                                            {index == formik.values.fee_details?.length - 1 &&
                                                <div>
                                                    <Button type="button" onClick={() => addNewFeedDetails()} className='save-btn me-2  ' style={addBtn}>Add</Button>
                                                </div>
                                            }
                                            <div>
                                                {formik.values.fee_details?.length > 1 && <Button type="button" onClick={() => deleteNewFeedDetails(index)} className='save-btn ms-2 ms-2 mb-4  ' style={deleteBtn}>Delete</Button>}
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Row>
                            )}
                            <div className="group">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('details')}>Back</Button>
                                <Button type="button" onClick={() => setFormView('eligibility')} className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    } */}
                    {/* {formView == 'eligibility' &&
                        <Fragment>
                            {formik.values?.eligibility?.map((eligibility, index) =>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                                        <Form.Label>{index == 0 && 'Eligibility Details'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`eligibility[${index}].eligibility_details`}
                                            value={eligibility?.eligibility_details}
                                            onChange={formik.handleChange}
                                            placeholder="Eligibility Details"
                                            maxLength={100}
                                        />
                                        <div className='d-flex'>
                                            {formik.values.eligibility?.length - 1 == index &&
                                                <div as={Col} md="6" controlId="validationCustom04">
                                                    <Button type="button" onClick={() => addNewEligibility()} className='save-btn me-4' style={addBtn}>Add</Button>
                                                </div>
                                            }
                                            {formik.values.eligibility?.length > 1 && <Button type="button" onClick={() => deleteNewEligibility(index)} className='save-btn me-4' style={deleteBtn}>Delete</Button>}
                                        </div>
                                    </Form.Group>
                                </Row>
                            )}
                            <div className="group">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('fee_details')}>Back</Button>
                                <Button type="button" onClick={() => setFormView('entrance')} className='save-btn me-4'>Next</Button>

                            </div>
                        </Fragment>
                    } */}
                    {formView == 'entrance' &&
                        <Fragment>
                            {formik.values?.entrance?.map((entrance, index) =>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                                        <Form.Label>{index == 0 && 'Entrance Details'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`entrance[${index}].entrance_details`}
                                            value={entrance?.entrance_details}
                                            onChange={formik.handleChange}
                                            // isValid={formik.touched?.entrance && formik.touched?.entrance[index] && formik.touched?.entrance[index]?.entrance_details && !formik.errors.entrance[index]?.entrance_details}
                                            // isInvalid={formik.errors?.entrance && formik.errors?.entrance[index] && !!formik.errors?.entrance[index]?.entrance_details}
                                            placeholder="eg. IELTS - 7.0"
                                            maxLength={100}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">{formik.errors?.entrance && formik.errors?.entrance[index] && formik.errors?.entrance[index]?.entrance_details}</Form.Control.Feedback> */}
                                        <div className='d-flex '>
                                            {formik.values.entrance?.length - 1 == index &&
                                                <div as={Col} md="6" controlId="validationCustom04">
                                                    <Button type="button" onClick={() => addNewEntrance()} className='save-btn me-4' style={addBtn}>Add</Button>
                                                </div>
                                            }
                                            <div>
                                                {formik.values.entrance?.length > 1 && <Button type="button" onClick={() => deleteNewEntrance(index)} className='save-btn me-4' style={deleteBtn}>Delete</Button>}
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Row>
                            )}
                            <div className="group mt-3">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('details')}>Back</Button>
                                <Button type="button" onClick={() => setFormView('required_documents')} className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    }

                    {/* {formView == 'syllabus' &&
                        <Fragment>
                            {formik.values?.syllabus?.map((syllabus, index) =>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>{index == 0 && 'Course Title'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`syllabus[${index}].course_title`}
                                            value={syllabus?.course_title}
                                            onChange={formik.handleChange}
                                            placeholder="Course Title"
                                            maxLength={100}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>{index === 0 && 'Weeks'}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={`syllabus[${index}].weeks`}
                                            value={syllabus?.weeks}
                                            onChange={formik.handleChange}
                                            placeholder="Weeks"
                                            maxLength={100}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>{index === 0 && 'Assignments'}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={`syllabus[${index}].assignments`}
                                            value={syllabus?.assignments}
                                            onChange={formik.handleChange}
                                            placeholder="Assignments"
                                            maxLength={100}
                                        />
                                    </Form.Group>
                                    {syllabus?.syllabus_details?.map((detail, ind) =>
                                        <Fragment>
                                            <Form.Group as={Col} md="100%" controlId="validationCustom04">
                                                <Form.Label>{index === 0 && 'Syllabus Details'}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name={`syllabus[${index}].syllabus_details[${ind}].details_syllabus`}
                                                    value={detail?.details_syllabus}
                                                    onChange={formik.handleChange}
                                                    placeholder="Syllabus Details"
                                                    maxLength={100}
                                                />
                                                {syllabus?.syllabus_details?.length - 1 === ind &&
                                                    <div className="d-flex">
                                                        <div>
                                                            <Button type="button" onClick={() => addNewSyllabusDetails(index)} className='save-btn me-4 ' style={addBtn}>Add</Button>
                                                        </div>
                                                        {syllabus?.syllabus_details?.length > 1 && <Button type="button" onClick={() => deleteNewSyllabusDetails(index, ind)} className='save-btn me-4' style={deleteBtn}>Delete</Button>}
                                                    </div>
                                                }
                                                <div className='d-flex justify-content-end '>
                                                    {formik.values.syllabus?.length - 1 === index &&
                                                        <div>
                                                            <Button type="button" onClick={() => addNewSyllabus()} className='save-btn me-4' style={addBtn}>Add</Button>
                                                        </div>}
                                                    <div>
                                                        {formik.values.syllabus?.length > 1 && <Button type="button" onClick={() => deleteNewSyllabus(index)} className='save-btn me-4' style={deleteBtn}>Delete</Button>}
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Fragment>
                                    )}
                                </Row>
                            )}
                            <div className="group">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('entrance')}>Back</Button>
                                <Button type="button" onClick={() => setFormView('required_documents')} className='save-btn me-4'>Next</Button>
                            </div>
                        </Fragment>
                    } */}
                    {formView === 'required_documents' &&
                        <Fragment>
                            {formik.values?.required_documents?.map((document, index) =>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                                        <Form.Label>{index === 0 && 'Required Documents'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`required_documents[${index}].required_details`}
                                            value={document?.required_details}
                                            onChange={formik.handleChange}
                                            // isValid={formik.touched?.required_documents && formik.touched?.required_documents[index] && formik.touched?.required_documents[index]?.required_details && !formik.errors.required_documents[index]?.required_details}
                                            isInvalid={formik.errors?.required_documents && formik.errors?.required_documents[index] && !!formik.errors?.required_documents[index]?.required_details}
                                            placeholder="eg. VISA"
                                            maxLength={100}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.errors?.required_documents && formik.errors?.required_documents[index] && formik.errors?.required_documents[index]?.required_details}</Form.Control.Feedback>
                                    </Form.Group>
                                    <div className='d-flex'>
                                        {formik.values.required_documents?.length - 1 === index &&
                                            <div>
                                                <Button type="button" onClick={() => addNewDocuments()} className='save-btn me-4' style={addBtn}>Add</Button>
                                            </div>
                                        }
                                        {formik.values.required_documents?.length > 1 && <Button type="button" onClick={() => deleteNewDocuments(index)} className='save-btn me-4 ' style={deleteBtn}>Delete</Button>}
                                    </div>
                                </Row>
                            )}
                            <div className="group">
                                {isloader === true ?
                                    <div className='loader'>
                                        <img src={loader} alt="" />
                                    </div>
                                    : <>
                                        <Button type="button" className='save-btn me-4' onClick={() => setFormView('entrance')}>Back</Button>
                                        <Button type="submit" className='save-btn me-4'>Save</Button>
                                    </>
                                }
                            </div>
                        </Fragment>
                    }

                </Form>
            </div>

        </React.Fragment>
    )
}

export default CreateUniversityCourse;

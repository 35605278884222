// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CreateRoles from './createRoles';
import RolesDatatable from './rolesDatatable';
import { RolesService } from '../../../services/RolesService';
import { checkUserPermission } from '../../checkPermission';

const rolesService = new RolesService();

const Roles = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [pending, setPending] = React.useState(true)
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [scroll])


    async function getAllStreams() {
        setPending(true)
        const data = await rolesService.getAllRoles(perpage, currentPage);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)

    }
    useEffect(() => {
        getAllStreams(perpage, currentPage)
    }, [refresh])
    const handleChange = () => { setSelectedRows(selectedRows); };
    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
  
  
    const handleRowClicked = row => {
  
        console.log(`${row.name} was clicked!`);
    };
  

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),

    });

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            permissions: [],
        },
        validationSchema,

        onSubmit: (values) => {
            setIsloader(true)
            let formData = {};
            if (values.id) {
                formData.role = values.id;
            }
            formData.name = values.name;
            formData.permissions = values.permissions;

            rolesService.addOrUpdateRole(formData).then((response) => {

                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_name: response?.response?.data?.errors?.title,
                        course_type: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        
        formik.setValues({
            id: item.id,
            name: item.name,
            permissions: item.permissions,
        })
        setScroll(preVal=>!preVal)
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Roles</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Roles</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create Roles") && <CreateRoles image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <RolesDatatable setCurrentPage={setCurrentPage}   handleRowClicked={handleRowClicked} handlePerRowsChange={handlePerRowsChange} totalRows={totalRows} handleChange={handleChange} currentPage={currentPage}  pending={pending}  data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik}  />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Roles;
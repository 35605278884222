// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { RolesService } from '../../../services/RolesService';
import CreateGroup from './createGroup';
import GroupDatatable from './groupDatatable';
import { GroupService } from '../../../services/GroupService';
import { checkUserPermission } from '../../checkPermission';

const groupService = new GroupService();

const Groups = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [roles, setRoles] = useState([]);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [pending, setPending] = React.useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [selectedRows, setSelectedRows] = React.useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [scroll])

  const handleChange = () => { setSelectedRows(selectedRows); };


  const handleRowClicked = row => {

      console.log(`${row.name} was clicked!`);
  };

  const [totalRows, setTotalRows] = React.useState(0);
  const handlePerRowsChange = async (newPerPage) => {
      setPerpage(newPerPage)
  };

    async function getAllGroups() {
        setPending(true)
        const data = await groupService.getAllGroup(perpage,currentPage);
        setPending(false)
        setData(data.data)
    }
    useEffect(() => {
        getAllGroups()
    }, [refresh,perpage,currentPage])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
            roles:Yup.array().min(1).required("Required"),
        
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            roles: [],
        },
        validationSchema,
        validate: async (values) => {
         const errors = {}
            if (values.roles == "") {
                errors.roles = "Required"
            }
            // else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
            //     errors.image = "Only jpg/jpeg and png files are allowed!"
            // }
            // console.log(values.image);
            return errors;
        },
        onSubmit: (values) => {
            setIsloader(true)
            let formData = {};
            if (values.id) {
                formData.group = values.id;
            }
            formData.name = values.name;
            formData.roles = roles;

            groupService.addOrUpdateGroup(formData).then((response) => {

                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_name: response?.response?.data?.errors?.title,
                        course_type: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        const data = await groupService.getGroupDetails(item.pk);
        formik.setValues({
            id: data.data?.group?.id,
            name: data.data?.group?.name,
            roles: data.data?.group?.roles,
        })
    }
console.log(formik.errors);
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Groups</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Groups</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        { checkUserPermission("Create Groups") && <CreateGroup image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} roles={roles} setRoles={setRoles} />}
                    </React.Fragment>
                    <React.Fragment>
                        <GroupDatatable handleRowClicked={handleRowClicked} handleChange={handleChange} currentPage={currentPage} pending={pending} data={data} setCurrentPage={setCurrentPage} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik}/>
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Groups;
// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import CreateUser from './createUser';
import UserDatatable from './userDatatable';
import { UserService } from '../../../services/UserService';
import { checkUserPermission } from '../../checkPermission';

const userService = new UserService();

const Users = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [groups, setGroups] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [totalRows, setTotalRows] = React.useState(0);
    const [scroll, setScroll] = useState(false);
    const [pending, setPending] = React.useState(true)
    const [passField, setPassField] = useState(true)

    
    
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])

    async function getAllUsers() {
        setPending(true)
        const data = await userService.getUsers(currentPage,perpage);
        setPending(false)
        setData(data.data)
        console.log(data?.data)
        setTotalRows(data?.data?.count)
    }
    useEffect(() => {
        getAllUsers()
    }, [refresh,currentPage,perpage])

    const validationSchema = Yup.object().shape({
        fullname: Yup.string()
            .required('Required'),
        email: Yup.string().email()
            .required('Required'),
        username: Yup.string()
            .required('Required'),
        phone: Yup.number()
            .required('Required'),
        groups:  Yup.array().min(1).required("Required"),
        


    });
  
    const formik = useFormik({
        initialValues: {
            instance_id: "",
            fullname: "",
            email: "",
            username: "",
            phone: "",
            password: "",
            image: '',
            image_url: '',
            groups:"",
            edit:false,
        },
        // validationSchema,
        // validate: async (values) =>{
        //     const errors = {}
        //    values.password ? errors.password = "Required" : "";
           
        // },

        validationSchema,

        validate: async (values) => {
            const errors = {}
            if(passField){
                if(values.password === ""){
                    errors.password = "Required"
                }
              }
        //    if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
        //         errors.image = "Only jpg/jpeg and png files are allowed!"
        //     }
            console.log(values.image);
            return errors
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
           
            
            let formData = new FormData();
            
            if (values.instance_id) {
                formData.append("instance_id", values.instance_id  ?? "");
            }
            if (passField) {
                formData.password = values.password;
            }
            formData.append("fullname", values.fullname);
            formData.append("email", values.email);
            formData.append("username", values?.username);
            
            formData.append("phone", values?.phone);
            if (values?.password && values?.password != '')
                formData.append("password", values?.password);
            formData.append("image", values?.image);
            formData.append("groups",JSON.stringify(groups));

            userService.addOrUpdateUser(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_name: response?.response?.data?.errors?.title,
                        course_type: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                        email: response?.response?.data?.errors?.email,
                        password: response?.response?.data?.errors?.password,
                        phone:response?.response?.data?.errors?.phone ,
                    }); 
                    setIsloader(false)
                    toast.error( response?.response?.data?.errors?.email?  response?.response?.data?.errors?.email[0] :    response?.response?.data?.errors?.phone ? response?.response?.data?.errors?.phone[0] :"Something went wrong" ,
                 //  response?.response?.data?.errors?.phone ? response?.response?.data?.errors?.phone[0] : "Something went wrong",
                     {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    console.log(formik.errors);
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        formik.setValues({

            instance_id: item?.id,
            fullname: item?.fullname,
            email: item?.email,
            username: item?.username,
            phone: item?.phone,
            edit:true,
            image: item?.image ? await dataURLtoFile(item?.image) : '',
            image_url: item?.image ? item?.image : '',
            groups: item?.user_groups,
            

        })
        setPassField(false)

    }
console.log(formik.errors)
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Users</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Users</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        <CreateUser passField={passField}  image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} groups={groups} setGroups={setGroups} />
                    </React.Fragment>
                    <React.Fragment>
                        <UserDatatable currentPage={currentPage} pending={pending}  data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} setCurrentPage={setCurrentPage} setPerpage={setPerpage} totalRows={totalRows} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Users;
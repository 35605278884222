import request from "../api/agent";

const API_ROUTE = "scholarship/scholarship";

export class ScholarshipService {

    async getScholarship(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-scholarship?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


    async addOrUpdateScholarship(scholarshipData) {
        let action = `${API_ROUTE}/create-or-update-scholarship`;
        const response = await request.post(action, scholarshipData);
        return response;
    }

    async deleteScholarship(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-scholarship`, {data: { ids: [id] }});
        return response;
    }

    async setSchoplarshipStatus(id){
        const response = await request.put(`${API_ROUTE}/scholarship-status-change`,{id:id});
        return response;
    }
}
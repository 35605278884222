import request from "../api/agent";

const API_ROUTE = "/country/admin";

export class DistrictService {

    async getDistrict(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/district-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


    async addOrUpdateDistrict(district) {
        let action = `${API_ROUTE}/district-register`;
        const response = await request.post(action, district);
        return response;
    }

    async deleteDistrict(id) {
        const response = await request.delete(`${API_ROUTE}/district-delete`, {data: { district_id: [id] }});
        return response;
    }

    async setDistrictStatus(id){
        const response = await request.post(`${API_ROUTE}/district-status`,{district_id:id});
        return response;
    }
}
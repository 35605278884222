import axios from "axios";
import request from "../api/agent";

const API_ROUTE ="/enquiry/enquiry";

export class EnquiryService {

    async getEnquiry( perpage,currentPage,search,value) {
        const serchValue = search??""
        const response = await axios.get(`${API_ROUTE}/list-enquiry?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }

    async deleteEnquiry(id) {
        const response = await request.delete(`${API_ROUTE}/enquiry-delete`, {data: { enquiry_id: id }});
        return response;
    }
    async enquiryStatus(data) {
        let action = `${API_ROUTE}/is-contacted-or-not`;
        const response = await request.post(action, data);
        return response;

    }
    
    async enquiryServiceStatus(id) {
        let action = `${API_ROUTE}/is-contacted-or-not`;
        const response = await request.post(action, {contact_id : id});
        return response;
    }

}
import React, { useState } from 'react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { PREnquiryService } from '../../../services/prEnquiryService';
import PREnquiryDatatable from './prEnquiryDatatable';
import { Link } from 'react-router-dom';

const prenquiryservice = new PREnquiryService();

const PREnquiry = () => {

    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)

    const [typeValue, setTypeValue] = useState();

    const [contactValue, setContactValue] = useState();


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    async function getServiceEnquery() {
        setPending(true)
        const data = await prenquiryservice.getAllServiceEnquiry(currentPage, perpage, filterText, typeValue, contactValue);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data?.count)
    }

    useEffect(() => {
        getServiceEnquery()
    }, [refresh, currentPage, perpage, filterText, typeValue, contactValue])


    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>PR Enquiries</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">PR Enquiries</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        <PREnquiryDatatable pending={pending} currentPage={currentPage} setTypeValue={setTypeValue} setContactValue={setContactValue} typeValue={typeValue} filterText={filterText} setFilterText={setFilterText} data={data} setData={setData} setTotalRows={setTotalRows} refresh={refresh} setRefresh={setRefresh} setCurrentPage={setCurrentPage} setPerpage={setPerpage} totalRows={totalRows} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default PREnquiry; 
import request from "../api/agent";
const API_ROUTE = "/appointment/enroll";

export class EnrollService {
    async addOrUpdateEnroll(enroll) {
        let action = `${API_ROUTE}/create-or-update-enroll`;
        const response = await request.post(action, enroll);
        return response;
    }

    async getAllEnroll(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-enroll?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


  async deleteEnroll(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-endroll`, {
            data: { ids: [id] }
        } );
        
        return response;
      }
      async setEnrollStatus(id){
        const response = await request.put(`${API_ROUTE}/endroll-status-change`,{id:id})
        return response;
    }
}
import request from "../api/agent";
const API_ROUTE = "course/specialization";

export class SpecializationService {
    async addOrUpdateSpecialization(data) {
        let action = `${API_ROUTE}/specialization-creation`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllSpecialization(search,perpage,currentPage) {
        if(perpage){
            const serchValue = search ?? ""
            const response = await request.get(`${API_ROUTE}/specialization-listing?search=${serchValue}&limit=${perpage}&page=${currentPage}`);
            return response.data;
        }else{
            const response = await request.get(`${API_ROUTE}/specialization-listing`);
            return response.data;
        }
    }


    async deleteSpecialization(id) {
        const response = await request.delete(`${API_ROUTE}/specialization-delete`, {
            data: { specialization: [id] }
        });
        return response;
      }
      async getStatus(id){
        const response = await request.post(`${API_ROUTE}/specialization-status`,{specialization:id})
        return response;
    }

}
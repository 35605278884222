import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CourseTypeService } from '../../../services/CourseTypeService';


const courseTypeService = new CourseTypeService();
const CreateAlumniProfile = ({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {
    const [types, setTypes] = useState([]);
    const onChangeImage = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            formik.setValues({ ...formik.values, image: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) })
        }
    }

    useEffect(() => {
        getAllCourseTypes()
    }, [])

    async function getAllCourseTypes() {
        const data = await courseTypeService.getAllCourseTypes();
        setTypes(data?.data?.results)
        console.log(data);
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.name && !formik.errors.name}
                                isInvalid={!!formik.errors.name}
                                placeholder="User Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>User Image</Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={!!formik.errors.image}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }

                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="company_name"
                                value={formik.values?.company_name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.company_name && !formik.errors.company_name}
                                isInvalid={!!formik.errors.company_name}
                                placeholder="Company Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.company_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Company Image</Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="company_image"
                                    accept="image/*"
                                    values={formik.values.company_image}
                                    onChange={(e) => {
                                        formik.setValues({ ...formik.values, company_image: e.target.files[0], company_image_url: URL.createObjectURL(e.target.files[0]) })
                                    }
                                    }
                                    isValid={formik.touched.company_image && !formik.errors.company_image}
                                    isInvalid={!!formik.errors.company_image}
                                />
                                {formik.values?.company_image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.company_image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }

                                <Form.Control.Feedback type="invalid">{formik.errors.company_image}</Form.Control.Feedback>
                            </div>
                        </Form.Group>

                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateAlumniProfile;
// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import CreateResentIntake from './createResentIntake';
import ResentIntakeDatatable from './resentIntakeDatatable';
import { ResentIntakeService } from '../../../services/ResentIntakeService';
import { checkUserPermission } from '../../checkPermission';

const resentIntakeService = new ResentIntakeService();

const ResentIntake = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [groups, setGroups] = useState([]);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [totalRows, setTotalRows] = React.useState(0);


    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])


    async function getAllUsers() {
        setPending(true)
        const data = await resentIntakeService.getAllResentIntake(filterText,currentPage,perpage);
        setPending(false)

        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getAllUsers()
    }, [refresh,filterText,currentPage,perpage])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        country: Yup.string()
            .required('Required'),

    });

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            country: "",
            image: '',
            image_url: ''
        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            if (values.image == "") {
                errors.image = "Required"
            }
            else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            }
            return errors
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("intake_id", values.id ?? "");
            }
            formData.append("name", values.name);
            formData.append("country", values.country);
            formData.append("image", values?.image);

            resentIntakeService.addOrUpdateResentIntake(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        name: response?.response?.data?.errors?.name,
                        country: response?.response?.data?.errors?.country,
                        image: response?.response?.data?.errors?.image,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        formik.setValues({

            id: item?.id,
            name: item?.name,
            country: item?.country?.id,
            image: item?.image ? await dataURLtoFile(item?.image) : '',
            image_url: item?.image ? item?.image : ''
        })

    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Recent Intake</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Recent Intake</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create Recent Intake") && <CreateResentIntake image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} groups={groups} setGroups={setGroups} />}
                    </React.Fragment>
                    <React.Fragment>
                        <ResentIntakeDatatable  totalRows={totalRows} currentPage={currentPage}  setCurrentPage={setCurrentPage} setPerpage={setPerpage}  pending={pending} filterText={filterText} setFilterText={setFilterText} tableloader={tableloader} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default ResentIntake;
import request from "../api/agent";
const API_ROUTE = "university/university";

export class UniversityService {
    async addOrUpdateUniversity(data) {
        let action = `${API_ROUTE}/create-or-update-university`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllUniversity(page, limit, search, typeValue) {
        // const serchValue = search ?? ""
        console.log(page, limit);
        let BASE_URL = `${API_ROUTE}/get-university-admin?page=${page}&limit=${limit}`
        if (typeValue != "" && typeof (typeValue) != "undefined") {
            BASE_URL = BASE_URL + `&country_id=${typeValue}`
        }
        console.log(BASE_URL);
        if (search != "") {
            BASE_URL = BASE_URL + `&search=${search}`
        }

        // const country_id = typeValue ? typeValue : "";
        const response = await request.get(BASE_URL);
        return response.data;
    }

    async getUniversity() {
        const response = await request.get(`${API_ROUTE}/get-university-admin`);
        return response.data;
    }

    async deleteUniversity(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-university`, {
            data: { instance_id: id }
        });
        return response;
    }
    async setStatus(id) {
        const response = await request.post(`${API_ROUTE}/university-status`, { instance_id: id })
        return response;
    }
    async setTrustedUniversity(id) {
        const response = await request.post(`${API_ROUTE}/university-trusted`, { instance_id: id })
        return response;
    }

}
import request from "../api/agent";

const API_ROUTE = "country/admin";

export class CountryService {
  countries = [];

  async getAllCountries() {
    const response = await request.get(`${API_ROUTE}/country-listing`);
    return response.data;
  }

  async getCountries(perpage, currentPage) {
    
    const response = await request.get(`${API_ROUTE}/country-listing?limit=${perpage}&page=${currentPage}`);
    return response.data;
  }

  resetCountry() {
    const country = {
      id: 0,
      country_name: "",
      country_code: ""
    };
    return country;
  }

  async addOrUpdateCountry(country) {
    let action = `${API_ROUTE}/country-register`;
    const response = await request.post(action, country);
    return response;
  }

  async deleteCountry(id) {
    console.log(";;;;;;;;;;;;;;;;;;;;;;;;;");
    const response = await request.delete(`${API_ROUTE}/country-delete`, {
      data: { country_id: id }
    });
    return response;
  }
  async getStatus(id){
    const response = await request.post(`${API_ROUTE}/country-status`,{country_id:id})
    return response;
}
async getPopular(id){
  const response = await request.post(`${API_ROUTE}/common-country`,{country_id:id})
  return response;

}
}
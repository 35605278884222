import request from "../api/agent";
const API_ROUTE = "/appointment/plan";

export class AboardService{
    async addOrUpdateAboard(aboard) {
        let action = `${API_ROUTE}/create-or-update-plan`;
        const response = await request.post(action, aboard);
        return response;
    }

    async getAllAboard(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-plan?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


  async deleteAboard(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-plan`, {
            data: { ids: [id] }
        } );
        
        return response;
      }
      async setAboardStatus(id){
        const response = await request.put(`${API_ROUTE}/plan-status-change`,{id:id})
        return response;
    }
}
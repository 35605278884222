import request from "../api/agent";
const API_ROUTE = "university/university-course";

export class UniversityCourseService {
    async addOrUpdateUniversityCourse(data) {
        let action = `${API_ROUTE}/create-or-update-university-course`;
        const response = await request.post(action, data);
        return response;
    }



    async getAllUniversityCourses(page, limit, search) {
        const serchValue = search ?? ""
        const response = await request.get(`${API_ROUTE}/get-university-course-admin?search=${serchValue}&page=${page}&limit=${limit}`);
        return response.data;
    }

    async getAllUniversityCoursesList({ search = '' }) {

        console.log('search :>>>>>>>>>>> ', search)
        const response = await request.get(`/scholarship/scholarship/scholarship-dropdown?search=${search}`);
        return response.data;
    }

    async deleteUniversityCourse(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-university-course`, {
            data: { course_id: id }
        });
        return response;
    }
    async setStatus(id) {
        const response = await request.post(`${API_ROUTE}/university-course-status`, { university_course: id })
        return response;
    }

}
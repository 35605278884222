import axios from 'axios';
import { toast } from "react-hot-toast";

const request = axios.create({
  baseURL: "https://api.algateinternational.com/api/",
  // baseURL: "https://algateapi.aventusinformatics.com/api/",
  // baseURL: "http://192.168.18.110:5000/api/",
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json',
    // withCredentials: true
  },
});
function sleep(delay) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

request.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

request.interceptors.response.use(
  async (response) => {
    await sleep(1000);
    const pagination = response.headers['pagination'];
    if (pagination) {
      //   response.data = new PaginatedResult(response.data, JSON.parse(pagination));
      return response;
    }
    if (response.data) return response;
  },
  (error) => {
    const { data, status } = error.response || {};
    switch (status) {
      case 400:
        if (typeof data === 'string') {
          toast.error(data);
        }
        break;
      case 401:
        toast.error('unauthorized');
        break;
      case 404:
        toast.error('not found');
        break;
      case 500:
        toast.error('server error');
        break;
      default:
        toast.error('error');
        break;
    }
    return error;
  }
);

export default request;
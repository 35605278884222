import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DistrictService } from '../../../services/DistrictService';
import DistrictCreate from './DistrictCreate';
import DistrictDatatable from './DistrictDatatable';
import { checkUserPermission } from '../../checkPermission';


const District = () => {

    const districtService = new DistrictService();


    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [active, setActive] = React.useState(true);
    const [refresh, setRefresh] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [pending, setPending] = React.useState(true)

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)

    };

    async function getAllDistrict() {
        active && setPending(true)
        const data = await districtService.getDistrict(perpage, currentPage,filterText);
        active && setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    useEffect(() => {
        getAllDistrict()
    }, [refresh, perpage, currentPage,filterText])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        state_id: Yup.string()
            .required('Required')

    });

    const formik = useFormik({
        initialValues: {
            district_id: "",
            state_id: "",
            name: "",
            is_active: true
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.district_id) {
                formData.append("district_id", values.district_id ?? "");
            }
            formData.append("state_id", values.state_id);
            formData.append("name", values.name);

            districtService.addOrUpdateDistrict(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        color: response?.response?.data?.errors?.title,
                        text: response?.response?.data?.errors?.description,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        formik.setValues({
            district_id: item.id,
            state_id: item.state_id,
            name: item.name
        })
        console.log("Edit")
        console.log(item);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>District</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">District</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        { checkUserPermission("Create District") && <DistrictCreate image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <DistrictDatatable                            
                            data={data}
                            handleEdit={handleEdit}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            formik={formik}
                            setActive={setActive}
                            filterText={filterText}
                            setFilterText={setFilterText}
                            pending={pending}
                            currentPage={currentPage}
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default District
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { UniversityCourseService } from '../../../services/UniversityCourseService';
import { ScholarshipCategoryService } from '../../../services/ScholarshipCategoryService';
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { dataURLtoFile } from '../../functions';


const ScholarshipCreate = ({ formik, pxImage, setPxImage, isloader, formRef, formView, setFormView, universityData, setUniversityData, setImage }) => {
    console.log(formik.errors)
    const [universityTypes, setUniversityTypes] = useState([]);
    const [categoryType, setCategoryType] = useState([]);
    const [errors, setErrors] = useState("false");
    const [errorState, setErrorState] = useState();

    const universityCourseService = new UniversityCourseService();
    const scholarshipCategoryService = new ScholarshipCategoryService();

    async function getAllUniversityTypes() {
        const data = await universityCourseService.getAllUniversityCourses(1, 10, '');
        let defaultData = []
        data?.data?.results?.map((type) => {
            defaultData.push({ value: type?.id, label: type?.course?.name + ' | ' + type?.university?.university_name })
        })
        setUniversityTypes(defaultData)
        console.log(data?.data?.results);
    }

    async function getAllCategories() {
        const data = await scholarshipCategoryService.getCategories();
        setCategoryType(data.data.results)
    }

    useEffect(() => {
        getAllCategories()
        getAllUniversityTypes()
    }, [])

    function addPoints() {
        let points = formik.values?.points;
        points?.push({
            points: ''
        });
        formik.setValues({ ...formik.values, points: points })
    }

    function deletePoints(index) {
        let points = formik.values?.points;
        points?.splice(index, 1)
        formik.setValues({ ...formik.values, points: points })
    }

    function addRequirements() {
        let requirements = formik.values?.requirements;
        requirements?.push({
            requirements: ''
        });
        formik.setValues({ ...formik.values, requirements: requirements })
    }
    function deleteRequirements(index) {
        let requirements = formik.values?.requirements;
        requirements?.splice(index, 1);
        formik.setValues({ ...formik.values, requirements: requirements })
    }

    function handleMediaImage(e) {
        setImage(e.target.files[0])
        console.log("ajhsdashkdgashkf")
        console.log(e.target.files[0])
        // if (e.target.files[0]) {
        //     const img = new Image();
        //     img.src = URL.createObjectURL(e.target.files[0])
        //     img.onload = function () {
        //         let height = img.height;
        //         let width = img.width;
        //         console.log(width)
        //         if (height == "300" && width == "300") {
        //             setPxImage(false)
        //             console.log("if")
        //         } else if (height == "") {
        //             setPxImage(false)
        //             console.log("else if")
        //         } else {
        //             setPxImage(true)
        //             console.log("else ")
        //         }
        //     }
        // } else {
        //     setPxImage(false)
        // }
        formik.setValues({ ...formik.values, og_image: e.target.files[0], image_url: e.target.files[0] && URL.createObjectURL(e.target.files[0]) })
    }


    const animatedComponents = makeAnimated();


    // const filterClasses = async (inputValue) => {
    //     const data = await universityCourseService.getAllUniversityCoursesList({ search: inputValue });
    //     console.log(data?.data?.results);
    //     setUniversityTypes(data?.data?.results)
    //     // return data?.data?.results

    // };

    const classLoadOptions = async (inputValue) => {
        // setTimeout(() => {
        //     callback(filterClasses(inputValue));
        // }, 1000);
        const data = await universityCourseService.getAllUniversityCoursesList({ search: inputValue });
        return data?.data?.results
    };



    const onChangeFilerForm = ({ name, value, label }) => {
        formik.setValues({ ...formik.values, [name]: value });
        setUniversityData([{ value: value, label: label }]
        );
    };

    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    {formView === "title" &&
                        <>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        value={formik.values?.title}
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.title && !formik.errors.title}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.title && formik.errors.title}
                                        placeholder="Title"
                                        maxLength={100}
                                    />
                                    {formik.touched.title && formik.errors.title &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                                    }

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>Category</Form.Label>
                                    <Form.Select
                                        placeholder="Select an Option"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="category"
                                        value={formik.values?.category}
                                        isInvalid={formik.touched.category && formik.errors.category}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.category && !formik.errors.category}
                                        onChange={formik.handleChange}
                                    >
                                        <option value='' >Select an Option</option>
                                        {categoryType?.map(category =>
                                            <option value={category?.id}>{category?.title}</option>
                                        )}
                                    </Form.Select>
                                    {formik.touched.category && formik.errors.category &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.category}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>University Course</Form.Label>
                                    <AsyncSelect
                                        cacheOptions
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        loadOptions={classLoadOptions}
                                        defaultOptions={universityTypes}
                                        onChange={(option) => {
                                            onChangeFilerForm({ name: 'university_course', value: option?.value, label: option?.label });
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        debounceInterval={100}
                                        classNamePrefix="select"
                                        placeholder="Enter the university name"
                                        name="university_course"
                                        value={universityData}
                                    />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>University Course</Form.Label>
                                    <Form.Select
                                        placeholder="Select an Option"
                                        className="select-dropdown"
                                        classNamePrefix="select"
                                        name="university_course"
                                        value={formik.values?.university_course}
                                        isInvalid={formik.touched.university_course && formik.errors.university_course}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.university_course && !formik.errors.university_course}
                                        onChange={formik.handleChange}
                                    >
                                        <option value='' >Select an Option</option>
                                        {universityTypes?.map(universityType =>
                                            <option value={universityType?.id}>{universityType?.course?.name}/{universityType?.university?.university_name}</option>
                                        )}
                                    </Form.Select>
                                    {formik.touched.university_course && formik.errors.university_course &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.university_course}</Form.Control.Feedback>
                                    }
                                </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Duration</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="duration"
                                        value={formik.values?.duration}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.duration && !formik.errors.duration}
                                        isInvalid={formik.touched.duration && formik.errors.duration}
                                        placeholder="Duration"
                                        maxLength={100}
                                    />
                                    {formik.touched.duration && formik.errors.duration &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.duration}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom05">
                                    <Form.Label>About</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="about"
                                        value={formik.values?.about}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.about && !formik.errors.about}
                                        isInvalid={formik.touched.about && formik.errors.about}
                                        placeholder="About"
                                        maxLength={100}
                                    />
                                    {formik.touched.about && formik.errors.about &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.about}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom05">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="price"
                                        value={formik.values?.price}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.touched.price && !formik.errors.price}
                                        isInvalid={formik.touched.price && formik.errors.price}
                                        placeholder="Price"
                                        maxLength={100}
                                    />
                                    {formik.touched.price && formik.errors.price &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.price}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                    <Form.Label>Social Media Image</Form.Label>
                                    <div className="file_wrapper">
                                        <Form.Control
                                            type="file"
                                            name="og_image"
                                            accept="image/*"
                                            values={formik.values.og_image}
                                            onChange={(e) => { handleMediaImage(e) }
                                            }
                                            isValid={formik.touched.og_image && !formik.errors.og_image}
                                            isInvalid={!!formik.errors.og_image}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.errors.og_image}</Form.Control.Feedback>
                                        {formik.values?.image_url &&
                                            <div className='img-wrapper ms-2'>
                                                <img src={formik.values.image_url} alt='course' style={{ height: '100px', width: '150px' }} className=' mt-2' />
                                            </div>
                                        }
                                        <div className='img'>Only 300 X 300 px images are accepted</div>

                                    </div>
                                </Form.Group>
                            </Row>
                            <div className="group">
                                <Button type="button" onClick={() => {
                                    !formik.errors.about && !formik.errors.duration && !formik.errors.university_course && !formik.errors.category && !formik.errors.title && !formik.errors.og_image && setFormView('requirements')
                                }} className='save-btn me-4'>Next</Button>
                            </div>
                        </>}


                    {formView === 'requirements' &&
                        <>
                            <Row>
                                {formik.values.requirements.map((requirements, index) => {
                                    if (requirements === undefined) {
                                        setErrors(true)
                                        return;
                                    }
                                    const touched = formik.touched?.requirements && formik.touched?.requirements[index];
                                    return <>
                                        <Form.Group as={Col} md="6" controlId="validationCustom07">
                                            <Form.Label>{index == 0 && "Requirements"}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`requirements[${index}].requirements`}
                                                value={requirements?.requirements}
                                                onChange={formik.handleChange}
                                                isValid={touched && errors}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.points && formik.errors.requirements && formik.errors.requirements[index] && formik.errors.requirements[index]?.requirements && !!formik.errors.requirements[index]?.requirements}
                                                placeholder="Requirements"
                                                maxLength={100}
                                            />

                                            <Form.Control.Feedback type="invalid">{formik.errors.requirements && formik.errors.requirements[index] && formik.errors.requirements[index]?.requirements && formik.errors.requirements[index]?.requirements}</Form.Control.Feedback>
                                            <div className='d-flex'>
                                                {index == formik.values?.requirements?.length - 1 &&
                                                    <div>
                                                        <Button type="button" onClick={() => addRequirements()} className='save-btn me-4 ms-2 mb-4'>Add</Button>
                                                    </div>}
                                                {formik.values?.requirements?.length > 1 && <Button type="button" onClick={() => deleteRequirements(index)} className='save-btn me-4 ms-2 mb-4'>Delete</Button>}
                                            </div>
                                        </Form.Group>
                                    </>
                                })}
                            </Row>
                            <div className="group mt-3">
                                <Button type="button" className='save-btn me-4' onClick={() => setFormView('title')}>Back</Button>
                                <Button type="button" onClick={() => setFormView('points')} className='save-btn me-4'>Next</Button>
                            </div>
                        </>

                    }
                    {formView === 'points' &&
                        <>
                            <Row>
                                {formik.values.points.map((points, index) => {
                                    if (points === undefined) {
                                        setErrors(true)
                                        return;
                                    }
                                    const touched = formik.touched?.points && formik.touched?.points[index];
                                    return <>
                                        <Form.Group as={Col} md="6" controlId="validationCustom07">
                                            <Form.Label>{index == 0 && "How to apply"}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`points[${index}].points`}
                                                value={points?.points}
                                                onChange={formik.handleChange}
                                                isValid={touched && errors}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.points && formik.errors.points && formik.errors.points[index] && formik.errors.points[index]?.points && !!formik.errors.points[index]?.points}
                                                placeholder="how to apply"
                                                maxLength={100}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.points && formik.errors.points[index] && formik.errors.points[index]?.points && formik.errors.points[index]?.points}</Form.Control.Feedback>
                                            <div className='d-flex'>
                                                {index == formik.values?.points?.length - 1 &&
                                                    <div>
                                                        <Button type="button" onClick={() => addPoints()} className='save-btn me-4 ms-2 mb-4'>Add</Button>
                                                    </div>}
                                                {formik.values?.points?.length > 1 && <Button type="button" onClick={() => deletePoints(index)} className='save-btn me-4 ms-2 mb-4'>Delete</Button>}
                                            </div>
                                        </Form.Group>
                                    </>
                                })}
                            </Row>
                            <div className="group">
                                {isloader === true ?
                                    <div className='loader'>
                                        <img src={loader} alt="" />
                                    </div>
                                    :
                                    <>
                                        <Button type="button" className='save-btn me-4' onClick={() => setFormView('requirements')}>Back</Button>
                                        <Button type="submit" className='save-btn me-4'>Save</Button>
                                    </>
                                }
                            </div>
                        </>}
                </Form>
            </div>
        </React.Fragment>
    )
}

export default ScholarshipCreate;
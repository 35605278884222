export const dataURLtoFile = async (dataurl) => {
  dataurl = dataurl.replace('http://', 'https://');
  console.log(dataurl);
  let filename = dataurl.substring(
    dataurl.lastIndexOf("/") + 1,
    dataurl.length
  );

  let response = await fetch(dataurl);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  let fileobj = new File([data], filename, metadata);
  let file = [
    {
      originFileObj: fileobj,
      uid: "1",
      name: filename,
      url: dataurl,
    },
  ];
  console.log(file[0]);
  return file[0]?.originFileObj;
};

export const dataURLtoPDF = async (dataurl) => {
  dataurl = dataurl.replace('http://', 'https://');
  console.log(dataurl);
  let filename = dataurl.substring(
    dataurl.lastIndexOf("/") + 1,
    dataurl.length
  );

  let response = await fetch(dataurl);
  let data = await response.blob();
  let metadata = {
    type: "application/pdf",
  };
  let fileobj = new File([data], filename, metadata);
  let file = [
    {
      originFileObj: fileobj,
      uid: "1",
      name: filename,
      url: dataurl,
    },
  ];
  console.log(file[0]?.originFileObj);
  return file[0]?.originFileObj;
};
import request from "../api/agent";
const API_ROUTE = "appointment/book-a-appointment";

export class AppointmentService {


    async getAllAppointments(page,limit,search,typeValue) {
        const serchValue = search??""
        const application_status = typeValue?typeValue:"";
        const response = await request.get(`${API_ROUTE}/get-all-appointment?page=${page}&limit=${limit}&search=${serchValue}&application_status=${application_status}`);
        return response.data;
    }


    async deleteAppointment(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-appointment`, {
            data: { ids: [id] }
        });
        return response;
    }
    async contactStatus(id) {
        let action = `${API_ROUTE}/appointment-contact-or-not-status-change`;
        const response = await request.put(action,{id:id});
        return response;
}

    async setAppointmentStatus(id, status) {
        const response = await request.post(`${API_ROUTE}/appointment-status`, {
            id: id,
            status: status
        });
        return response;
    }

}
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { CountryService } from '../../../services/CountryService';


const countryService = new CountryService();

const CreateResentIntake = ({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError, groups, setGroups }) => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getAllCountry()
    }, [])

    async function getAllCountry() {
        const data = await countryService.getAllCountries();
        setCountries(data?.data?.results)
        console.log(data);
    }

    const handleChangeFile = (e, name, url) => {
        if((e.target.files[0])){
        formik.setValues({ ...formik.values, [name]: e.target.files[0], [url]: URL.createObjectURL(e.target.files[0]) })
        }
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.name && !formik.errors.name}
                                isInvalid={!!formik.errors.name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Country</Form.Label>
                            <Form.Select
                                placeholder="Country"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="country"
                                value={formik.values?.country}
                                isInvalid={!!formik.errors?.country}
                                onChange={formik.handleChange}
                            >
                                <option value='' >Select an Option</option>
                                {countries?.map(type =>
                                    <option value={type?.id}>{type?.country_name}</option>
                                )}


                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.country}</Form.Control.Feedback>
                        </Form.Group>

                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">

                            <Form.Label>Image</Form.Label>

                            <div className="file_wrapper">


                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"

                                    onChange={(e) => { handleChangeFile(e, 'image', 'image_url') }}
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={!!formik.errors.image}
                                />

                                {formik.values?.image_url &&
                                    <div className='img-wrapper ms-2'>
                                        <img src={formik.values.image_url} alt='course' style={{ height: '100px', width: '150px' }} className=' mt-2' />
                                    </div>
                                }

                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </Row>


                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateResentIntake;
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import BasicSweetCallback from '../../SweetAlertCallback';
import { Button } from 'react-bootstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import Modal from 'react-bootstrap/Modal';
import { StudentService } from '../../../services/studentServices';

const studentservice = new StudentService();

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

const StudentsDatatable = ({ currentPage,setActive, tableloader, pending, setFilterText, filterText, data, handleEdit, refresh, setRefresh, handlePerRowsChange, setCurrentPage, totalRows, formik }) => {
    const [showModal, setShowModal] = useState(false);
    const [options, setOptions] = useState([]);
    const [Agents, setAgent] = useState([]);
    const [NewAgent, setNewAgent] = useState([]);
    const [student, setStudent] = useState('');
    const [selectedAgent, setselectedAgent] = useState('');
    const [instanceId, setInstanceId] = useState('');


    async function UpdatesAgents() {
        let formData = new FormData()
        formData.append('instance_id', instanceId)
        formData.append('agent', selectedAgent)
        formData.append('student', student)
        const data = await studentservice.addOrUpdateAgent(formData);
        setNewAgent(data?.data?.results)
        console.log(data);
    }
    async function getAllAgents() {
        const data = await studentservice.getAgent();
        setAgent(data?.data?.results)
        console.log("data", data);
    }

    useEffect(() => {
        getAllAgents()
    }, []);
    const handleShowModal = (row) => {
        // setInstanceId(row.agent[0].id)
        setStudent(row.id)
        setShowModal(true);
    }
    const handleSubmit = () => {
        UpdatesAgents()
        setShowModal(false)
        setRefresh(true)
        setActive(false)
        setRefresh(!refresh)

    }
    const handleCloseModal = () => {
        setShowModal(false);
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit
        </Tooltip>
    );
    const columns = [
        {
            name: "SL.No",
            key: "index",
            cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
            grow: 0.2,
        },
        {
            name: 'Full Name',
            cell: (row) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.fullname}>
                    {row.fullname}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Email ',
            cell: (row) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.email}>
                    {row.email}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Phone',
            selector: row => <div className='text-truncate'>{row.phone}</div>,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Referral Id',
            selector: row => <div className='text-truncate'>{row.referral_id}</div>,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            name: 'Agent Name',
            cell: (row) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row?.agent[0]?.agent_name}>
                    {row?.agent[0]?.agent_name}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
        },
        {
            id: 'action',
            name: "Change Agent",
            grow: 2,
            cell: (row) =>
                <div className='d-flex align-items-center  '>
                    <div >
                        <div onClick={() => handleShowModal(row)}>
                            <span className="svg-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                        <Modal setRefresh={setRefresh} refresh={refresh} show={showModal} onHide={handleCloseModal}>
                            <div className="d-flex flex-row align-items-center justify-content-between" style={{ marginTop: "5px", marginLeft: "7px" }}>
                                <h3><center>Change Agent</center></h3>
                            </div>
                            {/* <div className="main-box " style={{ width: "300px" }}> */}
                            <Modal.Body>
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <label >Select an Agent:</label>
                                </div>
                                <select className="form-select" onChange={(e) => setselectedAgent(e.target.value)}>
                                    <option className="select-dropdown" value="">Select Agent</option>
                                    {Agents?.map(agen =>
                                        <option className="select-dropdown" value={agen?.id}>{agen?.email}</option>
                                    )}
                                </select>
                            </Modal.Body>
                            <div className="btns" style={{ marginTop: "5px", marginLeft: "7px", marginBottom: "9px" }}>
                                <div style={{ marginTop: "5px", marginLeft: "7px", marginRight: "10px", marginBottom: "15px" }}>
                                    <Button className="close-btn me-4" onClick={handleCloseModal}>Close</Button>
                                </div>
                                <div style={{ marginTop: "5px", marginLeft: "7px", marginRight: "10px", marginBottom: "15px" }}>
                                    <Button type="submit" className='save-btn me-4' onClick={handleSubmit}>Save</Button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>,
            sortable: true,
            center: true,
        },


    ];

    return (
        <React.Fragment>
            <div className='mt-5'>
                <Card style={{ height: '100%' }}>
                    <div className="input-group">
                        <div className="form-outline">
                            <input
                                type="search"
                                id="form1"
                                onChange={(e) => setFilterText(e.target.value)}
                                className="form-control"
                                placeholder="search"
                            />
                        </div>
                    </div>
                    {tableloader === true ?
                        <div className='tableloader'>
                            <img src={loader} alt="" />
                        </div>
                        :
                        <DataTable
                            title="Students"
                            columns={columns}
                            data={data}
                            defaultSortFieldId={1}
                            highlightOnHover
                            defaultSortField="name"
                            sortIcon={sortIcon}
                            selectableRowsComponent={Checkbox}
                            selectableRowsComponentProps={selectProps}
                            onChangePage={(page) => setCurrentPage(page)}
                            onChangeRowsPerPage={handlePerRowsChange}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            progressPending={pending}
                        />}
                </Card>
            </div>
        </React.Fragment>
    )
}

export default StudentsDatatable;

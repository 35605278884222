import request from "../api/agent";
const API_ROUTE = "university/course-enquiry";

export class CourseEnqueryService {

    async getAllCourseEnquery(limit, page,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-course-enquiry-admin?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }

    async deleteCourseEnquery(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-course-enquiry-admin`, {
            data: { ids: [id] }
        });
        return response;
    }

    async setIsContacted(id){
        const response = await request.post(`${API_ROUTE}/student-contact`,{instance_id:id})
        return response;
    }
    



}
import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'

import { StudentService } from '../../../services/studentServices';
import StudentsDatatable from './StudentDatatable';

const Students = () => {
    const studentservice = new StudentService();

    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)
    const [active, setActive] = React.useState(true)


    const handlePerRowsChange = async (newPerPage) => {
      setPerpage(newPerPage)
    };
  
    async function getStudents() {
      active && setPending(true)
      const data = await studentservice.getStudentService(perpage, currentPage,filterText);
      active && setPending(false)
      setData(data.data.results)

    console.log("dattaaa",data.data);
      setTotalRows(data.data.count)
    }
  
    useEffect(() => {
        getStudents()
    }, [refresh, currentPage, perpage,filterText])

console.log(data)
  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Students</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Students</li>
              </ol>
            </nav>
          </div>
          <React.Fragment>
            <StudentsDatatable setActive={setActive} currentPage={currentPage} filterText={filterText} setFilterText={setFilterText} pending={pending} data={data}  refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} />
          </React.Fragment> 
        </main>
      </>
    </React.Fragment>
  )
}

export default Students;
import React from 'react'
import Content from '../components/main/content';
import Footer from '../components/main/footer';
import Header from '../components/main/header';
import Sidebar from '../components/main/sidebar';

const Index = () => {

    return (
        <div>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Header homePage={true} />
                    <Sidebar />
                    <Content />
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Index;
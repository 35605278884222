import request from "../api/agent";
const API_ROUTE = "feeds/banner";

export class BannerFeedService {
    async addOrUpdateBannerFeed(course) {
        let action = `${API_ROUTE}/banner-upload`;
        const response = await request.post(action, course);
        return response;
    }

    async getAllBannerFeed(perpage, currentPage) {
        if(perpage){
        const response = await request.get(`${API_ROUTE}/banner-listing?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }else{
        const response = await request.get(`${API_ROUTE}/banner-listing`);
        return response.data;

    }
    }

    async deleteBannerFeed(id) {
        const response = await request.delete(`${API_ROUTE}/banner-delete`, {
            data: { banner: id }
        });
        return response;
    }
    async getStatus(id) {
        const response = await request.post(`${API_ROUTE}/banner-status`, { banner: id })
        return response;
    }
}

import React, { Suspense, useEffect } from 'react'
import { Route, Routes as Switch, useNavigate } from "react-router-dom";

import Home from "../../../Layout/index";
import Countries from '../countries/countries';
import Courses from '../courses/courses';
import CourseTypes from '../coursetype/courseTypes';
import UniversityTypes from '../universityType/unversityType';
import Notifications from '../Notifications/Notifications';
// import Service from '../Service/Service';
import University from '../university/university';
import Stream from '../stream/stream';
import Specialization from '../specialization/specialization';
import Feeds from '../Feeds/Feeds';
import TestimonialVideo from '../testimonialVideo/testimonialVideo';
import TestimonialGallery from '../testimonialGallery/TestimonialGallery';
import Testimonial from '../testimonial/Testimonial';
import ScholarshipCategory from '../scholarship-category/ScholarshipCategory';
import Scholarship from '../scholarship/Scholarship';
import Faq from '../Faq/Faq';
import UniversityCourse from '../universityCourse/universityCourse';
import AlumniProfile from '../alumniProfile/alumniProfile';
import Appointment from '../appointment/appointment';
import Roles from '../roles/roles';
import Placement from '../Placement/Placement';
import Aboard from '../AbroadAdmin/Aboard';
import State from '../State/State';
import Groups from '../group/groups';
import Users from '../users/users';
import District from '../district/District';
import ResentIntake from '../resentIntake/resentIntake';
import ApplicationForm from '../applicationForm/applicationForm';
// import CourseEnquery from '../courseEnquery/courseEnquery';
import Enroll from '../Enroll/Enroll';
import Entrance from '../entrance/Entrance';
import Enquiry from '../enquiry/Enquiry';
import ServiceEnquiry from '../EnquiryService/ServiceEnquiry';
import Reset from '../../changepassword/changepassword';
import UniversityEnquiry from '../universityenquery/universityenquery';
import UniversityCourseEnquiry from '../universitycourseenquiry/universitycourseenquiry';
import Students from '../Student/Student';
import CourseApplication from '../courseApplication/CourseApplication';
import Reset_Password from '../../forgot/resetpassword';
import BannerFeed from '../feedBanner/feedbanner';
import ScholarshipEnquiry from '../scholarshipEnquiry/scholarshipEnquiry';
import PREnquiry from '../PREnquiry/prEnquiry';

const AdminPanel = () => {

    const navigate = useNavigate();
    useEffect(() => {
        const logged = localStorage.getItem('is_logged');
        if (!logged) {
            navigate('/login')
        }
    }, [])

    return (
        <Suspense
            fallback={
                <div style={{ height: '100vh' }}>
                    Loading
                </div>
            }
        >
            <Switch>
                <Route index exact path='/' element={<Home />} />
                <Route index exact path='/re' element={<Reset_Password />} />
                <Route exact path='/countries' element={<Countries />} />
                <Route exact path='/courses' element={<Courses />} />
                <Route exact path='/course-type' element={<CourseTypes />} />
                <Route exact path='/university-type' element={<UniversityTypes />} />
                <Route exact path='/notifications' element={<Notifications />} />
                <Route exact path='/university' element={<University />} />
                <Route exact path='/stream' element={<Stream />} />
                <Route exact path='/specialization' element={<Specialization />} />
                <Route exact path='/feed' element={<Feeds />} />
                <Route index exact path='/banner' element={<BannerFeed />} />
                <Route exact path='/testimonial-video' element={<TestimonialVideo />} />
                <Route exact path='/testimonial-interaction' element={<TestimonialGallery />} />
                <Route exact path='/testimonial' element={<Testimonial />} />
                <Route exact path='/scholarship-category' element={<ScholarshipCategory />} />
                <Route exact path='/scholarship' element={<Scholarship />} />
                <Route exact path='/faq' element={<Faq />} />
                <Route exact path='/university-course' element={<UniversityCourse />} />
                <Route exact path='/alumni-profile' element={<AlumniProfile />} />
                <Route exact path='/appointments' element={<Appointment />} />
                <Route exact path='/enquiry' element={<Enquiry />} />
                <Route exact path='/roles' element={<Roles />} />
                <Route exact path='/placement' element={<Placement />} />
                <Route exact path='/aboard' element={<Aboard />} />
                <Route exact path='/state' element={<State />} />
                <Route exact path='/group' element={<Groups />} />
                <Route exact path='/users' element={<Users />} />
                <Route exact path='/students' element={<Students />} />
                <Route exact path='/district' element={<District />} />
                <Route exact path='/resent-intake' element={<ResentIntake />} />
                <Route exact path='/application-form' element={<ApplicationForm />} />
                <Route exact path='/course-application' element={<CourseApplication />} />
                <Route exact path='/enroll' element={<Enroll />} />
                <Route exact path='/entrance' element={<Entrance />} />
                <Route exact path='/enquiryservice' element={<ServiceEnquiry />} />
                <Route exact path='/scholarship-enquiry' element={<ScholarshipEnquiry />} />
                <Route exact path='/pr-enquiry' element={<PREnquiry />} />
                <Route exact path='/resetpassword' element={<Reset />} />
                <Route exact path='/university-enquiry' element={<UniversityEnquiry />} />
                <Route exact path='/universitycourseenquiry' element={<UniversityCourseEnquiry />} />
            </Switch>
        </Suspense>
    )
}

export default AdminPanel
import request from "../api/agent";

const API_ROUTE = "scholarship/scholarship";

export class ScholarshipCategoryService {

    async getCategory(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-category?limit=${perpage}&page=${currentPage}&search=${serchValue }`);
        return response.data;
    }

    async getCategories() {
        const response = await request.get(`${API_ROUTE}/get-all-category`);
        return response.data;
    }


    async addOrUpdateCategory(category) {
        let action = `${API_ROUTE}/create-or-update-category`;
        const response = await request.post(action, category);
        return response;
    }

    async deleteCategory(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-category`, {data: { ids: [id] }});
        return response;
    }

    async setCategoryStatus(id){
        const response = await request.put(`${API_ROUTE}/category-status-change`,{id:id});
        return response;
    }
}
import request from "../api/agent";
const API_ROUTE = "auth";

export class AuthService {
    async userLogin(data) {
        let action = `${API_ROUTE}/admin-login`;
        const response = await request.post(action, data);
        return response;
    }
    async userLogout(data) {
        let action = `${API_ROUTE}/logout/`;
        const response = await request.post(action, data);
        return response;
    }
    async userForgotPassword(data) {
        let action = `${API_ROUTE}/password/forget-password`;
        const response = await request.post(action, data);
        return response;
    }
    async changePassword(data) {
        let action = `${API_ROUTE}/password/change-password`;
        const response = await request.post(action, data);
        return response;
    }
    async reset(data,uid,token) {
        let action = `${API_ROUTE}/password/reset`;
        const response = await request.post(action, data);
        return response;
    }
    


    
}
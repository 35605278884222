import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import BasicSweetCallback from "../../SweetAlertCallback";
import { CourseEnqueryService } from "../../../services/CourseEnqueryService";
import Modal from "react-modal";
import { ApplicationFormService } from "../../../services/ApplicationFormService";
import loader from '../../../assets/images/loader.gif';
import zIndex from "@material-ui/core/styles/zIndex";
import { light } from "@material-ui/core/styles/createPalette";

const courseEnqueryService = new CourseEnqueryService();
const applicationFormService = new ApplicationFormService();
const sortIcon = <ArrowDownward />;

const CourseApplicationDatatable = ({
  tableloader,
  setFilterText,
  filterText,
  data,
  refresh,
  setRefresh,
  currentPage,
  setCurrentPage,
  setPerpage,
  setActive,
  totalRows,
  pending
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(undefined)

  function handleVerification(id) {
    applicationFormService.formVerification(id).then((response) => {
      if (response.status) {
        setActive(false)
        setRefresh(prev => !prev)
      }

    })
  }

  function handleCompletion(id) {
    applicationFormService.formCompletion(id).then((response) => {
      if (response.status)
        setRefresh(prev => !prev)
    })
  }
  const columns = [
    {
      name: "SL.No",
      key: "index",
      cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
      grow: 0.2,
    },
    {
      name: "Name",
      selector: (row) => row?.fullname,
      sortable: true,
      grow: 0.5,
      reorder: true,
      // center: true
    },
    {
      name: "Email",
      // selector: (row) => row?.email,
      cell: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row?.email}>
          {row?.email}
        </div>,
      sortable: true,
      grow: 0.5,
      reorder: true,
      center: true,
    },
    {
      name: "Course",
      // selector: (row) => row?.course,
      cell: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row?.course}>
          {row?.course}
        </div>,
      sortable: true,
      grow: 0.8,
      reorder: true,
      center: true
    },
    {
      name: "Status",
      cell: (row) => (
        <div className='d-flex align-items-center'><div>{row.status_name}</div></div>
      ),
      sortable: true,
      grow: 0.6,
      reorder: true,
    },
    {
      name: "Documents",
      cell: (row) => {
        if (selected?.id === row.id) {
          setSelected(row)
        }
        return <div className='d-flex align-items-center'>
          <div onClick={() => openModal(row)}>
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
              </svg>
            </span>
          </div>
        </div>
      },
      sortable: true,
      grow: 0.2,
      reorder: true,
      center: true
    },
    {
      name: 'Contacted',
      selector: row => <div class="form-check form-switch"  >
        <input onChange={() => {
          courseEnqueryService.setIsContacted(row.id).then((response) => {
            setActive(false)
            setRefresh(!refresh)
          })
        }} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_contacted} />
        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
      </div>
      ,
      grow: 0.4,
      sortable: true,
      reorder: true,
      center: true
    },
    {
      id: "action",
      name: "Actions",
      cell: (row) => {
        return <div className="d-flex align-items-center button_status">
          {row.status_name === "Process Completed" ? <button disabled={true} className="btn btn-success" role="button" >Completed</button>
            :
            <button onClick={() => { handleCompletion(row.id) }} className="btn btn-light" role="button" >Complete</button>}
          <BasicSweetCallback
            onDelete={courseEnqueryService.deleteCourseEnquery}
            deleteId={row?.id}
            title="Course"
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </div>
      },
      sortable: true,
      grow: 1,
      center: true
    },
  ];

  const openModal = (row) => {
    setIsOpen(!modalIsOpen);
    setSelected(row)
  }

  console.log(selected)
  return (
    <React.Fragment>
      <div className="mt-5">
        <Card style={{ height: "100%" }}>
          <div className="input-group">
            <div className="form-outline">
              <input
                type="search"
                id="form1"
                onChange={(e) => setFilterText(e.target.value)}
                className="form-control"
                placeholder="search"
              />
            </div>
          </div>

          <>

            <DataTable
              title="Course Application"
              columns={columns}
              data={data?.results}
              defaultSortFieldId={1}
              highlightOnHover
              defaultSortField="name"
              sortIcon={sortIcon}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={(newPerPage) => setPerpage(newPerPage)}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              progressPending={pending}
            />
          </>
        </Card>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="document_data">
            <h3>Documents</h3>
          </div>
          <div onClick={() => setIsOpen(false)}>
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="50" fill="#fffff" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </div>
        {selected !== undefined  ?
          <div className="main-box " style={{ width: "300px" }}>
            <div className="first-box">
              {selected.student_document.length >= 1 && selected.student_document.map(document => (
                <div className='sub-box' >
                  <div className="heading_data">
                    {document.document_name} :
                  </div>
                  <div className='name-box'>
                    {<button className="button-13" role="button" onClick={() => window.open(document.documents, '_blank')}>View File</button>}
                  </div>
                </div>
              ))}
              <div className='sub-box' >
                <div className="heading_data">
                  Name 
                </div>
                <div >
                  {selected?.fullname}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data">
                  Email 
                </div>
                <div >
                  {selected?.email}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data">
                  Mobile 
                </div>
                <div >
                  {selected?.phone}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data">
                  Gender 
                </div>
                <div >
                  {selected?.gender}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data">
                  DOB 
                </div>
                <div >
                  {selected?.dob}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data">
                  Martial Status 
                </div>
                <div >
                  {selected?.martial_status}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data">
                  Country 
                </div>
                <div >
                  {selected?.country}
                </div>
              </div>
              {/* <div className='sub-box' >
                <div className="heading_data">
                  Work Experience 
                </div>
                <div >
                  {selected?.work_experience}
                </div>
              </div> */}
              {/* <div className='sub-box' >
                <div className="heading_data">
                  Visa Rejection 
                </div>
                <div >
                  {selected?.visa_rejected_provisory}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data">
                  Reason 
                </div>
                <div >
                  {selected?.reason}
                </div>
              </div> */}
            </div>
            <div className="first-box width-box">
              <div className='sub-box font' >
                <div className="heading_data1 ">
                  Degree Qualification 
                </div>
                <div >
                  {selected?.qualification}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data1">
                  Percentage Marks 
                </div>
                <div >
                  {selected?.percentage_of_mark}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data1">
                  Status 
                </div>
                <div >
                  {selected?.status}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data1">
                  Enroll Plan 
                </div>
                <div >
                  {selected?.planning_to_enroll}
                </div>
              </div>
              <div className='sub-box' >
                <div className="heading_data1">
                  Enroll Term 
                </div>
                <div >
                  {selected?.year}
                </div>
              </div>

              <div className='sub-box' >
                <div className="heading_data1">
                  Entrance Mark :
                </div>
                <div >
                  {selected.entrace_mark.length ? selected.entrace_mark.map(item => {
                   return <ul>
                      <li>{item.entrance} {item.marks}</li>
                    </ul>
                  }) : <div>Nill</div>}
                </div>
              </div>
            </div>
          </div> : <div className="no-doc-box">
            No Documents
          </div>
        }
        {selected !== undefined && selected.student_document.length !== 0 && <div>
          {selected.status_name.toLocaleLowerCase() === "Documents Submitted".toLocaleLowerCase() || selected.status_name.toLocaleLowerCase() === "Application Submitted".toLocaleLowerCase() ? <button onClick={() => { handleVerification(selected.id) }} className="btn btn_verified" role="button" >Verifiy</button>
            :
            <button disabled={true} className="btn btn_verified" role="button" >Verified</button>}
        </div>}
      </Modal>
    </React.Fragment>
  );
};



const customStyles = {
  content: {
    top: '54%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    zIndex: "1000"
  },
}

export default CourseApplicationDatatable;

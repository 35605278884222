import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';


import DatatableService from './DatatableService';
import { ServiceEnquiryService } from '../../../services/sevrviceEnquiryService';

const serviceenquiryservice = new ServiceEnquiryService();

const ServiceEnquiry = () => {
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)

    const [typeValue, setTypeValue] = useState();
    const [contactValue, setContactValue] = useState();


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    async function getServiceEnquery() {
        setPending(true)
        const data = await serviceenquiryservice.getAllServiceEnquiry(currentPage, perpage, filterText, typeValue, contactValue);
        setPending(false)
        setData(data?.data)
        setTotalRows(data.data?.count)
    }

    useEffect(() => {

        getServiceEnquery()
    }, [refresh, currentPage, perpage, filterText, typeValue, contactValue])


    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Service Enquiry</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Service Enquiry</li>
                            </ol>
                        </nav>
                    </div>

                    <React.Fragment>
                        <DatatableService pending={pending} currentPage={currentPage} setTypeValue={setTypeValue} setContactValue={setContactValue} typeValue={typeValue} filterText={filterText} setFilterText={setFilterText} data={data} setData={setData} setTotalRows={setTotalRows} refresh={refresh} setRefresh={setRefresh} setCurrentPage={setCurrentPage} setPerpage={setPerpage} totalRows={totalRows} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default ServiceEnquiry;
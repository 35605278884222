// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CreateSpecialization from './createSpecialization';
import SpecializationDatatable from './specializationDatatable';
import { SpecializationService } from '../../../services/SpecializationService';
import { checkUserPermission } from '../../checkPermission';

const specializationService = new SpecializationService();

const Specialization = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [pending, setPending] = React.useState(true)
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState(0);

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])
    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };

    async function getAllStreams() {
        setPending(true)
        const data = await specializationService.getAllSpecialization(filterText,perpage,currentPage);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getAllStreams()
    }, [refresh,filterText,perpage,currentPage])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        stream: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            stream: "",
        },
        validationSchema,

        onSubmit: (values) => {
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("specialization", values.id ?? "");
            }
            formData.append("name", values.name);
            formData.append("stream", values.stream);

            specializationService.addOrUpdateSpecialization(formData).then((response) => {

                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_name: response?.response?.data?.errors?.title,
                        course_type: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        formik.setValues({
            id: item.id,
            name: item.name,
            stream: item.stream_id
        })
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Specialization</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Specialization</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create Specialization") && <CreateSpecialization image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <SpecializationDatatable setCurrentPage={setCurrentPage} setPerpage={setPerpage} setSelectedRows={setSelectedRows} handleRowClicked={handleRowClicked} handlePerRowsChange={handlePerRowsChange} totalRows={totalRows} handleChange={handleChange} currentPage={currentPage} filterText={filterText} setFilterText={setFilterText}  pending={pending}  data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik}  />
                    </React.Fragment>

                </main>
            </>


            
        </React.Fragment>
    )
}

export default Specialization;
import request from "../api/agent";

const API_ROUTE ="/testimonial/testimonial";

export class TestimonialVideoService {

    async getVideo(perpage,currentPage) {
        const response = await request.get(`${API_ROUTE}/video-listing?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }


    async addOrUpdateVideo(video) {
        let action = `${API_ROUTE}/video-creation`;
        const response = await request.post(action, video);
        return response;
    }

    async deleteVideo(id) {
        const response = await request.delete(`${API_ROUTE}/video-delete`, {data: { video_id: [id] }});
        return response;
    }

    async setVideoStatus(id){
        const response = await request.post(`${API_ROUTE}/video-status`,{video_id:id})
        return response;
    }
}
// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import { UniversityService } from '../../../services/universityService';
import ApplicationFormDatatable from './applicationFormDatatable';
import { ApplicationFormService } from '../../../services/ApplicationFormService';

const applicationFormService = new ApplicationFormService();

const ApplicationForm = () => {
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [active, setActive] = React.useState(true);
    const[pending,setPending]=React.useState(true);
    async function getAllApplicationForm() {
        setPending(true)
        const data = await applicationFormService.getAllApplicationForm(currentPage, perpage,filterText);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data?.count)
    }
    useEffect(() => {
        getAllApplicationForm()
    }, [refresh,currentPage, perpage,filterText])

  

    

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Application Form</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Application Form</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        <ApplicationFormDatatable pending={pending} setActive={setActive} filterText={filterText} setFilterText={setFilterText} data={data} refresh={refresh} setRefresh={setRefresh} setCurrentPage={setCurrentPage} setPerpage={setPerpage} totalRows={totalRows} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default ApplicationForm;
// ** Third Party Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from 'react-router-dom'
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";

const MySwal = withReactContent(Swal)

const BasicSweetCallback2 = ({ onDelete, deleteId, title, setRefresh, formik = null , handleClearRows, setRowsId }) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Delete
        </Tooltip>
    );
    const handleConfirmCancel = () => {
        return MySwal.fire({
            title: `Are You Sure You Want To Delete ${title}?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ms-1'
            },
            buttonsStyling: false
        }).then(function (result) {
            if (result.value) {
                onDelete(deleteId).then((response) => {
                    console.log(response.status);
                    if (response.status) {
                        handleClearRows()
                        setRowsId([])
                        setRefresh((prevRef) => !prevRef);
                        formik && formik.resetForm()
                        MySwal.fire({
                            icon: 'success',
                            title: 'Deleted',
                            text: response.message,
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        .catch((response) => {
                                console.log(`Delete operation failed: ${response.message}`);
                            });
                    }else{
                        MySwal.fire({
                            title: 'Delete operation failed',
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                    }
                })
            } else if (result.dismiss === MySwal.DismissReason.cancel) {
                MySwal.fire({
                    title: 'Cancelled',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            }
        })
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            backgroundColor: "#F8F6F4"

        }}
        >
            <div className='fw-bold'>Delete Selected Data</div>
            <Button style={{ marginTop: 0 }} type="submit" className="save-btn me-4" onClick={handleConfirmCancel} >
                Delete
            </Button>
        </div>
    )
}

export default BasicSweetCallback2

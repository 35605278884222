import request from "../api/agent";
const API_ROUTE = "user/intake";

export class ResentIntakeService {
    async addOrUpdateResentIntake(data) {
        let action = `${API_ROUTE}/create-or-update-intake`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllResentIntake(search,perpage,currentPage) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/get-all-intake?search=${serchValue}&limit=${currentPage}&page=${perpage}`);
        return response.data;
    }
    async updateResentIntakeStatus(id) {
        const response = await request.put(`${API_ROUTE}/intake-status-change`, {
            id
        });
        return response;
    }

    async deleteResentIntake(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-intake`, {
            data: { ids: [id] }
        });
        return response;
    }
}
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';

const FaqCreate = ({ formik, isloader, formRef }) => {

    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Question</Form.Label>
                            <Form.Control
                                type="text"
                                name="question"
                                value={formik.values?.question}
                                onChange={formik.handleChange}
                                isValid={formik.touched.question && !formik.errors.question}
                                isInvalid={!!formik.errors.question}
                                placeholder="Question"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.question}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Answer</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="answer"
                                value={formik.values?.answer}
                                onChange={formik.handleChange}
                                isValid={formik.touched.answer && !formik.errors.answer}
                                isInvalid={!!formik.errors.answer}
                                placeholder="Answer"
                                style={{ height: '100px' }}
                                maxLength={255}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.answer}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default FaqCreate;
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import BasicSweetCallback from "../../SweetAlertCallback";
import { UniversitycourseEnquiryService } from "../../../services/universitycourseenquiryService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import loader from "../../../assets/images/loader.gif";
import { checkUserPermission } from "../../checkPermission";
import { BsFilter } from "react-icons/bs";
import moment from "moment";

const universityenquirycourseService = new UniversitycourseEnquiryService();

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };

const UniversityCourseEnquiryDatatable = ({
  tableloader,
  currentPage,
  setContactValue,
  data,
  handleEdit,
  refresh,
  setRefresh,
  handlePerRowsChange,
  setCurrentPage,
  totalRows,
  formik,
  setFilterText,
  filterText,
  pending

}) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );
  const onChangeStatus = (id) => {

    universityenquirycourseService.enquiryStatus({ contact_id: id }).then((response) => {
      setRefresh(!refresh)
    })
  }

  const columns = [
    {
      name: "SL.No",
      key: "index",
      grow: 0.2,
      cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10
    },
    {
      name: " Name",
      cell: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.user_details.fullname}>
          {row.user_details.fullname}
        </div>,
      // selector: (row) => (
      //   <div className="text-truncate">{row.user_details.fullname}</div>
      // ),
      sortable: true,
      grow: 2,
      reorder: true,
    },
    {
      name: "Email",
      cell: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.user_details.email}>
          {row.user_details.email}
        </div>,
      // selector: (row) => (
      //   <div className="text-truncate">{row.user_details.email}</div>
      // ),
      sortable: true,
      grow: 3,
      reorder: true,
    },
    {
      name: "Phone",
      selector: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.user_details.phone}>
          {row.user_details.phone}
        </div>,
      sortable: true,
      grow: 2,
      reorder: true,
    },
    {
      name: 'Date / Time',
      selector: row => moment(row.created_date).format("YYYY-DD-MM h:mm:ss a"),
      sortable: true,
      center: false,
      grow:3
    },
    {
      name: "University",
      cell: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.course_university_name}>
          {row.course_university_name}
        </div>,
      // selector: (row) => <div className="text-truncate">{row.course_university_name}</div>,
      sortable: true,
      grow: 2,
      reorder: true,
    },

    {
      name: "Course",
      cell: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.course_name}>
          {row.course_name}
        </div>,
      // selector: (row) => <div className="text-truncate">{row.course_name}</div>,
      sortable: true,
      grow: 2,
      reorder: true,
    },

    {
      name: "Contacted",
      selector: (row) => <div class="form-check form-switch"  >
        <input onClick={() => onChangeStatus(row?.id)} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_contacted} />
        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
      </div>
      ,
      sortable: true,
      reorder: true,
      grow: 2,
    },
    (checkUserPermission("Create University Course Enquiry")) && {
      id: "action",
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <BasicSweetCallback onDelete={universityenquirycourseService.deleteEnquiry} deleteId={row.id} title="FAQ" setRefresh={setRefresh} refresh={refresh} formik={formik} />
        </div>
      ),
      sortable: true,
      grow: 2,
    },
  ];

  return (
    <React.Fragment>
      <div className="mt-5">
        <Card style={{ height: "100%" }}>

          <div className="head-filter">
            <div className="filter">
              <div className="fltr">
                <select onChange={(e) => setContactValue(e.target.value)}>
                  <option value='' >Contact status</option>
                  <option value='true' >Contacted</option>
                  <option value='false' >Not Contacted</option>
                </select>
                <BsFilter className='filter_icon' />
              </div>
            </div>
            <div className="">
              <div className="form-outline">
                <input
                  type="search"
                  id="form1"
                  onChange={(e) => setFilterText(e.target.value)}
                  className="form-control"
                  placeholder="search"
                />
              </div>
            </div>
          </div>

          {tableloader === true ? (
            <div className="tableloader">
              <img src={loader} alt="" />
            </div>
          ) : (
            <DataTable
              title="Course Enquiry"
              columns={columns}
              data={data}
              defaultSortFieldId={1}
              highlightOnHover
              defaultSortField="name"
              sortIcon={sortIcon}
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={selectProps}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={handlePerRowsChange}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              progressPending={pending}
            />
          )}
        </Card>
      </div>
    </React.Fragment>
  );
};

export default UniversityCourseEnquiryDatatable;

import request from "../api/agent";
const API_ROUTE = "profile/admin-user";

export class UserService {
    async addOrUpdateUser(data) {
        let action = `${API_ROUTE}/create-or-update-admin-profile`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllUsers() {
        const response = await request.get(`${API_ROUTE}/get-all-admin-profile`);
        return response.data;
    }
    async getUsers(page,limit) {
        const response = await request.get(`${API_ROUTE}/get-all-admin-profile?page=${page}&limit=${limit}`);
        return response.data;
    }

    async updateUserStatus(id) {
        const response = await request.put(`${API_ROUTE}/active-or-inactive-admin-profile`, {
            id
        });
        return response;
    }



    async deleteUser(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-admin-profile`, {
            data: { instance_id: id }
        });
        return response;
    }
    
}
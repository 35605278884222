import request from "../api/agent";
const API_ROUTE = "user/groups";

export class GroupService {
    async addOrUpdateGroup(data) {
        let action = `${API_ROUTE}/create-or-update-group`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllGroup(perpage, currentPage) {
        const response = await request.get(`${API_ROUTE}/get-groups?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }

    async getGroup() {
        const response = await request.get(`${API_ROUTE}/get-groups`);
        return response.data;
    }

    async getGroupDetails(id) {
        const response = await request.get(`${API_ROUTE}/get-group-details?group=${id}`);
        return response.data;
    }


    async deleteGroup(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-group`, {
            data: { group: id }
        });
        return response;
    }
}
import request from "../api/agent";
const API_ROUTE = "user/roles";

export class RolesService {
    async addOrUpdateRole(data) {
        let action = `${API_ROUTE}/create-or-update-role`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllRoles(perpage, currentPage) {
        const response = await request.get(`${API_ROUTE}/get-roles?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }

    async getRoles() {
        const response = await request.get(`${API_ROUTE}/get-roles`);
        return response.data;
    }


    async deleteRole(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-role`, {
            data: { role: id }
        });
        return response;
    }
}
import request from "../api/agent";

const API_ROUTE = "testimonial/testimonial";

export class TestimonialService {

    async getTestimonial(perpage,currentPage) {
        const response = await request.get(`${API_ROUTE}/testimonial-listing?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }


    async addOrUpdateTestimonial(testimonial) {
        let action = `${API_ROUTE}/testimonial-creation`;
        const response = await request.post(action, testimonial);
        return response;
    }

    async deleteTestimonial(id) {
        const response = await request.delete(`${API_ROUTE}/testimonial-delete`, {data: { testimonial: [id] }});
        return response;
    }

    async getStatus(id){
        const response = await request.post(`${API_ROUTE}/testimonial-status`,{testimonial:id})
        return response;
    }
}
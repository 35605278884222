// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import CreateUniversityCourse from './createUniversityCourse';
import UniversityCourseDatatable from './universityCourseDatatable';
import { UniversityCourseService } from '../../../services/UniversityCourseService';
import moment from 'moment';
import { checkUserPermission } from '../../checkPermission';
import { CsvService } from '../../../services/csvService';

const intakes = [
    {
        "value": "12",
        "label": "Jan"

    },
    {
        "value": "11",
        "label": "Feb"
    },
    {
        "value": "10",
        "label": "Mar",
    },
    {
        "label": "Apr",
        "value": "9"
    },
    {
        "value": "8",
        "label": "May"
    },
    {
        "value": "7",
        "label": "Jun"
    },
    {
        "value": "6",
        "label": "Jul"
    },
    {
        "value": "5",
        "label": "Aug"
    },
    {
        "value": "4",
        "label": "Sep"
    },
    {
        "value": "3",
        "label": "Oct"
    },
    {
        "value": "2",
        "label": "Nov"
    },
    {
        "value": "1",
        "label": "Dec"
    }
]

const universityCourseService = new UniversityCourseService();
const csvService = new CsvService();

const UniversityCourse = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [scroll, setScroll] = useState(false);
    const [active, setActive] = useState(true);
    const [bulkLoader, setBulkloader] = useState(false);
    const [intakeData, setIntakeData] = useState(false)
    const [intakeValue] = useState(intakes)
    const [formView, setFormView] = useState('details')
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [totalRows, setTotalRows] = React.useState(0);
    const [pending, setPending] = React.useState(true)
    const [bulkSucess, setBulkSucess] = React.useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])

    async function getAllCourses() {
        active && setPending(true)
        const data = await universityCourseService.getAllUniversityCourses(currentPage,perpage,filterText);
        active && setPending(false)
        setData(data.data)
        setTotalRows(data?.data?.count)
    }
    useEffect(() => {
        getAllCourses()
    }, [refresh, filterText, currentPage, perpage])

    const bulkValidation = Yup.object().shape({
        // country: Yup.number().required('Required'),
        // file: Yup.array().min(1).required('Required')
       
    });
    const bulkformik = useFormik({
        initialValues: {
        country: "",
        file: "",
        file_url: "",
        },
        bulkValidation,
        onSubmit: (values) => {
            setBulkloader(true)
            let formData = new FormData();
            formData.append("country", values?.country);
            formData.append("file", values?.file);
            csvService.addCsv(formData).then((response) => {
                if (response && response.status) {
                    setBulkSucess(true)
                    setBulkloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                   
                }
                else {
                    bulkformik.setErrors({
                        country: response?.response?.data?.errors?.country,
                        file: response?.response?.data?.errors?.file,
                        file_url: response?.response?.data?.errors?.file_url,
                    });
                    setBulkloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });

        }
    })
    const validationSchema = Yup.object().shape({
        university: Yup.string().required('Required'),
        course: Yup.string().required('Required'),
        // intake: Yup.array().min(1).required('Required'),
        // syllabus: Yup.array().min(1).required('Required'),
        // time: Yup.string().required('Required'),
        // course: Yup.number().required('Required'),
        // duration: Yup.string().required('Required'),
        // application: Yup.string().required('Required'),
        // fees: Yup.string().required('Required'),
        // time: Yup.string().required('Required'),
        // required_documents: Yup.array().of(
        //         Yup.object().shape({
        //             details: Yup.string().required("Required")
        //         }).required("Required")
        //     ),
        // fee_details: Yup.array().of(
        //     Yup.object().shape({
        //         term: Yup.string().required("Required"),
        //         fee_head: Yup.string().required("Required"),
        //         amount: Yup.string().required("Required")
        //     }).required("Required"),
        // ),
        // syllabus: Yup.array().of(
        //     Yup.object().shape({
        //         course_title: Yup.string().required("Required"),
        //         weeks: Yup.string().required("Required"),
        //         assignments: Yup.string().required("Required")
        //     }).required("Required"),

        // ),
        // entrance: Yup.array().of(
        //     Yup.object().shape({
        //         entrance_details: Yup.string().required("Required")
        //     }).required("Required"),
        // ),
        // eligibility: Yup.array().of(
        //     Yup.object().shape({
        //         eligibility_details: Yup.string().required("Required")
        //     }).required("Required"),
        // ),

    });

    const formik = useFormik({
        initialValues: {
            id: "",
            course: "",
            university: "",
            duration: "",
            fees: "",
            time: "",
            application: "",
            intake: "",
            fee_details: [{
                term: '',
                fee_head: '',
                amount: ''
            }],
            eligibility: [{
                eligibility_details: ''
            }],
            entrance: [{
                entrance_details: ''
            }],
            syllabus: [{
                course_title: '',
                weeks: '',
                assignments: '',
                syllabus_details: [{
                    details_syllabus: ''
                }],
            }],
            required_documents: [{
                required_details: ''
            }]


        },
        validationSchema,
        validate: async (values) => {

            // const errors = {}
            // // let image = values.image


            // // values.id ? image = await dataURLtoFile(values.image) : image = values.image
            // if (values.image == "") {
            //     errors.image = "Required"
            // }
            // else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
            //     errors.image = "Only jpg/jpeg and png files are allowed!"
            // }
            // console.log(values.image);
            // return errors
        },
        onSubmit: (values) => {
            let formData = new FormData();
            setIsloader(true)
            if (values.id) {
                formData.append("university_course_id", values.id ?? "");
            }
            formData.append("course_id", values.course);
            formData.append("university_id", values.university);
            formData.append("duration", values?.duration);
            formData.append("fees", values?.fees);
            formData.append("time", values?.time);
            formData.append("application", values?.application);

            formData.append("intake", JSON.stringify(values.intake));
            formData.append("fee_details", JSON.stringify(values?.fee_details));
            formData.append("eligibility", JSON.stringify(values?.eligibility));
            formData.append("entrance", JSON.stringify(values?.entrance));
            formData.append("syllabus", JSON.stringify(values?.syllabus));
            formData.append("required_documents", JSON.stringify(values?.required_documents));


            universityCourseService.addOrUpdateUniversityCourse(formData).then((response) => {


                if (response && response.status) {
                    setIsloader(false)
                    setFormView("details")
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        university: response?.response?.data?.errors?.title,
                        fee_details: response?.response?.data?.errors?.course_type,
                        required_documents: response?.response?.data?.errors?.image,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    const markAllFieldsAsTouched = () => {
        console.log("touched")
        const fieldKeys = Object.keys(formik.values);
        const touchedFields = fieldKeys.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});
        formik.setTouched(touchedFields);
      };

    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        let fee_details = [];
        let syllabus = [];


        item?.fee_details.map(detail => {
            fee_details.push({
                term: detail?.term,
                fee_head: detail?.fee_head,
                amount: detail?.amount
            })
        })
        item?.syllabus.map(data => {
            syllabus.push({
                course_title: data?.title,
                weeks: data?.weeks,
                assignments: data?.assignments,
                syllabus_details: data?.syllabus_details?.map(detail => { return { details_syllabus: detail?.details } }),
            })
        })
        console.log(item.fee_details)
        const date = moment(item?.application).format('YYYY-MM-DD');
        const filterIntake = intakeValue.filter((i) => item.intake.find((f) => f.month == i.label))
        formik.setValues({
            id: item.id,
            course: item?.course?.id,
            university: item?.university?.id,
            duration: item?.duration,
            fees: item?.fees,
            time: item?.time == 'Full time' ? 1 : 2,
            application: item?.application === "" ? "" :date,
            intake: filterIntake,
            fee_details: item.fee_details.length ? item.fee_details : [{
                term: '',
                fee_head: '',
                amount: ''
            }],
            eligibility: item.eligibility.length ? item?.eligibility?.map(eligibility => { return { eligibility_details: eligibility?.details } }) : [{
                eligibility_details: ''
            }],
            entrance: item.entrance.length ? item?.entrance?.map(entrance => { return { entrance_details: entrance?.details } }) : [{
                entrance_details: ''
            }],
            syllabus: syllabus.length ? syllabus : [{
                course_title: '',
                weeks: '',
                assignments: '',
                syllabus_details: [{
                    details_syllabus: ''
                }],
            }],
            required_documents: item?.required_documents.length ? item?.required_documents?.map(document => { return { required_details: document?.details } }) : [{
                required_details: ''
            }]
        })
        setFormView("details")
    }
    console.log(formik);
    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>University Course</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">University Course</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("View University Course") && <CreateUniversityCourse markAllFieldsAsTouched={markAllFieldsAsTouched} image={image} formView={formView} setFormView={setFormView} intakeValue={intakeValue} setIntakeData={setIntakeData} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <UniversityCourseDatatable bulkSucess={bulkSucess} bulkLoader={bulkLoader} bulkformik={bulkformik} pending={pending} currentPage={currentPage} totalRows={totalRows} setPerpage={setPerpage} setCurrentPage={setCurrentPage} setActive={setActive} tableloader={tableloader} filterText={filterText} setFilterText={setFilterText} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default UniversityCourse;

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import { CourseEnqueryService } from '../../../services/CourseEnqueryService';
import CourseApplicationDatatable from './CourseApplicationDatatable';

const courseEnqueryService = new CourseEnqueryService();

const CourseApplication = () => {
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [tableloader, setTableloader] = useState(true);
    const [active, setActive] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)

    async function getAllCourseEnquery() {
        active &&  setPending(true)
        const data = await courseEnqueryService.getAllCourseEnquery(perpage,currentPage,filterText );
        active && setPending(false)
        setData(data.data)
        setTotalRows(data.data?.count)
    }
    useEffect(() => {
        getAllCourseEnquery()
    }, [refresh,  perpage,currentPage,filterText])

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Course Application</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Course Application</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        <CourseApplicationDatatable setActive={setActive} currentPage={currentPage} pending={pending} filterText={filterText} setFilterText={setFilterText} setActive={setActive} tableloader={tableloader}  data={data} refresh={refresh} setRefresh={setRefresh} setCurrentPage={setCurrentPage} setPerpage={setPerpage} totalRows={totalRows} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default CourseApplication;
import axios from "axios";
import request from "../api/agent";
const API_ROUTE = "enquiry/get-started";

export class PREnquiryService {
    async getAllServiceEnquiry(perpage, currentPage, search, typeValue, contactValue) {
        const serchValue = search ?? "";
        //typeValue? enquiry_type:"";
        const enquiry_type = typeValue ? typeValue : "";
        const is_contacted = contactValue ? contactValue : "";
        if (perpage) {
            const response = await request.get(
                `${API_ROUTE}/list-get-started?page=${perpage}&limit=${currentPage}&search=${serchValue}&enquiry_type=pr&is_contacted=${is_contacted}`
            );
            return response.data;
        } else {
            const response = await request.get(`${API_ROUTE}/list-get-started`);
            return response.data;
        }
    }

    async deleteService(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-get-started`, {
            data: { get_started_id: id }
        });

        return response;
    }
    async setServiceStatus(id) {
        const response = await request.post(
            `${API_ROUTE}/active-or-inactive-get-started`,
            { get_started_id: id }
        );
        return response;
    }
    // async setContactStatus(id) {
    //   const response = await request.post(`${API_ROUTE}/user-contacted`,
    //    {
    //  enquiry_id: id}

    //   );
    //   return response;
    // }
    async setContactStatus(data) {
        let action = `${API_ROUTE}/user-contacted`;
        const response = await request.post(action, data);
        return response;
    }
}

import request from "../api/agent";
const API_ROUTE = "/feeds/feed";

export class FeedService {
    async addOrUpdateFeeds(feed) {
        let action = `${API_ROUTE}/feed-create`;
        const response = await request.post(action, feed);
        return response;
    }

    async getAllfeeds(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/feed-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }
    async setFeedStatus(id){
        const response = await request.post(`${API_ROUTE}/feed-status`,{feed:id})
        return response;
    }


 async deleteFeed(id) {
            console.log(";;;;;id",id)

        const response = await request.delete(`${API_ROUTE}/feed-delete`, {
            data: { feed_id: id }

        } );
        return response;

      }
}

import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import Select from 'react-select'
import { NotificationServices } from '../../../services/NotificationService';




const notificationService = new NotificationServices();
var options = [{value:'user',label:'select all'}];
const CreateNotifications = ({ user, setUser, formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {
    // const [users, setUsers] = useState([]);
    const [update, setUpdate] = useState(false)
    const onChangeImage = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            formik.setValues({ ...formik.values, notification_image: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) })
        }
    }


    useEffect(() => {
        getAllUser()
    }, [])


    async function getAllUser() {
        const data = await notificationService.userList();
        data.data?.results.forEach((item) => {
            options.push({ value:item.id, label:item.email })
        })
        setUpdate(!update)
    }
    const handleOnSelectChange = ({ name, value }) => {
        console.log(value)
       
            const hasAllUser = value.some(v => v.value === "user");
            if (hasAllUser) {
                const filterUser = options.filter(user=> (typeof user.value) === "number")
                formik.setValues({ ...formik.values, [name]: [...filterUser] })
                setUser(filterUser.map((user) => user.value))
            } else {
                formik.setValues({ ...formik.values, [name]: value })
                setUser(value.map((val) => (val.value)))
            }
        }

        const style = {
            control: (styles) => ({
              ...styles,
              borderColor: formik.errors.user ? "#dc3545" : "white",
              fontSize: "14px",
              height: "10px",
        
              "&:focus": {
                boxShadow: "#f5b7bd 0px 0px 0px 3px",
                borderColor: "#dc3545",
              },
            }),
          };
    console.log("options")
     console.log(options)
    
    console.log(formik.errors)
    return (
        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formik.values?.title}
                                onChange={formik.handleChange}
                                isValid={formik.touched.title && !formik.errors.title}
                                isInvalid={!!formik.errors.title}
                                placeholder="title"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>User</Form.Label>
                            <Select
                                placeholder="select users"
                                className="select-dropdown"
                                classNamePrefix="select"
                                isMulti
                                name="user"
                                isInvalid={formik.touched.user && formik.errors.user}
                                onBlur={formik.handleBlur}
                                isValid={formik.touched.user && !formik.errors.user}
                                options={options}
                                styles={style}
                                onChange={(e) => { handleOnSelectChange({ name: 'user', value: e }) }}
                            />
                            <div className="errorMessage text-danger mt-1" >{formik.errors.user}</div>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={formik.values?.description}
                                onChange={formik.handleChange}
                                isValid={formik.touched.description && !formik.errors.description}
                                isInvalid={!!formik.errors.description}
                                placeholder="description"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>

    )
}

export default CreateNotifications

import request from "../api/agent";
const API_ROUTE = "/notification/notification";

export class NotificationServices {
    async addOrUpdateNotification(notification) {
        let action = `${API_ROUTE}/create-or-update-notification-admin`;
        const response = await request.post(action, notification);
        return response;
    }

async userList(){
    const response = await request.get(`/user/user/user-listing`)
    return response.data;
}

    async getAllNotification(perpage,currentPage) {
        const response = await request.get(`${API_ROUTE}/get-all-notification-admin?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }

    async deleteNotification(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-admin-notification`, {
            data: { ids: [id] }
        });
        return response;
    }
}
import request from "../api/agent";
const API_ROUTE = "alumni_profile/alumni";

export class AlumniProfileService {
    async addOrUpdateAlumniProfile(data) {
        let action = `${API_ROUTE}/alumni-profile-register`;
        const response = await request.post(action, data);
        return response;
    }

    async getAllAlumniProfile(perpage,currentPage,search) {
        const serchValue = search??""
        const response = await request.get(`${API_ROUTE}/alumni-profile-listing?limit=${perpage}&page=${currentPage}&search=${serchValue}`);
        return response.data;
    }


    async deleteAlumniProfile(id) {
        const response = await request.delete(`${API_ROUTE}/alumni-profile-delete`, {
            data: { user: [id] }
        });
        return response;
      }

      async alumniProfileStatus(data) {
        let action = `${API_ROUTE}/alumni-profile-status`;
        const response = await request.post(action, data);
        return response;
    }
   
}
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

import loader from '../../../assets/images/loader.gif';


import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import BasicSweetCallback from "../../SweetAlertCallback";
import { UniversityTypeService } from "../../../services/UniversityTypeService";

import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { checkUserPermission } from '../../checkPermission';

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
const universityTypeService = new UniversityTypeService();
const UniversityTypeDatatable = ({
  currentPage,
  setCurrentPage,
  setFilterText,
  filterText,
  setPerpage,
  data,
  handleEdit,
  refresh,
  setRefresh,
  tableloader,
  pending,
  handlePerRowsChange,
  handleRowClicked,
  handleChange,
  totalRows
}) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );

  const columns = [
    {
      name: "SL.No",
      key: "index",
      grow: 0.8,
      cell: (row, index) =>((currentPage * 10) + (index + 1)) - 10,
    },
    {
      name: "Name",
      selector: (row) => row.title,
      sortable: true,
      grow: 2,
      reorder: true,
    },
    {
      name: 'Status',
      grow: 2,
      selector: row => {
        return <div className="form-check form-switch"  >
          <input onClick={() => {
            universityTypeService.Status(row.id).then((response) => {
              setRefresh(!refresh)
            })
          }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_active} />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
        </div>
      }
      ,
      sortable: true,
      reorder: true,
    },


    (checkUserPermission("Edit University Type") || checkUserPermission("Delete University Type")) && {
      id: "action",
      grow: 2,
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center">
          {checkUserPermission("Edit University Type") && <Link
            onClick={() => handleEdit(row)}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span className="svg-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </OverlayTrigger>
          </Link>}
          {checkUserPermission("Delete University Type") && <BasicSweetCallback
            onDelete={universityTypeService.deleteUniversityType}
            deleteId={row.id}
            title="University  Type"
            setRefresh={setRefresh}
          />}
        </div>
      ),
      sortable: true,
    },
  ];

  const [toggleCleared, setToggleCleared] = React.useState(false);



  return (
    <React.Fragment>
      <div className="mt-5">
        <Card style={{ height: "100%" }}>
          <div className="input-group">
            <div className="form-outline">
              <input
                type="search"
                id="form1"
                onChange={(e) => setFilterText(e.target.value)}
                className="form-control"
                placeholder="search"
              />
            </div>
          </div>

          <DataTable
            title="University Types"
            columns={columns}
            data={data?.results}
            defaultSortFieldId={1}
            highlightOnHover
            defaultSortField="name"
            sortIcon={sortIcon}
            selectableRowsComponent={Checkbox}
            selectableRowsComponentProps={selectProps}
            onSelectedRowsChange={handleChange}
            clearSelectedRows={toggleCleared}
            onRowClicked={handleRowClicked}
            onChangePage={(page) => setCurrentPage(page)}
            onChangeRowsPerPage={handlePerRowsChange}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            progressPending={pending}
          />

        </Card>
      </div>
    </React.Fragment>
  );
};

export default UniversityTypeDatatable;


import CreateNotifications from './CreateNotifications'
import NotificationsDatatable from './NotificationDatatable';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';


import { ToastContainer, toast } from 'react-toastify';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { NotificationServices } from '../../../services/NotificationService';
import { checkUserPermission } from '../../checkPermission';


const notificationServices = new NotificationServices();

const Notifications = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [user, setUser] = useState([]);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [pending, setPending] = React.useState(true)

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };

    async function getNotification() {
        setPending(true)
        const data = await notificationServices.getAllNotification(perpage, currentPage);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)

    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [scroll])

    useEffect(() => {
        getNotification()
    }, [refresh, perpage, currentPage])

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
        description: Yup.string()
            .required('Required'),
       user: Yup.array().min(1).
           required("Required"),
    });

    const formik = useFormik({
        initialValues: {
            instance_id: "",
            title: "",
            description: "",
            user: ""
        },
        validationSchema,
        // validate: async (values) => {
        //     const errors = {}
        //     if (values.notification_image == "") {
        //         errors.notification_image = "Required"
        //     }
        //     else if (values?.notification_image?.type !== "image/png" && values?.notification_image?.type !== "image/jpg" && values?.notification_image?.type !== "image/jpeg") {
        //         errors.notification_image = "Only jpg/jpeg and png files are allowed!"
        //     }
        //     console.log(values.notification_image);
        //     return errors
        // },

        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.instance_id) {
                formData.instance_id = values.instance_id;
            }
            formData.append("title", values.title);
            formData.append("description", values.description);
           // formData.append("notification_image", values.notification_image);
            formData.append("user", JSON.stringify(user));
            notificationServices.addOrUpdateNotification(formData).then((response) => {
                // })
                // CreateOrUpdateCourse({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    getNotification()
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        title: response?.response?.data?.errors?.title,
                        description: response?.response?.data?.errors?.description,
                        user: response?.response?.data?.errors?.user,

                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });

    console.log("formik")
    console.log(formik)
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        console.log("item")
        // console.log(items)
        formik.setValues({
            id: item.id,
            title: item.title,
            description: item.description,
            user: item.label,
            // notification_image: await dataURLtoFile(item.notification_image),
            // image_url: item.notification_image
        })


    }
    console.log(formik.errors)
    return (
        <div><React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Notification</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Notification</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create Notification") && <CreateNotifications
                            image={image}
                            formik={formik}
                            isloader={isloader}
                            toaster={toaster}
                            setToaster={setToaster}
                            setImage={setImage}
                            formRef={formRef}
                            user={user}
                            setUser={setUser}
                        />}
                    </React.Fragment>
                    <React.Fragment>
                        <NotificationsDatatable currentPage={currentPage} pending={pending} setUser={setUser} user={user} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
        </div>

    )
}

export default Notifications

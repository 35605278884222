import request from "../api/agent";



export class StudentService {

    async getStudentService(limit, page, search) {
        const serchValue = search ?? ""
        const response = await request.get(`profile/student-profile/get-all-students-profile?page=${page}&limit=${limit}&search=${serchValue}`);
        return response.data;
    }
    async getAgent() {
        const response = await request.get(`profile/chat/agent-list`);
        return response.data;
    }
    async addOrUpdateAgent(instance_id, agent, student) {
        let action = `user/user/assign-agent`;
        const response = await request.post(action, instance_id, agent, student);
        return response;
    }



}
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { StateService } from '../../../services/StateServices';

const DistrictCreate = ({ formik, isloader, formRef }) => {

    const [stateData,setStateData] = useState([]);

    const stateService = new StateService();
    
    async function getAllState() {
        const data = await stateService.getStates();
        setStateData(data.data.results)
      }

    useEffect(() => {
        getAllState()
    }, [])

    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.name && !formik.errors.name}
                                isInvalid={!!formik.errors.name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>State</Form.Label>
                            <Form.Select
                                placeholder="Select an Option"
                                className="select-dropdown"
                                classNamePrefix="select"
                                name="state_id"
                                value={formik.values?.state_id}
                                isValid={formik.touched.state_id && !formik.errors.state_id}
                                isInvalid={!!formik.errors?.state_id}
                                onChange={formik.handleChange}
                            >
                                <option value=''>Select an Option</option>
                                {stateData?.map(state =>
                                    <option value={state?.id}>{state?.name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback  type="invalid">{formik.errors.state_id}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>

    )
}

export default DistrictCreate;
import React, { useEffect, useState } from 'react';
import { BsChevronDown, BsCircle, BsFillDoorClosedFill, BsGrid, BsFillSignIntersectionTFill, BsBookmarkPlusFill, AiFillSetting, BsMicrosoftTeams, BsFillCalendar2PlusFill, BsFillExplicitFill } from 'react-icons/bs';
import { FaQuestion, FaSearch, FaUniversity, FaUserFriends, FaUsers } from 'react-icons/fa';
import { SiHelpscout } from 'react-icons/si';
import { Link, useLocation } from 'react-router-dom';
import { GiGraduateCap } from 'react-icons/gi'
import { SlFeed } from 'react-icons/sl'
import { checkUserPermission } from '../checkPermission';
import { GrNotification } from 'react-icons/gr';
import { ServiceEnquiryService } from '../../services/sevrviceEnquiryService';


const Sidebar = () => {
    const location = useLocation();
    const serviceEnquiryService = new ServiceEnquiryService();
    const [notificationCount, setNotificationCount] = useState([]);
    const [totalNotificationCount, setTotalNotificationCount] = useState("");
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetch('https://api.algateinternational.com/api/dashboard/admin/dashboard-data')
            .then(response => response.json())
            .then(jsonData => {
                console.log(jsonData?.data?.results?.notification)
                setNotificationCount(jsonData?.data?.results?.notification)
                setTotalNotificationCount(jsonData?.data?.results?.total_notification_count)
            })
            .catch(error => console.log(error));

    }, [refresh]);


    function handleNotificationCount(notificationType) {
        serviceEnquiryService.setNotificationCount(notificationType).then((response) => {
            if (response.status && response)
                setRefresh(prev => !prev)
        })
    }

    return (
        <React.Fragment>
            <>
                <aside id="sidebar" className="sidebar">
                    <ul className="sidebar-nav" id="sidebar-nav">
                        <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                            <Link className="nav-link collapsed" to="/">
                                <BsGrid />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        {(checkUserPermission("View Course") || checkUserPermission("View Course Syllabus") || checkUserPermission("View Course Syllabus") || checkUserPermission("View Course Stream")) && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#course-nav" data-bs-toggle="collapse">
                                <FaUniversity /> <span>Course</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="course-nav"
                                className={`nav-content collapse ${location.pathname === '/course-type' || location.pathname === '/courses' || location.pathname === '/stream' || location.pathname === '/specialization' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">

                                {checkUserPermission("View Course Type") && <li className={`nav-item ${location.pathname === '/course-type' ? 'active' : ''}`}>
                                    <Link to="/course-type">
                                        <BsCircle /><span>Course Type</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Course") && <li className={`nav-item ${location.pathname === '/courses' ? 'active' : ''}`}>
                                    <Link to="/courses">
                                        <BsCircle /><span>Create Courses</span>
                                    </Link>
                                </li>}
                                {/* {checkUserPermission("View Course Stream") && <li className={`nav-item ${location.pathname === '/stream' ? 'active' : ''}`}>
                                    <Link to="/stream">
                                        <BsCircle /><span>Course Stream</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Specialization") && <li className={`nav-item ${location.pathname === '/specialization' ? 'active' : ''}`}>
                                    <Link to="/specialization">
                                        <BsCircle />
                                        <span>Specialization </span>
                                    </Link>
                                </li>} */}
                            </ul>
                        </li>}
                        {checkUserPermission("View Entrance") && <li className={`nav-item ${location.pathname === '/entrance' ? 'active' : ''}`}>
                            <Link className="nav-link collapsed" to="/entrance">
                                <BsFillExplicitFill />
                                <span>Entrance Exams</span>
                            </Link>
                        </li>}
                        {checkUserPermission("View Placement") && <li className={`nav-item ${location.pathname === '/placement' ? 'active' : ''}`}>
                            <Link className="nav-link collapsed" to="/placement">
                                <FaUsers />
                                <span>Placement Cell</span>
                            </Link>
                        </li>}
                        {(checkUserPermission("View University") || checkUserPermission("View University News") || checkUserPermission("View University Course")) && <li className="nav-item">
                            <div className="nav-link collapsed" data-bs-target="#university-nav" data-bs-toggle="collapse">
                                <FaUniversity /> <span>University</span><BsChevronDown className='chevron-down ms-auto' />
                            </div>
                            <ul id="university-nav"
                                className={`nav-content collapse ${location.pathname === '/university-type' || location.pathname === '/university' || location.pathname === '/university-course' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">

                                {checkUserPermission("View University News") && <li className={`nav-item ${location.pathname === '/university-type' ? 'active' : ''}`}>
                                    <Link to="/university-type">
                                        <BsCircle /><span>University Type</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Universities") && <li className={`nav-item ${location.pathname === '/university' ? 'active' : ''}`}>
                                    <Link to="/university">
                                        <BsCircle /><span>Create University</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View University Course") && <li className={`nav-item ${location.pathname === '/university-course' ? 'active' : ''}`}>
                                    <Link to="/university-course">
                                        <BsCircle /><span>University Course</span>
                                    </Link>
                                </li>}
                            </ul>
                        </li>}
                        {/* {(checkUserPermission("View Sholarship") || checkUserPermission("View Scholarship Winners")) && <li className="nav-item">
                            <a className="nav-link collapsed" data-bs-target="#scholarship-nav" data-bs-toggle="collapse" href="#">
                                <GiGraduateCap /> <span>Scholarship</span><BsChevronDown className='chevron-down ms-auto' />
                            </a>
                            <ul id="scholarship-nav"
                                className={`nav-content collapse ${location.pathname === '/scholarship-category' || location.pathname === '/scholarship' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">
                                {checkUserPermission("View Sholarship") && <li className={`nav-item ${location.pathname === '/scholarship-category' ? 'active' : ''}`} >
                                    <Link to="/scholarship-category">
                                        <BsCircle /><span>Scholarship Category</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Sholarship") && <li className={`nav-item ${location.pathname === '/scholarship' ? 'active' : ''}`} >
                                    <Link to="/scholarship">
                                        <BsCircle /><span>Scholarship</span>
                                    </Link>
                                </li>}
                            </ul>
                        </li>} */}
                        {(checkUserPermission("View Abroad Plan") || checkUserPermission("View Enroll")) && <li className="nav-item">
                            <a className="nav-link collapsed" data-bs-target="#support-nav" data-bs-toggle="collapse" href="#">
                                <SiHelpscout /> <span>Support</span><BsChevronDown className='chevron-down ms-auto' />
                            </a>
                            <ul id="support-nav" className={`nav-content collapse ${location.pathname === '/aboard' || location.pathname === '/enroll' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">
                                {checkUserPermission("View Abroad Plan") && <li className={`nav-item ${location.pathname === '/aboard' ? 'active' : ''}`}>
                                    <Link to="/aboard">
                                        <BsCircle /><span>Abroad Plan</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Enroll") && <li className={`nav-item ${location.pathname === '/enroll' ? 'active' : ''}`}>
                                    <Link to="/enroll">
                                        <BsCircle /><span>Enroll</span>
                                    </Link>
                                </li>}
                            </ul>
                        </li>}
                        {(checkUserPermission("View Service Enquiry") || checkUserPermission("View University Enquiry") || checkUserPermission("View University Course Enquiry")) && <li className="nav-item">
                            <a className="nav-link collapsed" data-bs-target="#enquiry-nav" data-bs-toggle="collapse" href="#">
                                <FaSearch /> <span className='notification-container'>Enquiry {totalNotificationCount ? <span className='total-notification-count'>{totalNotificationCount}</span> : ""}</span><BsChevronDown className='chevron-down ms-auto' />
                            </a>
                            <ul id="enquiry-nav" className={`nav-content collapse ${location.pathname === '/enquiryservice' || location.pathname === '/university-enquiry' || location.pathname === '/universitycourseenquiry' ? 'collapse show' : ''}`}
                                data-bs-parent="#enquiry-nav">
                                {checkUserPermission("View Service Enquiry") && <li onClick={() =>handleNotificationCount("service_enquiry")} className={`nav-item ${location.pathname === '/enquiryservice' ? 'active' : ''}`}>
                                    <Link to="/enquiryservice">
                                        <BsCircle />
                                        <span className='notification-container' >Service Enquiry {notificationCount[0]?.service_enquiry ? <span className='total-notification-count'>{notificationCount[0]?.service_enquiry}</span> : ""}</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View University Enquiry") && <li onClick={() =>handleNotificationCount("university_enquiry")} className={`nav-item ${location.pathname === '/university-enquiry' ? 'active' : ''}`}>
                                    <Link to="/university-enquiry">
                                        <BsCircle /><span className='notification-container'>University Enquiry {notificationCount[0]?.university_enquiry ? <span className='total-notification-count'>{notificationCount[0]?.university_enquiry}</span> : ""}</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View University Course Enquiry") && <li onClick={() =>handleNotificationCount("course_enquiry")} className={`nav-item ${location.pathname === '/universitycourseenquiry' ? 'active' : ''}`}>
                                    <Link to="/universitycourseenquiry">
                                        <BsCircle /><span className='notification-container'>Course Enquiry {notificationCount[0]?.course_enquiry ? <span className='total-notification-count'>{notificationCount[0]?.course_enquiry}</span> : ""}</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View University Course Enquiry") && <li onClick={() =>handleNotificationCount("scholarship_enquiry")} className={`nav-item ${location.pathname === '/scholarship-enquiry' ? 'active' : ''}`}>
                                    <Link to="/scholarship-enquiry">
                                        <BsCircle /><span className='notification-container'>Scholarship Enquiry {notificationCount[0]?.scholarship_enquiry ? <span className='total-notification-count'>{notificationCount[0]?.scholarship_enquiry}</span> : ""}</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View University Course Enquiry") && <li onClick={() =>handleNotificationCount("pr_enquiry")} className={`nav-item ${location.pathname === '/pr-enquiry' ? 'active' : ''}`}>
                                    <Link to="/pr-enquiry">
                                        <BsCircle /><span className='notification-container'>PR Enquiry {notificationCount[0]?.pr_enquiry ? <span className='total-notification-count'>{notificationCount[0]?.pr_enquiry}</span> : ""}</span>
                                    </Link>
                                </li>}

                            </ul>
                        </li>}
                        {checkUserPermission("View Appointments") && <li className={`nav-item ${location.pathname === '/appointments' ? 'active' : ''}`}>
                            <Link className="nav-link collapsed" to="/appointments">
                                <BsFillCalendar2PlusFill />
                                <span>Appointments</span>
                            </Link>
                        </li>}
                        {checkUserPermission("View Alumni Profile") && <li className={`nav-item ${location.pathname === '/alumini-profile' ? 'active' : ''}`}>
                            <Link className="nav-link collapsed " to="/alumni-profile">
                                <BsBookmarkPlusFill />
                                <span>Alumni Profile</span>
                            </Link>
                        </li>}
                        {(checkUserPermission("View Roles") || checkUserPermission("View Groups") || checkUserPermission("View Users")) && <li className="nav-item">
                            <a className="nav-link collapsed" data-bs-target="#user-nav" data-bs-toggle="collapse" href="#">
                                <FaUserFriends /> <span>User Management</span><BsChevronDown className='chevron-down ms-auto' />
                            </a>
                            <ul id="user-nav" className={`nav-content collapse ${location.pathname === '/roles' || location.pathname === '/group' || location.pathname === '/users' ? 'collapse show' : ''}`}
                                data-bs-parent="#user-nav">
                                {checkUserPermission("View Roles") && <li className={`nav-item ${location.pathname === '/roles' ? 'active' : ''}`}>
                                    <Link to="/roles">
                                        <BsCircle /><span>Roles</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Groups") && <li className={`nav-item ${location.pathname === '/group' ? 'active' : ''}`}>
                                    <Link to="/group">
                                        <BsCircle /><span>Groups</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View Users") && <li className={`nav-item ${location.pathname === '/users' ? 'active' : ''}`}>
                                    <Link to="/users">
                                        <BsCircle /><span>Users</span>
                                    </Link>
                                </li>
                                }
                            </ul>
                        </li>}

                        {(checkUserPermission("View Country") || checkUserPermission("View State") || checkUserPermission("View District")) && <li className="nav-item">
                            <a className="nav-link collapsed" data-bs-target="#location-nav" data-bs-toggle="collapse" href="#">
                                <FaUserFriends /> <span>Location</span><BsChevronDown className='chevron-down ms-auto' />
                            </a>
                            <ul id="location-nav" className={`nav-content collapse ${location.pathname === '/countries' || location.pathname === '/State' || location.pathname === '/district' ? 'collapse show' : ''}`}
                                data-bs-parent="#sidebar-nav">
                                {checkUserPermission("View Country") && <li className={`nav-item ${location.pathname === '/countries' ? 'active' : ''}`}>
                                    <Link to="/countries">
                                        <BsCircle /><span>Country</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View State") && <li className={`nav-item ${location.pathname === '/State' ? 'active' : ''}`}>
                                    <Link to="/State">
                                        <BsCircle /><span>State</span>
                                    </Link>
                                </li>}
                                {checkUserPermission("View District") && <li className={`nav-item ${location.pathname === '/district' ? 'active' : ''}`}>
                                    <Link to="/district">
                                        <BsCircle /><span>District</span>
                                    </Link>
                                </li>}
                            </ul>
                        </li>}
                        <li className="nav-item">
                            {(checkUserPermission("View Testimonial") || checkUserPermission("View Testimonial Video")) && <li className="nav-item">
                                <a className="nav-link collapsed" data-bs-target="#testimonial-nav" data-bs-toggle="collapse" href="#">
                                    <BsFillSignIntersectionTFill /> <span>Testimonial</span><BsChevronDown className='chevron-down ms-auto' />
                                </a>
                                <ul id="testimonial-nav" className={`nav-content collapse ${location.pathname === '/testimonial' || location.pathname === '/testimonial-video' || location.pathname === '/testimonial-interaction' ? 'collapse show' : ''}`}
                                    data-bs-parent="#testimonial-nav">
                                    {checkUserPermission("View Testimonial") && <li className={`nav-item ${location.pathname === '/testimonial' ? 'active' : ''}`}>
                                        <Link to="/testimonial">
                                            <BsCircle /><span>Testimonial</span>
                                        </Link>
                                    </li>}
                                    {checkUserPermission("View Testimonial Video") && <li className={`nav-item ${location.pathname === '/testimonial-video' ? 'active' : ''}`}>
                                        <Link to="/testimonial-video">
                                            <BsCircle /><span>Testimonial Video</span>
                                        </Link>
                                    </li>}
                                    {checkUserPermission("View Interactive Gallery") && <li className={`nav-item ${location.pathname === '/testimonial-interaction' ? 'active' : ''}`}>
                                        <Link to="/testimonial-interaction">
                                            <BsCircle /><span>Interactive Gallery</span>
                                        </Link>
                                    </li>}
                                </ul>
                            </li>}
                            {checkUserPermission("View Student") && <li className={`nav-item ${location.pathname === '/students' ? 'active' : ''}`}>
                                <Link className="nav-link collapsed" to="/students">
                                    <FaSearch />
                                    <span>Students</span>
                                </Link>
                            </li>}
                            {/* <li className="nav-item"> */}
                            {(checkUserPermission("View Feed") || checkUserPermission("View Banner Feed")) && <li className="nav-item">
                                <div className="nav-link collapsed" data-bs-target="#course-nav" data-bs-toggle="collapse">
                                    <SlFeed /> <span>Feeds</span><BsChevronDown className='chevron-down ms-auto' />
                                </div>
                                <ul id="course-nav"
                                    className={`nav-content collapse ${location.pathname === '/feed' || location.pathname === '/banner' || location.pathname === '/stream' || location.pathname === '/specialization' ? 'collapse show' : ''}`}
                                    data-bs-parent="#sidebar-nav">
                                    {checkUserPermission("View Banner Feed") && <li className={`nav-item ${location.pathname === '/banner' ? 'active' : ''}`}>
                                        <Link to="/banner">
                                            <BsCircle /><span>
                                                Banner
                                            </span>
                                        </Link>
                                    </li>}
                                    {checkUserPermission("View Feed") && <li className={`nav-item ${location.pathname === '/feed' ? 'active' : ''}`}>
                                        <Link to="/feed">
                                            <BsCircle /><span>Feed</span>
                                        </Link>
                                    </li>}


                                </ul>
                            </li>}
                            {/* {checkUserPermission("View Feed") && <li className={`nav-item ${location.pathname === '/feed' ? 'active' : ''}`}>
                                <Link className="nav-link collapsed" to="/feed">
                                    <SlFeed />
                                    <span>Feed</span>
                                </Link>
                            </li>}
                            {checkUserPermission("View BAnner Feed") && <li className={`nav-item ${location.pathname === '/banner' ? 'active' : ''}`}>
                                <Link className="nav-link collapsed" to="/banner">
                                    <SlFeed />
                                    <span>banner</span>
                                </Link>
                            </li>} */}
                            {checkUserPermission("View Notification") && <li className={`nav-item ${location.pathname === '/notifications' ? 'active' : ''}`}>
                                <Link className="nav-link collapsed" to="/notifications">
                                    <GrNotification />
                                    <span>Notification</span>
                                </Link>
                            </li>}
                            {checkUserPermission("View Recent Intake") && <li className={`nav-item ${location.pathname === '/resent-intake' ? 'active' : ''}`}>
                                <Link className="nav-link collapsed" to="/resent-intake">
                                    <BsFillDoorClosedFill />
                                    <span>Recent Intake</span>
                                </Link>
                            </li>}
                            {checkUserPermission("View Course Application") && <li className={`nav-item ${location.pathname === '/course-application' ? 'active' : ''}`}>
                                <Link className="nav-link collapsed" to="/course-application">
                                    <FaSearch />
                                    <span>Course Application</span>
                                </Link>
                            </li>}
                            {checkUserPermission("View FAQ") && <li className={`nav-item ${location.pathname === '/faq' ? 'active' : ''}`}>
                                <Link className="nav-link collapsed" to="/faq">
                                    <FaQuestion />
                                    <span>FAQs</span>
                                </Link>
                            </li>}
                        </li>
                    </ul>
                </aside>
            </>
        </React.Fragment>
    )
}

export default Sidebar;
import request from "../api/agent";

const API_ROUTE = "testimonial/interactive-gallery";

export class TestimonialInteraction {

    async getImage(perpage,currentPage) {
        const response = await request.get(`${API_ROUTE}/interactive-listing?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }


    async addOrUpdateImage(image) {
        let action = `${API_ROUTE}/interactive-gallery-creation`;
        const response = await request.post(action, image);
        return response;
    }

    async deleteImage(id) {
        const response = await request.delete(`${API_ROUTE}/interactive-gallery-delete`, {data: { image_id: [id] }});
        return response;
    }

    async getImageStatus(id){
        const response = await request.post(`${API_ROUTE}/interactive-status`,{image_id:id});
        return response;
    }
}
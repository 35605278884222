import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { EntranceService } from '../../../services/EntranceService';
import EntranceCreate from './EntranceCreate';
import EntranceDatatable from './EntranceDatatable';
import { checkUserPermission } from '../../checkPermission';


const Entrance = () => {

    const entranceService = new EntranceService();

    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [pending, setPending] = React.useState(true)
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const[typeValue,setTypeValue]=useState();

    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };




    async function getEntrance() {
        setPending(true)
        const data = await entranceService.getEntrance(perpage, currentPage);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }

    useEffect(() => {
        getEntrance()
    }, [refresh, perpage, currentPage])
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [scroll])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required')
    });



    const formik = useFormik({
        initialValues: {
            entrance_id: "",
            name: "",
            is_active: true
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.entrance_id) {
                formData.append("entrance_id", values.entrance_id ?? "");
            }
            formData.append("name", values.name);


            entranceService.addOrUpdateEntrance(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        video: response?.response?.data?.errors?.title,
                        text: response?.response?.data?.errors?.description,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        formik.setValues({
            entrance_id: item.id,
            name: item.name,
        })
        setScroll(preVal=>!preVal)
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Entrance</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Entrance</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create Entrance") && <EntranceCreate formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <EntranceDatatable
                         totalRows={totalRows} 
                         handleChange={handleChange} 
                         handleRowClicked={handleRowClicked} 
                         handlePerRowsChange={handlePerRowsChange}
                          pending={pending} 
                          currentPage={currentPage}
                           setCurrentPage={setCurrentPage} 
                           setPerpage={setPerpage}
                            setTypeValue={setTypeValue} 
                            typeValue={typeValue}
                               data={data} 
                               handleEdit={handleEdit}
                                refresh={refresh}
                                 setRefresh={setRefresh} 
                                 formik={formik}
                              
                        />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default Entrance
import React, { useEffect, useState, Fragment } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';
import { PermissionService } from '../../../services/PermissionService';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { RolesService } from '../../../services/RolesService';
import Select from 'react-select';

const rolesService = new RolesService();
var options = [];

const CreateGroup = ({ formik, isloader, formRef, roles, setRoles }) => {

    const [nodes, setNodes] = useState(
        [{ label: " Contact Us", value: " Contact Us" }, { label: "Category", value: "Category" }]);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        getRoles()
    }, [])

    async function getRoles() {
        const data = await rolesService.getRoles();
        options = []
        data.data?.results.forEach((item) => {
            options.push({ value: item.id, label: item.name })
        })
        setUpdate(!update)
    }

    const handleOnSelectChange = ({ name, value }) => {
        console.log("value")
        console.log(value)
        formik.setValues({ ...formik.values, [name]: value })
        setRoles(value.map((val) => val.value))
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.name && !formik.errors.name}
                                isInvalid={!!formik.errors.name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Roles</Form.Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isMulti
                                value={formik.values.roles}
                                name="roles"
                                options={options}
                                onChange={(e) => { handleOnSelectChange({ name: 'roles', value: e }) }}
                                isValid={formik.touched.roles && !formik.errors.roles}
                                isInvalid={!!formik.errors.roles}
                                placeholder="Select Role Type..."
                            />
                            {formik.errors.roles ?
                                <div className='required'> Required</div>

                                :
                                <Fragment></Fragment>
                            }
                        </Form.Group>

                    </Row>

                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateGroup;
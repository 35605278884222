import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile } from '../../functions';
import { TestimonialInteraction } from '../../../services/TestimonialGalleryService';
import TestimonialGalleryCreate from './TestimonialGalleryCreate';
import TestimonialGalleryDatatable from './TestimonialGalleryDatatable';
import { checkUserPermission } from '../../checkPermission';



const TestimonialGallery = () => {

  const testimonialInteraction = new TestimonialInteraction();


  const formRef = useRef(null);
  const [isloader, setIsloader] = useState(false);
  const [toaster, setToaster] = useState("");
  const [image, setImage] = useState();
  const [data, setData] = React.useState([]);
  const [refresh, setRefresh] = useState(false);

  const [totalRows, setTotalRows] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [scroll, setScroll] = useState(false);
  const [tableloader, setTableloader] = useState(true);
  const [pending, setPending] = React.useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [scroll])


  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage)
  };

  async function getImages() {
    setPending(true)
    const data = await testimonialInteraction.getImage(perpage, currentPage);
    setPending(false)
    setData(data.data)
    setTotalRows(data.data.count)
  }

  useEffect(() => {
    getImages()
  }, [refresh, perpage, currentPage])

  const formik = useFormik({
    initialValues: {
      id: "",
      image: "",
      image_url: "",
      is_active: true
    },
    validate: async (values) => {
      const errors = {}
      if (values.image == "") {
        errors.image = "Required"
      }
      else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
        errors.image = "Only jpg/jpeg and png files are allowed!"
      }
      else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                  errors.image ="images should be less than 2 MB"
                }
              }
      console.log(values.image);
      return errors
    },
    onSubmit: (values) => {
      console.log(values);
      setIsloader(true)
      let formData = new FormData();
      if (values.id) {
        formData.append("image_id", values.id ?? "");
      }
      formData.append("image", values.image);

      testimonialInteraction.addOrUpdateImage(formData).then((response) => {
        if (response && response.status) {
          setIsloader(false)
          console.log(response?.data?.data)
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT
          });
          setRefresh(!refresh)
          formik.resetForm()
          formRef.current.reset();
        }
        else {
          formik.setErrors({
            video: response?.response?.data?.errors?.title,
            text: response?.response?.data?.errors?.description,
            image: response?.response?.data?.errors?.image,
          });
          console.log(response?.response?.data?.errors);
          setIsloader(false)
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    },
  });
  const handleEdit = async (item) => {
    setScroll(preVal=>!preVal)
    formik.setValues({
      id: item.id,
      image: await dataURLtoFile(item.image),
      image_url: item.image
    })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Interactive Gallery</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Interactive Gallery</li>
              </ol>
            </nav>
          </div>
          <React.Fragment>
            {checkUserPermission("Create Interactive Gallery") && <TestimonialGalleryCreate image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
          </React.Fragment>
          <React.Fragment>
            <TestimonialGalleryDatatable
              pending={pending}
              data={data}
              handleEdit={handleEdit}
              refresh={refresh}
              setRefresh={setRefresh}
              handlePerRowsChange={handlePerRowsChange}
              setCurrentPage={setCurrentPage}
              totalRows={totalRows}
              formik={formik}
              currentPage={currentPage}
            />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  )
}

export default TestimonialGallery
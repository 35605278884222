import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import { MDBDataTable } from 'mdbreact'
import moment from 'moment'
import Card from "@material-ui/core/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import BasicSweetCallback from "../../SweetAlertCallback";
import { ApplicationFormService } from "../../../services/ApplicationFormService";
import { Button, Col, Form, Row } from 'react-bootstrap';

import loader from '../../../assets/images/loader.gif';
import { checkUserPermission } from '../../checkPermission';


const applicationFormService = new ApplicationFormService();
const sortIcon = <ArrowDownward />;



const ApplicationFormDatatable = ({ setActive,pending, tableloader, data, refresh, setRefresh, setCurrentPage, setPerpage, totalRows, setFilterText, }) => {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    function handleVerification(id) {
        applicationFormService.formVerification(id).then((response) => {
            if (response.status)
                setActive(false)
            setRefresh(prev => !prev)

        })

    }
    function handleCompletion(id) {
        applicationFormService.formCompletion(id).then((response) => {
            if (response.status)
                setRefresh(prev => !prev)
        })

    }


    const columns = [
        {
            name: "SI.No",
            key: "index",
            cell: (row, index) => index + 1,
            grow: 1,
            center: true
        },
        {
            name: 'Name',
            selector: row => row?.form_data.fullname,
            sortable: true,
            grow: 1,
            reorder: true,
            center: true
        },
        {
            name: 'Email',
            selector: row => row?.form_data.email,
            sortable: true,
            grow: 1,
            reorder: true,
            center: true
        },
        {
            id: 'action',
            name: 'Actions',
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    {row.is_verified ? <button className="button-13" role="button" onClick={() => handleCompletion(row.id)}>Completed</button>
                        :
                        <button className="button-13" role="button" onClick={() => handleVerification(row.id)}>Verify</button>}
                    <button className="button-13" role="button" onClick={handleShowModal}>View</button>
                    {checkUserPermission("Delete Application Form") && <BasicSweetCallback onDelete={applicationFormService.deleteApplicationForm} deleteId={row?.id} title="ApplicationForm" setRefresh={setRefresh} refresh={refresh} />}
                    <Modal show={showModal} onHide={handleCloseModal} styles={{ padding: "20px !important" }} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ margin: "40px 20px " }}>
                                <ul>
                                    <li className='bold'>University Name</li>
                                    <ul>
                                        <li>{row.form_data.university_details.university_name}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Course Name</li>
                                    <ul>
                                        <li>{row.form_data.course}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Full Name</li>
                                    <ul>
                                        <li>{row.form_data.fullname}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Email</li>
                                    <ul>
                                        <li>{row.form_data.email}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Phone Number</li>
                                    <ul>
                                        <li>{row.form_data.phone}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Date Of Birth</li>
                                    <ul>
                                        <li>{row.form_data.dob}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Martial Status </li>
                                    <ul>
                                        <li>{row.form_data.martial_status}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Gender</li>
                                    <ul>
                                        <li>{row.form_data.gender}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Email</li>
                                    <ul>
                                        <li>{row.form_data.email}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Qualification</li>
                                    <ul>
                                        <li>{row.form_data.qualification}</li>
                                    </ul>
                                </ul>

                                <ul>
                                    <li className='bold'>Marks In Percentage</li>
                                    <ul>
                                        <li>{row.form_data.percentage_of_mark}</li>
                                    </ul>
                                </ul>

                                <ul>
                                    <li className='bold'>Created Date</li>
                                    <ul>
                                        <li>{moment(row.form_data.created_date).format('MMMM Do YYYY, h:mm:ss a')}</li>
                                    </ul>
                                </ul>

                                <ul>
                                    <li className='bold'>Status</li>
                                    <ul>
                                        <li>{row.form_data.status}</li>
                                    </ul>
                                </ul>

                                <ul>
                                    <li className='bold'>Visa Rejected Provisory</li>
                                    <ul >
                                        <li>{row.form_data.visa_rejected_provisory}</li>
                                    </ul>
                                </ul>

                                <ul>
                                    <li className='bold'>Why abroad</li>
                                    <ul>
                                        <li>{row.form_data.why_abroad}</li>
                                    </ul>
                                </ul>

                                <ul>
                                    <li className='bold'>Work Experience</li>
                                    <ul>
                                        <li>{row.form_data.work_experience}</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li className='bold'>Year</li>
                                    <ul>
                                        <li>{row.form_data.year}</li>
                                    </ul>
                                </ul>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={handleCloseModal} className="button-13" role="button" >Close</button>
                        </Modal.Footer>
                    </Modal>
                </div>,
            sortable: true,
            center: true
        },
    ];

    return (
        <React.Fragment>
            <div className='mt-5'>
                <Card style={{ height: '100%' }}>
                    <div className="input-group">
                        <div className="form-outline">
                            <input
                                type="search"
                                id="form1"
                                onChange={(e) => setFilterText(e.target.value)}
                                className="form-control"
                                placeholder="search"
                            />
                        </div>
                    </div>
                    {tableloader === true ?
                        <div className='tableloader'>
                            <img src={loader} alt="" />
                        </div>
                        :
                        <DataTable
                            title="Application Form"
                            columns={columns}
                            data={data?.results}
                            defaultSortFieldId={1}
                            highlightOnHover
                            defaultSortField="name"
                            sortIcon={sortIcon}
                            onChangePage={(page) => setCurrentPage(page)}
                            onChangeRowsPerPage={(newPerPage) => setPerpage(newPerPage)}
                            pagination
                            progressPending={pending}
                            paginationServer
                            paginationTotalRows={totalRows}
                        />}
                </Card>
            </div>
        </React.Fragment>
    )
}

export default ApplicationFormDatatable;

import request from "../api/agent";

const API_ROUTE ="/university/entrance";

export class EntranceService {

    async getEntrance(perpage,currentPage) {
        const response = await request.get(`${API_ROUTE}/get-entrance?limit=${perpage}&page=${currentPage}`);
        return response.data;
    }

    async addOrUpdateEntrance(video) {
        let action = `${API_ROUTE}/create-or-update-entrance`;
        const response = await request.post(action, video);
        return response;
    }

    async deleteEntrance(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-entrance`, {data: { entrance_id: [id] }});
        return response;
    }

    async getEntranceStatus(id){
        const response = await request.post(`${API_ROUTE}/entrance-status-change`,{entrance_id:id})
        return response;
    }

}
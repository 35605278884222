import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dataURLtoFile, dataURLtoPDF } from '../../functions';
import CreateUniversity from './createUniversity';
import UniversityDatatable from './universityDatatable';
import { UniversityService } from '../../../services/universityService';
import { checkUserPermission } from '../../checkPermission';


const universityService = new UniversityService();

const University = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [active, setActive] = useState(true);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [imageData, setImageData] = useState([]);
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [formView, setFormView] = useState('details')
    const [pending, setPending] = React.useState(true)
    const [typeValue, setTypeValue] = useState();


    const [ogimage, setOGImage] = useState();


    function getAllUniversity() {
        active &&  setPending(true)
        universityService.getAllUniversity(currentPage, perpage, filterText, typeValue).then(data => {
            active &&  setPending(false)
            setData(data.data)
            setTotalRows(data.data?.count)
        });
    }

    useEffect(() => {
        getAllUniversity()
    }, [refresh, currentPage, perpage, filterText, typeValue])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    const videoUrl = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
    const validationSchema = Yup.object().shape({
        university_name: Yup.string().required('Required'),
        // place: Yup.string().required('Required'),
        // country: Yup.number().required('Required'),
        // type: Yup.string().required('Required'),
        // estd: Yup.number().required('Required'),
        // ranking: Yup.number().required('Required'),
        // latitude: Yup.string().required('Required'),
        // longitude: Yup.string().required('Required'),
        // about: Yup.string().required('Required'),
        // specialization: Yup.array().min(1).required("Required"),
        // placements: Yup.array().min(1).required("Required"),
        gallery_videos: Yup.array().of(
            Yup.object().shape({
                gallery_videos: Yup.string().matches(videoUrl, "Enter valid url!").required("Required")
            })
        ),
        // applications: Yup.object().shape({

        //     fees: Yup.number().required('Required'),
        //     pre_requsites: Yup.array().of(
        //         Yup.object().shape({
        //             pre_requsites_details: Yup.string().required("Required")
        //         })).required("Required"),
        //         score_requirements: Yup.array().of(
        //         Yup.object().shape({
        //             score_requirement_details: Yup.string().required("Required")
        //         })).required("Required"),
        // }),




    });

    const formik = useFormik({
        initialValues: {
            id: "",
            university_name: "",
            place: "",
            country: "",
            type: "",
            estd: "",
            ranking: "",
            latitude: "",
            longitude: "",
            about: "",
            image: '',
            image_url: '',
            og_image: "",
            logo: '',
            logo_url: '',
            brochure: '',
            brochure_url: '',
            website: '',
            placements: [],
            gallery_image_urls: [],
            gallery_images: [],
            gallery_videos: [{
                gallery_videos: ''
            }],
            // specialization: [],
            applications: {
                // fees: '',
                pre_requsites: [{
                    pre_requsites_details: ''
                }],
                score_requirements: [{
                    score_requirement_details: ''
                }]
            },


        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            // if (values.ranking == "") {
            //     errors.ranking = "Required"
            // } else if (values?.ranking > 5) {
            //     errors.ranking = "Please enter a valid value"
            // }

            if (values.image == "") {
                errors.image = "Required"
            }
            else if (values?.image?.type !== "image/png" && values?.image?.type !== "image/jpg" && values?.image?.type !== "image/jpeg") {
                errors.image = "Only jpg/jpeg and png files are allowed!"
            } else {
                let kb = values.image.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.image = "images should be less than 2 MB"
                }
            }
            console.log(values.logo);
            if (values.logo == "") {
                errors.logo = "Required"
                console.log(values.logo);

            }

            else if (values?.logo?.type !== "image/png" && values?.logo?.type !== "image/jpg" && values?.logo?.type !== "image/jpeg") {
                errors.logo = "Only jpg/jpeg and png files are allowed!"
                console.log(values?.logo?.type)
                console.log(errors.logo)

            } else {
                let kb = values.logo.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                    errors.logo = "Logo should be less than 2 MB"
                }
            }

            console.log(values.brochure)

            if (values.brochure === "") {

            } else if (values.brochure.type !== "application/pdf") {
                errors.brochure = "Only PDFs are valid"
            }
            else {
                let kb = values.brochure.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 5) {
                    errors.brochure = "brochure should be less than 5 MB"
                }
            }
            // if (values.website !== "URL") {
            //     errors.website = "Please enter a valid url"
            //     console.log(errors.website);
            // }

            return errors;
        },
        onSubmit: (values) => {
            setPending(true)
            setIsloader(true)
            let gallery_videos = [];
            values?.gallery_videos?.map((video, index) => {
                gallery_videos.push(video?.gallery_videos)
            })

            let formData = new FormData();
            if (values.id) {
                formData.append("university_id", values.id ?? "");
            }
            formData.append("university_name", values.university_name);
            formData.append("place", values.place);
            formData.append("country", values?.country);
            formData.append("type", values?.type);
            formData.append("estd", values?.estd);
            formData.append("ranking", values?.ranking);
            formData.append("og_image", ogimage ?? "");
            formData.append("latitude", values?.latitude);
            formData.append("website", values?.website);
            formData.append("longitude", values?.longitude);
            formData.append("about", values?.about);
            formData.append("image", values?.image);
            formData.append("logo", values?.logo);
            formData.append("brochure", values?.brochure);
            values?.gallery_images?.map(image => {
                formData.append("gallery_images", image);
            })
            formData.append("gallery_videos", JSON.stringify(gallery_videos));
            formData.append("applications", JSON.stringify(values?.applications));
            // values?.specialization?.map(specialization => {
            //     formData.append("specialization", specialization?.value);
            // })
            values?.placements?.map(placement => {
                formData.append("placements", placement?.value);
            })
            universityService.addOrUpdateUniversity(formData).then((response) => {
                if (response && response.status) {
                    setIsloader(false)
                    setFormView("details")
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        course_name: response?.response?.data?.errors?.title,
                        course_type: response?.response?.data?.errors?.course_type,
                        image: response?.response?.data?.errors?.image,
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });


    const handleEdit = async (item) => {
        let images = []

        item?.gallery?.images?.map(async image => {
            let data = await dataURLtoFile(image.images);
            images.push(data)
        })

        let website = "";
        if (item.website === "") {
            return;
        } else if (item?.website.includes("https://") || item?.website.includes("http://") ) {
            console.log("else if")
            console.log(item.website)
            website = item.website;
        } else {
            console.log("sfasdjfgas")
            console.log(item.website)
            website = "https://" + item.website;
        }

        formik.setValues({
            id: item.id,
            university_name: item?.university_name,
            place: item?.place,
            country: item?.country,
            type: item?.type_id,
            estd: item?.estd,
            ranking: item?.ranking,
            latitude: item?.latitude,
            longitude: item?.longitude,
            website: website,
            about: item?.about,
            image: await dataURLtoFile(item?.image),
            og_image_url: item?.og_image,
            image_url: item?.image,
            logo: await dataURLtoFile(item?.logo),
            logo_url: item?.logo,
            brochure: item?.brochure ? await dataURLtoPDF(item?.brochure) : "",
            brochure_url: item?.brochure,
            gallery_image_urls: item?.gallery?.images?.map(image => { return image.images }),
            gallery_images: images,
            gallery_videos: item?.gallery?.videos?.length ? item?.gallery?.videos?.map(video => {
                return {
                    gallery_videos: video?.videos
                }
            }) : [{
                gallery_videos: ''
            }],
            // specialization: item?.specialization.length ? item?.specialization?.map(value => {
            //     return {
            //         value: value?.value,
            //         label: value?.key
            //     }
            // }) : [],
            placements: item?.placements?.length ? item?.placements?.map(value => {
                return {
                    value: value?.value,
                    label: value?.label
                }
            }) : [],
            applications: item?.applications.length ? {
                // fees: item?.applications && item?.applications[0] && item?.applications[0].fees,
                pre_requsites: item?.applications[0]?.pre_requsites.length ? item?.applications[0]?.pre_requsites?.map(value => {
                    return {
                        pre_requsites_details: value?.details,
                    }
                }) : [{
                    pre_requsites_details: ''
                }],
                score_requirements: item.applications[0].score_requirements.length ? item?.applications[0]?.score_requirements?.map(value => {
                    return {
                        score_requirement_details: value?.details,
                    }
                }) : [{
                    score_requirement_details: ''
                }],
            } : {
                // fees: '',
                pre_requsites: [{
                    pre_requsites_details: ''
                }],
                score_requirements: [{
                    score_requirement_details: ''
                }]
            },
        })
        setFormView("details")
        setScroll(preVal => !preVal)
    }
    
    const markAllFieldsAsTouched = () => {
        const fieldKeys = Object.keys(formik.values);
        const touchedFields = fieldKeys.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});
        formik.setTouched(touchedFields);
      };

    return (
        <React.Fragment >
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Create University</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Create University</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create Universities") && <CreateUniversity markAllFieldsAsTouched={markAllFieldsAsTouched} image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} formView={formView} setFormView={setFormView} setImage={setImage} formRef={formRef} setOGImage={setOGImage} />}
                    </React.Fragment>
                    <React.Fragment>
                        <UniversityDatatable setActive={setActive} currentPage={currentPage} data={data} setTypeValue={setTypeValue} pending={pending} filterText={filterText} setFilterText={setFilterText} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} setCurrentPage={setCurrentPage} setPerpage={setPerpage} totalRows={totalRows} />
                    </React.Fragment>
                </main>
            </>
        </React.Fragment>
    )
}

export default University;

import request from "../api/agent";
const API_ROUTE = "course/course";

export class CourseService {
    async addOrUpdateCourse(course) {
        let action = `${API_ROUTE}/course-creation`;
        const response = await request.post(action, course);
        return response;
    }

    async getAllCourses(search, typeValue, perpage, currentPage) {
        const serchValue = search ?? ""
        const course_type = typeValue ? typeValue : "";

        if (perpage) {
            const response = await request.get(`${API_ROUTE}/course-listing?search=${serchValue}&course_type=${course_type}&limit=${perpage}&page=${currentPage}`);
            return response.data;
        }
        else {
            const response = await request.get(`${API_ROUTE}/course-listing`)
            return response.data;
        }
    }



    async deleteCourse(id) {
        const response = await request.delete(`${API_ROUTE}/course-delete`, {
            data: { course_id: [id] }
        });
        return response;
    }
    async getStatus(id) {
        const response = await request.post(`${API_ROUTE}/course-status`, { course: id })
        return response;
    }


}
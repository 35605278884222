import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import loader from "../../../assets/images/loader.gif";

const CreateAboard = ({
  formik,
  isloader,
  toaster,
  setToaster,
  setImage,
  image,
  formRef,
  imageError,
}) => {
  const [types, setTypes] = useState([]);

  const [pageSize, setPageSize] = useState("25");
  const [current, setCurrent] = useState(1);

  return (
    <div>
      <React.Fragment>
        <div className="form_sec">
          <Form ref={formRef} onSubmit={formik.handleSubmit}>
            <Row className="mb-2">
              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={formik.values?.title}
                  onChange={formik.handleChange}
                  isValid={formik.touched.title && !formik.errors.title}
                  isInvalid={!!formik.errors.title}
                  placeholder="eg. Exploring Colleges"
                  maxLength={100}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                <Form.Label>Icon</Form.Label>
                <div className="file_wrapper">
                  <Form.Control
                    type="file"
                    name="logo"
                    accept="image/*"
                    values={formik.values.logo}
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      formik.setValues({
                        ...formik.values,
                        logo: e.target.files[0],
                        image_url: URL.createObjectURL(e.target.files[0]),
                      });
                    }}
                    isValid={formik.touched.logo && !formik.errors.logo}
                    isInvalid={!!formik.errors.logo}
                  />
                  {formik.values?.image_url && (
                    <div className="img-wrapper">
                      <img
                        src={formik.values?.image_url}
                        alt="course"
                        style={{ height: "100px" }}
                        className="img-fluid mt-2"
                      />
                    </div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.logo}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Row>

            <div className="group">
              {isloader == true ? (
                <div className="loader">
                  <img src={loader} alt="" />
                </div>
              ) : (
                <Button type="submit" className="save-btn me-4">
                  Save
                </Button>
              )}
            </div>
          </Form>
        </div>
      </React.Fragment>
    </div>
  );
};
export default CreateAboard;

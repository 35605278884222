import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import loader from "../../../assets/images/loader.gif";

const CountryCreate = ({ formik, isloader, toaster, setToaster, setImage, flag, formRef, imageError }) => {
    const onChangeImage = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            formik.setValues({ ...formik.values, image: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) })
        }
    }
    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="country_name"
                                value={formik.values?.country_name}
                                onChange={formik.handleChange}
                                isValid={formik.touched.country_name && !formik.errors.country_name}
                                isInvalid={!!formik.errors.country_name}
                                placeholder="Name"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.country_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                            <Form.Label>Flag</Form.Label>
                            <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={!!formik.errors.image}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }
                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>

                            </div>

                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="country_code"
                                value={formik.values?.country_code}
                                onChange={formik.handleChange}
                                isValid={formik.touched.country_code && !formik.errors.country_code}
                                isInvalid={!!formik.errors.country_code}
                                placeholder="eg. +91"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.country_code}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Currency logo</Form.Label>
                            <Form.Control
                                type="text"
                                name="currency_logo"
                                value={formik.values?.currency_logo}
                                onChange={formik.handleChange}
                                isValid={formik.touched.currency_logo && !formik.errors.currency_logo}
                                isInvalid={!!formik.errors.currency_logo}
                                placeholder="eg. ₹"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.currency_logo}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Currency Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="currency_code"
                                value={formik.values?.currency_code}
                                onChange={formik.handleChange}
                                isValid={formik.touched.currency_code && !formik.errors.currency_code}
                                isInvalid={!!formik.errors.currency_code}
                                placeholder="eg. INR"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.currency_code}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

         

         
export default CountryCreate;

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import BasicSweetCallback from "../../SweetAlertCallback";
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from "../../../assets/images/loader.gif";
import { ServiceEnquiryService } from "../../../services/sevrviceEnquiryService";
import { checkUserPermission } from "../../checkPermission";
import { BsFilter } from "react-icons/bs";
import { MdClose } from 'react-icons/md';
import moment from "moment";




const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
const serviceenquiryservice = new ServiceEnquiryService();

const DatatableService = ({
  typeValue,
  setTypeValue,
  setContactValue,
  tableloader,
  data,
  refresh,
  setRefresh,
  setPerpage,
  setCurrentPage,
  totalRows,
  setFilterText,
  filterText,
  pending,
  currentPage
}) => {

  const [selected, setSelected] = useState({})
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );

  const handleCloseModal = () => {

    setShowModal(false);

  }
  const handleShowModal = (row) => {

    setShowModal(true);
    setSelected(row)
  }


  const onChangeStatus = (id) => {
    serviceenquiryservice.setContactStatus({ enquiry_id: id }).then((response) => {
      setRefresh(!refresh);
    });
  };

  const columns = [
    {
      name: "SL.No",
      key: "index",
      cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
      grow: 0.6,
    },
    {
      name: "Name",
      selector: (row) => row.user_name,
      sortable: true,
      grow: 0.6,
      reorder: true
    },
    {
      name: "Phone no",
      selector: (row) => row.phone_no,
      sortable: true,
      grow: 0.6,
      reorder: true
    },
    {
      name: 'Date / Time',
      selector: row => moment(row.created_date).format("YYYY-DD-MM h:mm:ss a"),
      sortable: true,
      center: false,
    },
    {
      name: "Message",
      grow: 0.6,
      selector: (row) => row.message,
      cell: (row) =>
        <div className='d-flex align-items-center  '>
          <div >
            <div onClick={() => handleShowModal(row)}>
              <span className="svg-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              </span>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} >
              <Modal.Body>
                <div className='close text-end'>
                  <MdClose className='close_icon' onClick={handleCloseModal} />
                </div>
                {selected?.message}
                {/* {row.message} */}
              </Modal.Body>
            </Modal>
          </div>
        </div>,
      sortable: true,
      reorder: true
    },
    {
      name: "Enquiry Type",
      grow: 1,
      selector: (row) => row.enquiry_type,
      sortable: true,
      reorder: true
    },

    {
      name: "Contacted",
      grow: 0.6,
      selector: (row) => (
        <div class="form-check form-switch">
          <input
            onClick={() => onChangeStatus(row?.id)}
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={row.is_contacted}
          />
          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
        </div>
      )
      ,
      sortable: true,
      reorder: true,
    },
    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <div class="form-check form-switch">
    //       <input
    //         onClick={() => {
    //           serviceenquiryservice
    //             .setServiceStatus(row.id)
    //             .then((response) => {
    //               console.log(response);
    //               setRefresh(!refresh);
    //             });
    //         }}
    //         class="form-check-input"
    //         type="checkbox"
    //         id="flexSwitchCheckDefault"
    //         checked={row.is_active}
    //       />
    //       <label class="form-check-label" for="flexSwitchCheckDefault"></label>
    //     </div>
    //   ),
    //   sortable: true,
    //   reorder: true
    // },

    checkUserPermission("Delete Service Enquiry") && {
      id: "action",
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <BasicSweetCallback
            onDelete={serviceenquiryservice.deleteService}
            deleteId={row.id}
            title=" Service Enquiry"
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </div>
      ),
      sortable: true,
      grow: 0.6,
    }
  ];
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  // const [data, setData] = React.useState([]);
  const [selectedOption, setSelectedOption] = useState(null);


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChange = () => {
    setSelectedRows(selectedRows);
  };

  const handleRowClicked = (row) => {
    console.log(`${row.name} was clicked!`);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage);
  };


  return (
    <React.Fragment>
      <div className="mt-5">
        <Card style={{ height: "100%" }}>
          <div className="head-filter">
            <div className="filter">
              <div className="fltr">

                <select onChange={(e) => setTypeValue(e.target.value)}>
                  <option value='' >SERVICES</option>
                  <option value='gs' >GET STARTED</option>
                  {/* <option value='sp' >SCHOLARSHIP</option> */}
                  <option value='cg' >CAREER GUIDANCE</option>
                  <option value='eg' >ALL-ENCOMPASSING GUIDANCE</option>
                  <option value='aa' >ADMISSION ASSISTANCE</option>
                  <option value='vg' >VISA GUIDANCE</option>
                  <option value='dr' >PRE-DEPARTURE BRIEFING</option>
                  <option value='al' >POST-ARRIVAL ASSISTANCE</option>
                </select>
                <BsFilter className='filter_icon' />
              </div>
            </div>
            <div className="fltr">
              <select onChange={(e) => setContactValue(e.target.value)}>
                <option value=''>Contact status</option>
                <option value='true' >Contacted</option>
                <option value='false' >Not Contacted</option>
              </select>
            </div>
            <div className="">
              <div className="form-outline">
                <input
                  type="search"
                  id="form1"
                  onChange={(e) => setFilterText(e.target.value)}
                  className="form-control"
                  placeholder="search"
                />
              </div>
            </div>
          </div>
          {tableloader === true ? (
            <div className="tableloader">
              <img src={loader} alt="" />
            </div>
          ) : (
            <DataTable
              title="Service Enquiry"
              columns={columns}
              data={data.results}
              // defaultSortFieldId={1}
              // selectableRows={selectableRows}
              highlightOnHover
              defaultSortField="name"
              // actions={actions}
              // contextActions={contextActions(deleteAll)}
              sortIcon={sortIcon}
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={selectProps}
              onSelectedRowsChange={handleChange}
              clearSelectedRows={toggleCleared}
              onRowClicked={handleRowClicked}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={handlePerRowsChange}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              progressPending={pending}
            // expandableRows={expandableRows}
            />
          )}
        </Card>
      </div>
    </React.Fragment>
  );
};

export default DatatableService;

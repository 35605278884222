import request from "../api/agent";

const API_ROUTE = "/enquiry/enquiry";

export class UniversitycourseEnquiryService {

    async getuniversitycourseEnquiry(limit, page, search, contactValue) {
        const serchValue = search ?? "";
        const is_contacted = contactValue ? contactValue : "";
        const response = await request.get(`${API_ROUTE}/list-university-course-enquiry?page=${page}&limit=${limit}&search=${serchValue}&is_contacted=${is_contacted}`);
        return response.data;
    }
    async deleteEnquiry(id) {
        const response = await request.delete(`${API_ROUTE}/enquiry-delete`, {
            data: { enquiry_id: id }
        });
        return response;
    }
    async enquiryStatus(data) {
        let action = `${API_ROUTE}/is-contacted-or-not`;
        const response = await request.post(action, data);
        return response;
    }


}
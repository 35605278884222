import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';

const TestimonialCreate = ({ formik, isloader, formRef }) => {

    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    {/* <Row className="mb-2"> */}
                        {/* <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Color</Form.Label>
                            <Form.Control
                                type="text"
                                name="color"
                                value={formik.values?.color}
                                onChange={formik.handleChange}
                                isValid={formik.touched.color && !formik.errors.color}
                                isInvalid={!!formik.errors.color}
                                placeholder="Color"
                                maxLength={100}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.color}</Form.Control.Feedback>
                        </Form.Group> */}
                        
                    {/* </Row> */}
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Testimonial</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="text"
                                value={formik.values?.text}
                                onChange={formik.handleChange}
                                isValid={formik.touched.text && !formik.errors.text}
                                isInvalid={!!formik.errors.text}
                                placeholder="Testimonial"
                                style={{ height: '100px' }}
                                
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.text}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default TestimonialCreate;
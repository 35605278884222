// eslint-disable-next-line no-undef
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import { CourseTypeService } from "../../../services/CourseTypeService";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CreateUniversityType from './createUniversityType';
import UniversityTypeDatatable from './unversityTypeDatatable';
import { UniversityTypeService } from '../../../services/UniversityTypeService';
import { checkUserPermission } from '../../checkPermission';

const universityTypeService = new UniversityTypeService();

const UniversityTypes = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [pending, setPending] = React.useState(true)
    const [filterText, setFilterText] = React.useState('');
    const [scroll, setScroll] = useState(false);
   const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
  
    const [selectedRows, setSelectedRows] = React.useState([]);
    const handleChange = () => {
      setSelectedRows(selectedRows);
    };
  
    const handleRowClicked = (row) => {
      console.log(`${row.name} was clicked!`);
    };
    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage);
      };
    
    

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])

    async function getUniversityTypes() {
        setPending(true)
        const data = await universityTypeService.getAllUniversityTypes(filterText,currentPage,perpage);
        setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getUniversityTypes()
    }, [refresh,filterText,currentPage,perpage])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scroll])


    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            title: "",
        },
        validationSchema,
        
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.id) {
                formData.append("instance_id", values.id ?? "");
            }
            formData.append("title", values.title);
            

            universityTypeService.addOrUpdateUniversityType(formData).then((response) => {
               
                if (response && response.status) {
                    setIsloader(false)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    // getCourseTypes()
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        name: response?.response?.data?.errors?.name,
                       
                    });
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal => !preVal)
        // setImage(await dataURLtoFile(item.image))
        formik.setValues({
            id: item.id,
            title: item.title,
        })
      
        // console.log(item);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>University Type</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">University Type</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>
                        {checkUserPermission("Create University Type") && <CreateUniversityType image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
                    </React.Fragment>
                    <React.Fragment>
                        <UniversityTypeDatatable currentPage={currentPage} handlePerRowsChange={handlePerRowsChange} handleRowClicked={handleRowClicked} totalRows={totalRows} handleChange={handleChange} setPerpage={setPerpage} setCurrentPage={setCurrentPage} pending={pending}   filterText={filterText} setFilterText={setFilterText} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default UniversityTypes;
// eslint-disable-next-line no-undef
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import AppointmmentDatatable from './appointmentDatatable';
import { AppointmentService } from '../../../services/AppointmentService';

const appointmentService = new AppointmentService();

const Appointment = () => {

    const [active, setActive] = useState(true);

    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = React.useState(0);
    const[typeValue,setTypeValue]=useState();
    const[pending,setPending]=React.useState(true);
    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };

    async function getAllAppointments() {
        active && setPending(true)
        const data = await appointmentService.getAllAppointments(currentPage, perpage,filterText,typeValue);
        active && setPending(false)
        setData(data.data)
        setTotalRows(data.data.count)
    }
    useEffect(() => {
        getAllAppointments()
    }, [currentPage, perpage, refresh, filterText,typeValue])






    return (
        <React.Fragment>

            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Appointment</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Appointment</li>
                            </ol>
                        </nav>
                    </div>

                    <React.Fragment>
                        <AppointmmentDatatable
                            data={data}
                            setActive={setActive}
                            handlePerRowsChange={handlePerRowsChange}
                            setCurrentPage={setCurrentPage}
                            totalRows={totalRows}
                            setFilterText={setFilterText}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            filterText={filterText}
                            setTypeValue={setTypeValue}
                            pending={pending}
                            currentPage={currentPage}
                             />
                    </React.Fragment>

                </main>
            </>
        </React.Fragment>
    )
}

export default Appointment;
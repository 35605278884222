import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaqService } from '../../../services/FaqService';
import FaqCreate from './FaqCreate';
import FaqDatatable from './FaqDatatable';
import { checkUserPermission } from '../../checkPermission';

const Faq = () => {

  const faqService = new FaqService();

  const formRef = useRef(null);
  const [isloader, setIsloader] = useState(false);
  const [toaster, setToaster] = useState("");
  const [image, setImage] = useState();
  const [data, setData] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [scroll, setScroll] = useState(false);
  const [tableloader, setTableloader] = useState(true);
 const [pending, setPending] = React.useState(true)
  const [filterText, setFilterText] = React.useState('');

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [scroll])


  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage)
  };

  async function getFaq() {

    setPending(true)
    const data = await faqService.getFaq(perpage, currentPage,filterText);
    setPending(false)
    setData(data.data)
   
    setTotalRows(data.data.count)
  }

  useEffect(() => {
    

    getFaq()
    
  }, [refresh, currentPage, perpage,filterText])

  const validationSchema = Yup.object().shape({
    question: Yup.string()
      .required('Required'),
    answer: Yup.string()
      .required('Required'),
  });



  const formik = useFormik({
    initialValues: {
      id: "",
      question: "",
      answer: "",
      is_active: true
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      setIsloader(true)
      let formData = new FormData();
      if (values.id) {
        formData.append("faq_id", values.id ?? "");
      }
      formData.append("question", values.question);
      formData.append("answer", values.answer);

      faqService.addOrUpdateFaq(formData).then((response) => {
        if (response && response.status) {
          setIsloader(false)
          console.log(response?.data?.data)
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT
          });
          setRefresh(!refresh)
          formik.resetForm()
          formRef.current.reset();
        }
        else {
          formik.setErrors({
            question: response?.response?.data?.errors?.title,
            answer: response?.response?.data?.errors?.description,
          });
          console.log(response?.response?.data?.errors);
          setIsloader(false)
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    },
  });
  const handleEdit = async (item) => {setScroll(preVal=>!preVal)
    // setImage(await dataURLtoFile(item.image))
    formik.setValues({
      id: item.id,
      question: item.question,
      answer: item.answer
    })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>FAQ</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">FAQ</li>
              </ol>
            </nav>
          </div>
          <React.Fragment>
            {checkUserPermission("Create FAQ") && <FaqCreate image={image} formik={formik} isloader={isloader} toaster={toaster} setToaster={setToaster} setImage={setImage} formRef={formRef} />}
          </React.Fragment>
          <React.Fragment>
  
            <FaqDatatable pending={pending} perpage={perpage} currentPage={currentPage} filterText={filterText} setFilterText={setFilterText}  data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} handlePerRowsChange={handlePerRowsChange} setCurrentPage={setCurrentPage} totalRows={totalRows} formik={formik} />
          </React.Fragment>
        </main>
      </>
    </React.Fragment>
  )
}

export default Faq
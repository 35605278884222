import request from "../api/agent";
const API_ROUTE = "university/university-type";

export class UniversityTypeService {
    async addOrUpdateUniversityType(data) {
        let action = `${API_ROUTE}/create-or-update-university-type`;
        const response = await request.post(action, data);
        return response;
    }



    async getAllUniversityTypes(search, perpage, currentPage) {

        if (perpage) {
            const serchValue = search ?? ""
            const response = await request.get(`${API_ROUTE}/get-all-university-type?search=${serchValue}&page=${perpage}&limit=${currentPage}`);
            return response.data;
        } else {
            const response = await request.get(`${API_ROUTE}/get-all-university-type`);
            return response.data;
        }
    }

    // async getAllUniversityListTypes() {
    //     const response = await request.get(`${API_ROUTE}/get-all-university-type`);
    //     return response.data;
    // }

    async deleteUniversityType(id) {
        const response = await request.delete(`${API_ROUTE}/destroy-university-type`, {
            data: { ids: [id] }
        });
        return response;
    }
    async Status(id) {
        const response = await request.put(`${API_ROUTE}/university-type-status-change`, { id: id })
        return response;
    }

}
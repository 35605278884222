import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import loader from '../../../assets/images/loader.gif';

const CreateBannerFeed = ({ formik, isloader, toaster, setToaster, setImage, image, formRef, imageError }) => {
    const onChangeImage = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            formik.setValues({ ...formik.values, image: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) })
        }
    }

    return (

        <React.Fragment>
            <div className='form_sec'>
                <Form ref={formRef} onSubmit={formik.handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                        <Form.Label>Banner Image</Form.Label>

                        <div className="file_wrapper">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    values={formik.values.image}
                                    onChange={(e) => {
                                        onChangeImage(e)
                                    }
                                    }
                                    isValid={formik.touched.image && !formik.errors.image}
                                    isInvalid={formik.touched.image && formik.errors.image}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.values?.image_url &&
                                    <div className='img-wrapper'>
                                        <img src={formik.values?.image_url} alt='course' style={{ height: '100px' }} className='img-fluid mt-2' />
                                    </div>
                                }

                                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        
                    </Row>
                    
                    <div className="group">
                        {isloader == true ?
                            <div className='loader'>
                                <img src={loader} alt="" />
                            </div>
                            :
                            <Button type="submit" className='save-btn me-4'>Save</Button>
                        }
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default CreateBannerFeed;
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import BasicSweetCallback from "../../SweetAlertCallback";
import { UniversityCourseService } from "../../../services/UniversityCourseService";
import { Tooltip, OverlayTrigger, Form, Button, Modal } from "react-bootstrap";

import loader from '../../../assets/images/loader.gif';
import { checkUserPermission } from "../../checkPermission";
import { useEffect } from "react";
import { useCallback } from "react";
import BasicSweetCallback2 from "../../sweetAlertMultipleDelete";
const universityCourseService = new UniversityCourseService();

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
const actions = (
  <IconButton color="primary">
    <Add />
  </IconButton>
);
const contextActions = (deleteHandler) => (
  <IconButton color="secondary" onClick={deleteHandler}>
    <Delete />
  </IconButton>
);

const UniversityCourseDatatable = ({
  tableloader,
  setFilterText,
  filterText,
  data,
  handleEdit,
  refresh,
  setRefresh,
  setActive,
  setPerpage,
  setCurrentPage,
  totalRows,
  currentPage,
  pending,
  bulkformik,
  bulkLoader,
  bulkSucess
}) => {

  const [modalShow, setModalShow] = React.useState(false);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [rowsId, setRowsId] = useState([]);

  const handleChangefiles = (e, name, url) => {
    bulkformik.setValues({ ...bulkformik.values, [name]: e.target.files[0], [url]: URL.createObjectURL(e.target.files[0]) })
  }

  const handleRowSelected = state => {
    setRowsId(state.selectedRows.map(row => row.id))
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  }

  console.log(rowsId)

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );


  useEffect(() => {
    setModalShow(false)
  }, [bulkSucess])




  const columns = [
    {
      name: "SL.No",
      key: "index",
      // width: "190px",
      cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
    },
    {
      name: "University",
      // selector: (row) => row?.university?.university_name,
      cell: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row?.university?.university_name}>
          {row?.university?.university_name}
        </div>,
      sortable: true,
      width: "190px",
      reorder: true,
    },
    {
      name: "Course",
      // selector: (row) => row?.course?.name,
      cell: (row) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row?.course?.name}>
          {row?.course?.name}
        </div>,
      sortable: true,
      width: "190px",
      reorder: true,
    },
    {
      name: "Fees",
      selector: (row) => `${row.currency_code} ${row.fees} / Year`,
      sortable: true,
      width: "190px",
      reorder: true,
    },
    {
      name: 'Status',
      // width: "190px",
      selector: row => {
        return <div className="form-check form-switch"  >
          <input onClick={() => {
            universityCourseService.setStatus(row.id).then((response) => {
              setActive(false)
              setRefresh(!refresh)
            })
          }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_active} />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
        </div>
      }
      ,
      sortable: true,
      reorder: true,
    },
    (checkUserPermission("Edit University Course") || checkUserPermission("Delete University Course")) && {
      id: "action",
      width: "190px",
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center">
          {checkUserPermission("Edit University Course") && <Link
            onClick={() => handleEdit(row)}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span className="svg-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </OverlayTrigger>
          </Link>}
        </div>
      ),
      sortable: true,
    },
  ];

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);


  const handlePerRowsChange = async (newPerPage) => {
    setPerpage(newPerPage);
  };

  return (
    <React.Fragment>
      <div className="mt-5">
        <Card style={{ height: "100%" }}>
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            // {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >

            <Modal.Body closeButton>
              <div className='modal_content'>
                <Form
                  onSubmit={bulkformik.handleSubmit}>
                  <div className='bulk_upload'>Bulk Upload </div>
                  <Form.Group md="6" controlId="validationCustom04">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      style={{
                        width: "100%",
                        borderRadius: "0.25rem 0 0 0.25rem"
                      }}
                      name="country"
                      value={bulkformik.values?.country}
                      onChange={bulkformik.handleChange}
                      onBlur={bulkformik.handleBlur}
                      isValid={bulkformik.touched.country && !bulkformik.errors.country}
                      isInvalid={bulkformik.touched.country && bulkformik.errors.country}
                      maxLength={100}
                    />
                    <Form.Control.Feedback type="invalid">{bulkformik.errors.country}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label>Bulk Upload</Form.Label>
                  <Form.Group md="6" controlId="formFile" className="mb-3" style={{ display: "flex", width: "600px" }}>
                    <div style={{ width: "250px" }}>
                      <Form.Control
                        type="file"
                        name="file"
                        style={{
                          width: "100%",
                          borderRadius: "0.25rem 0 0 0.25rem"
                        }}
                        accept="application/pdf"
                        onChange={(e) => { handleChangefiles(e, 'file', 'file_url') }}
                        onBlur={bulkformik.handleBlur}
                        isValid={bulkformik.touched.file && !bulkformik.errors.file}
                        isInvalid={bulkformik.touched.file && bulkformik.errors.file}
                      />
                      <Form.Control.Feedback type="invalid">{bulkformik.errors.file}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  {bulkLoader ?
                    <div className='loader'>
                      <img src={loader} alt="" />
                    </div>
                    :
                    <div>
                      <Button type="submit" className='save-btn me-4'>Save</Button>
                    </div>}
                </Form>
              </div>
            </Modal.Body>

            {/* <Button >Close</Button> */}

          </Modal>
          <div className='bulk_button'>
            <Button className='save-btn me-4' onClick={() => setModalShow(true)}>
              Bulk Upload
            </Button>
          </div>
          <div className="input-group">
            <div className="form-outline">
              <input
                type="search"
                id="form1"
                onChange={(e) => setFilterText(e.target.value)}
                className="form-control"
                placeholder="search"
              />
            </div>
          </div>
          {(rowsId.length !== 0) && checkUserPermission("Delete University Course") &&
            <BasicSweetCallback2
              onDelete={universityCourseService.deleteUniversityCourse}
              deleteId={rowsId}
              title="Selected Data"
              setRefresh={setRefresh}
              refresh={refresh}
              setRowsId={setRowsId}
              handleClearRows={handleClearRows}
            />}
          <DataTable
            title="University Course"
            columns={columns}
            data={data?.results}
            defaultSortFieldId={1}

            // selectableRows={selectableRows}
            highlightOnHover
            defaultSortField="name"
            selectableRows
            onSelectedRowsChange={handleRowSelected}
            sortIcon={sortIcon}
            selectableRowsComponentProps={selectProps}
            onChangePage={(page) => setCurrentPage(page)}
            onChangeRowsPerPage={handlePerRowsChange}
            clearSelectedRows={toggledClearRows}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            progressPending={pending}

          />

        </Card>
      </div>
    </React.Fragment>
  );
};

export default UniversityCourseDatatable;

import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import BasicSweetCallback from '../../SweetAlertCallback';
import { CourseService } from '../../../services/CourseService';
import { UserService } from '../../../services/UserService';
import { ResentIntakeService } from '../../../services/ResentIntakeService';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import loader from '../../../assets/images/loader.gif';
import { checkUserPermission } from '../../checkPermission';

const resentIntakeService = new ResentIntakeService();

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
const actions = (
    <IconButton color="primary">
        <Add />
    </IconButton>
);
const contextActions = deleteHandler => (
    <IconButton color="secondary" onClick={deleteHandler}>
        <Delete />
    </IconButton>
);





const ResentIntakeDatatable = ({ currentPage, setCurrentPage, pending, totalRows, setFilterText, filterText, data, handleEdit, refresh, setPerpage, setRefresh, formik }) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit
        </Tooltip>
    );

    const columns = [
        {
            name: "SL.No",
            key: "index",
            grow: 0.3,
            cell: (row, index) => ((currentPage * 10) + (index + 1)) - 10,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 0.6,
            reorder: true,
        },
        {
            name: 'Country',
            selector: row => row.country?.country_name,
            sortable: true,
            grow: 0.6,
            reorder: true,
        },

        {
            id: 'image',
            name: 'Image',
            grow: 0.6,
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    <img height="90px" width="90px" alt={row.name} src={row.image} />
                </div>,
            sortable: true,
        },
        {
            name: 'Status',
            grow: 0.6,
            selector: row => <div class="form-check form-switch"  >
                <input onClick={() => {
                    resentIntakeService.updateResentIntakeStatus(row.id).then((response) => {
                        console.log(response)
                        setRefresh(!refresh)
                    })
                }} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={row.is_active} />
                <label class="form-check-label" for="flexSwitchCheckDefault"></label>
            </div>
            ,
            sortable: true,
            reorder: true,
        },
        (checkUserPermission("Delete Recent Intake") || checkUserPermission("Edit Recent Intake")) && {
            id: 'action',
            name: 'Actions',
            cell: (row) =>
                <div className='d-flex align-items-center'>
                    {checkUserPermission("Edit Recent Intake") && <Link onClick={() => handleEdit(row)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                            <span className="svg-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </OverlayTrigger>
                    </Link>}
                    {checkUserPermission("Delete Recent Intake") && <BasicSweetCallback onDelete={resentIntakeService.deleteResentIntake} deleteId={row.id} title="Recent Intake " setRefresh={setRefresh} refresh={refresh} formik={formik} />}
                </div>,
            sortable: true,

        },
    ];

    const [selectedRows, setSelectedRows] = React.useState([]);
    const [toggleCleared, setToggleCleared] = React.useState(false);
    // const [data, setData] = React.useState([]);
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };


    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };

    return (
        <React.Fragment>
            <div className='mt-5'>
                <Card style={{ height: '100%' }}>
                    <div className="input-group">
                        <div className="form-outline">
                            <input
                                type="search"
                                id="form1"
                                onChange={(e) => setFilterText(e.target.value)}
                                className="form-control"
                                placeholder="search"
                            />
                        </div>
                    </div>

                    <DataTable
                        title="Recent Intake "
                        columns={columns}
                        data={data?.results}
                        defaultSortFieldId={1}
                        // selectableRows={selectableRows}
                        highlightOnHover
                        defaultSortField="name"
                        // actions={actions}
                        // contextActions={contextActions(deleteAll)}
                        sortIcon={sortIcon}
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        onSelectedRowsChange={handleChange}
                        clearSelectedRows={toggleCleared}
                        onRowClicked={handleRowClicked}
                        onChangePage={(page) => setCurrentPage(page)}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        progressPending={pending}
                    // expandableRows={expandableRows}
                    />
                </Card>
            </div>
        </React.Fragment>
    )
}

export default ResentIntakeDatatable;
import axios from "axios";
import request from "../api/agent";
const API_ROUTE = "enquiry/get-started";

export class ServiceEnquiryService {
  async getAllServiceEnquiry(perpage, currentPage, search, typeValue, contactValue) {
    const serchValue = search ?? "";
    //typeValue? enquiry_type:"";
    const enquiry_type = typeValue ? typeValue : "";
    const is_contacted = contactValue ? contactValue : "";
    if (perpage) {
      const response = await request.get(
        `${API_ROUTE}/get-started-other-listing?page=${perpage}&limit=${currentPage}&search=${serchValue}&enquiry_type=${enquiry_type}&is_contacted=${is_contacted}`
      );
      return response.data;
    } else {
      const response = await request.get(`${API_ROUTE}/get-started-other-listing`);
      return response.data;
    }
  }

  async deleteService(id) {
    const response = await request.delete(`${API_ROUTE}/destroy-get-started`, {
      data: { get_started_id: id }
    });

    return response;
  }
  async setServiceStatus(id) {
    const response = await request.post(
      `${API_ROUTE}/active-or-inactive-get-started`,
      { get_started_id: id }
    );
    return response;
  }
  async setContactStatus(data) {
    let action = `${API_ROUTE}/user-contacted`;
    const response = await request.post(action, data);
    return response;
  }

  //Notification api
  async setNotificationCount(enquiry_type) {
    let action = `/enquiry/enquiry/read-enquiry-notification`;
    const response = await request.post(action,{enquiry_type});
    return response;
  }

}

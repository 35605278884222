
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../main/header';
import Sidebar from '../../main/sidebar';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';


import { dataURLtoFile } from '../../functions';
import { PlacementService } from '../../../services/PlacementServices';
import CreatePlacement from './CreatePlacement';
import PlacementDatatable from './PlacementDatatable';
import { checkUserPermission } from '../../checkPermission';

const placementservice = new PlacementService()

const Placement = () => {
    const formRef = useRef(null);
    const [isloader, setIsloader] = useState(false);
    const [toaster, setToaster] = useState("");
    const [image, setImage] = useState();
    const [data, setData] = React.useState([]);
    const [refresh, setRefresh] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [scroll, setScroll] = useState(false);
    const [tableloader, setTableloader] = useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [pending, setPending] = React.useState(true)
    const [selectedRows, setSelectedRows] = React.useState([]);


    const handlePerRowsChange = async (newPerPage) => {
        setPerpage(newPerPage)
    };
    const handleChange = () => { setSelectedRows(selectedRows); };


    const handleRowClicked = row => {

        console.log(`${row.name} was clicked!`);
    };
    async function getPlacement() {
        setPending(true)
        const data = await placementservice.getAllPlacement(perpage, currentPage,filterText);
        setData(data.data)
        setPending(false)
        setTotalRows(data.data.count)

    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [scroll])
    useEffect(() => {
        getPlacement()
    }, [refresh, perpage, currentPage,filterText])
    const validationSchema = Yup.object().shape({
        // feed: Yup.string()
        //     .required('Required'),
        name: Yup.string()
            .required('Required'),

        // image: Yup.string()
        // .required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            placement_id: "",
            name: "",
            logo: "",
            is_active: true

        },
        validationSchema,
        validate: async (values) => {
            const errors = {}
            // let image = values.image


            // values.id ? image = await dataURLtoFile(values.image) : image = values.image
            if (values.logo == "") {
                errors.logo = "Required"
            }
            else if (values?.logo?.type !== "image/png" && values?.logo?.type !== "image/jpg" && values?.logo?.type !== "image/jpeg") {
                errors.logo = "Only jpg/jpeg and png files are allowed!"
            }
            else {
                let kb = values.logo.size / 1024; // convert the file size into byte to kb
                let mb = kb / 1024; // convert kb to mb
                if (mb > 2) {
                  errors.logo ="logo should be less than 2 MB"
                }
              }
            console.log(values.logo);
            return errors
        },
        onSubmit: (values) => {
            console.log(values);
            setIsloader(true)
            let formData = new FormData();
            if (values.placement_id) {
                formData.append("placement_id", values.placement_id ?? "");
            }
            formData.append("name", values.name);
            formData.append("logo", values.logo);


            placementservice.addOrUpdatePlacement(formData).then((response) => {
                // })
                // CountryCreateOrUpdate({ formData })((response) => {
                console.log(response);
                if (response && response.status) {
                    setIsloader(false)
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setRefresh(!refresh)
                    formik.resetForm()
                    formRef.current.reset();
                }
                else {
                    formik.setErrors({
                        placement_id: response?.response?.data?.errors?.placement_id,
                        name: response?.response?.data?.errors?.name,
                        logo: response?.response?.data?.errors?.logo,
                    });
                    console.log(response?.response?.data?.errors);
                    setIsloader(false)
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });
        },
    });
    const handleEdit = async (item) => {
        setScroll(preVal=>!preVal)
        formik.setValues({
            placement_id: item.id,
            name: item.name,
            logo: await dataURLtoFile(item.logo),
            image_url: item?.logo



        })
        console.log(item);
    }

    console.log(formik)
    return (
        <div>
            <ToastContainer />
            <>
                <Header />
                <Sidebar />
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Placement</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Placement</li>
                            </ol>
                        </nav>
                    </div>
                    <React.Fragment>

                        {checkUserPermission("Create Placement")
                        && <CreatePlacement formik={formik} setImage={setImage} isloader={isloader} toaster={toaster} setToaster={setToaster} formRef={formRef} />}
                    </React.Fragment>

                    <React.Fragment>
                        <PlacementDatatable currentPage={currentPage} setCurrentPage={setCurrentPage} handleRowClicked={handleRowClicked} handleChange={handleChange}  pending={pending} filterText={filterText} setFilterText={setFilterText}   totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} data={data} handleEdit={handleEdit} refresh={refresh} setRefresh={setRefresh} formik={formik} />
                    </React.Fragment>

                </main>
            </>

        </div>


    )
}


export default Placement


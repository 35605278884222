import React, { useState, useEffect } from 'react';
import { BsCart, BsCurrencyDollar, BsPeople } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const Content = () => {
    const [dashData, setDashData] = useState([]);
    const [view, setView] = useState("true");
    useEffect(() => {
        fetch('https://api.algateinternational.com/api/dashboard/admin/dashboard-data')
            .then(response => response.json())
            .then(jsonData =>{ 
                    console.log(jsonData)
                setDashData(jsonData.data.results)})
            .catch(error => console.log(error));

    }, []);
    console.log(dashData.university_list)

    const universityElements = dashData?.university_list?.map(data => (
        <tr key={data.id} >
            <th scope="row"><a href="#">{data.user}</a></th>
            <td>{data.university}</td>
            {/* <td>{data.is_contacted ? "Yes" : "No"}</td> */}
        </tr>
    ))

    const courseElements = dashData?.course_list?.map(data => (
        <tr key={data.id} >
            <th scope="row"><a href="#">{data.user}</a></th>
            <td>{data.university_course}</td>
            {/* <td>{data.is_contacted ? "Yes" : "No"}</td> */}
        </tr>
    ))



    return (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Dashboard</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-xxl-4 col-md-6">
                                    <div className="card info-card sales-card">
                                        <div className="filter">
                                            <a className="icon" href="#" ></a>
                                            {/* <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                {/* <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>
                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul> */}
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">Total Students  <span></span></h5>
                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <BsPeople />
                                                </div>
                                                <div className="ps-3">
                                                    <h6>{dashData.student_count}</h6>
                                                    {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xxl-4 col-md-6">
                                    <div className="card info-card revenue-card">
                                        <div className="filter">
                                            <a className="icon" href="#" ></a>
                                            {/* <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul> */}
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">Total University <span></span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i class="bi bi-bank"></i>
                                                </div>
                                                <div className="ps-3">

                                                    <h6>{dashData.university_count}</h6>
                                                    {/* <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xxl-4 col-xl-12">
                                    <div className="card info-card customers-card">
                                        <div className="filter">
                                            <a className="icon" href="#" ></a>
                                            {/* <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul> */}
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title"> Total Course <span></span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i class="bi bi-mortarboard-fill"></i>
                                                </div>
                                                <div className="ps-3">
                                                    <h6>{dashData.course_count}</h6>
                                                    {/* <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span> */}

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="card recent-sales overflow-auto">
                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>
                                                <li><a className="dropdown-item" onClick={() => setView(true)}>Universities</a></li>
                                                <li><a className="dropdown-item" onClick={() => setView(false)}>Courses</a></li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title"> Recent University Enquiries <span></span></h5>
                                            <table className="table table-borderless datatable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">User</th>
                                                        <th scope="col">{view ? "University" : "Course"}</th>
                                                        {/* <th scope="col">Contacted</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {view ? universityElements : courseElements}
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Content